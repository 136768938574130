import React, {useState} from "react";
import Map, {Layer, Marker, NavigationControl, Source} from "react-map-gl";
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../store/actions';
import MapsPinMini from "./MapsPinMini";
import {MapsMarker} from "./MapsMarker";
import {geoJsonLayer, geoJsonLayerBorder, geoJsonPolygon} from "./MapsLayer";

export const MapsWindowHotelDetail = () => {

  const dispatch = useDispatch()

  const [highlightHotel, setHighlightHotel] = useState(null)

  const mapsWindow = useSelector(state => state.map.mapsWindowHotelDetail)
  const hotel = useSelector(state => state.offer.hotelDetail)

  return(
    <div>
      { (mapsWindow.longitude && mapsWindow.latitude) ?
        <div style={{marginTop: 8, marginBottom: 10, height: 300}}>
          <Map
            {...mapsWindow}
            style={{ width:'100%', height:'100%'}}
            mapStyle="mapbox://styles/ajowa/ckth3jb9o40ka18pmm0aecbel"
            onMove={evt => dispatch(actions.moveMapHotelDetail(evt.viewState))}
          >
            {(() => {

              if(hotel.hotelOfferType === 'standard' && hotel.locationLat && hotel.locationLong) {

                return(
                  <MapsMarker
                    pinType={'single'}
                    hotel={hotel}
                    key={hotel.uuid}
                    highlightHotel={highlightHotel}
                    setHighlightHotel={setHighlightHotel}
                  />
                )

              } else if(hotel.hotelOfferType === 'district') {

                const polygonGeoJson = geoJsonPolygon({
                coordinates: hotel?.BookingDistrict?.locationPolygon?.polygon || [],
                hotelId: hotel.hotelId,
                })
                const polygonHotels = hotel?.BookingDistrict?.locationPolygon?.hotels || []
                const highlightPolygon = (hotel.hotelId === highlightHotel)

                return(
                  <div key={hotel.uuid}>
                    <Source
                      id={'polygonLayer_'+hotel.uuid}
                      type="geojson"
                      data={polygonGeoJson}
                    >
                      <Layer {...geoJsonLayer(highlightPolygon)} />
                      <Layer {...geoJsonLayerBorder(highlightPolygon)} />
                    </Source>
                    <>
                      {polygonHotels.map(polygonHotel => <Marker
                        key={'polygonMarker_'+polygonHotel.id}
                        latitude={polygonHotel.lat}
                        longitude={polygonHotel.long}
                        offset={[0, 0]}
                        style={{zIndex: 0}}
                      >
                        <MapsPinMini stroke="#fff" />
                      </Marker>)}
                      {(hotel.locationLat && hotel.locationLong)
                        ? <MapsMarker
                          pinType={'district'}
                          hotel={hotel}
                          key={hotel.uuid}
                          highlightHotel={highlightHotel}
                          setHighlightHotel={setHighlightHotel}
                        />
                        : ''}
                    </>
                  </div>
                )
              } else {
                return ''
              }
            })()}
            <NavigationControl style={{ top: 10, right: 10 }}/>
          </Map>
        </div> : '' }
    </div>

  )
}