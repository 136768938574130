import logo from '../assets/images/logo_final.png'
import SearchBar from './SearchBar';
import SearchBarMobile from './SearchBarMobile';
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import * as actions from "../store/actions";
import {useDispatch} from "react-redux";
import HeaderLogin from "./HeaderLogin";
import {useLocation} from "react-router";

const Header = () => {

  const dispatch = useDispatch()
  const { city=null } = useParams()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)

  const product = searchQuery.get('product') || 'single'
  const nights = searchQuery.get('nights') && !isNaN(Number(searchQuery.get('nights')))
    ? Number(searchQuery.get('nights'))
    : searchQuery.get('product') === 'business' // fallback if nights is not a number
      ? 0
      : 4

  useEffect(() => {
    dispatch(actions.getCities(city))
    dispatch(actions.getNights())
    dispatch(actions.updateSearchNights(nights))
    dispatch(actions.updateInputNights(nights))
    dispatch(actions.updateSearchProduct(product))
  }, [dispatch, city, nights, product])

  return (
    <>
      {/* Desktop */}
      <div className="bg-white shadow h-24 sticky top-0 z-50 hidden lg:block">
        <div className="p-3 mx-auto flex">
          <div className="w-2/12">
            <a href="https://www.myflexhome.de/"><img src={logo} alt="MyFlexHome Logo" className="header__logo cursor-pointer" /></a>
          </div>
          <div className="w-8/12 flex items-center justify-center">
            <SearchBar />
          </div>
          <div className="w-2/12 flex justify-end">
            <HeaderLogin />
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="bg-white shadow h-24 sticky top-0 z-50 block lg:hidden">
        <div className="px-3 py-2 mx-auto flex">
          <div className="w-6/12">
            <a href="https://www.myflexhome.de/"><img src={logo} alt="MyFlexHome Logo" className="header__logo cursor-pointer" /></a>
          </div>
          <div className="w-6/12 flex justify-end">
            <HeaderLogin />
          </div>
        </div>
        <div className="">
          <SearchBarMobile />
        </div>
      </div>
    </>
  )
}

export default Header
