import * as actions from "../store/actions";
import {MapsMarker} from "./MapsMarker";
import Map, {Layer, Marker, NavigationControl, Source} from "react-map-gl";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {geoJsonLayer, geoJsonLayerBorder, geoJsonPolygon} from "./MapsLayer";
import MapsPinMini from "./MapsPinMini";


export const MapsWindowSearch = (props) => {

  const dispatch = useDispatch()

  const {highlightHotel, setHighlightHotel} = props

  const mapsWindow = useSelector(state => state.map.mapsWindowSearch)
  const hotelList = useSelector(state => state.search.hotelList)

  if(hotelList) {
    //console.log('layerIds',layerIds)
    return(
      <Map
        {...mapsWindow}
        style={{ width:'100%', height:'100%'}}
        mapStyle="mapbox://styles/ajowa/ckth3jb9o40ka18pmm0aecbel"
        onMove={evt => dispatch(actions.moveMapSearch(evt.viewState))}
        //onMouseMove={onHover}
        //interactiveLayerIds={layerIds}
      >
        {hotelList.map((hotel) => {
          if(hotel.hotelOfferType === 'standard' && hotel.locationLat && hotel.locationLong) {

            return(
              <MapsMarker
                pinType={'single'}
                hotel={hotel}
                key={hotel.uuid}
                highlightHotel={highlightHotel}
                setHighlightHotel={setHighlightHotel}
              />
            )

          } else if(hotel.hotelOfferType === 'district') {

            const polygonGeoJson = geoJsonPolygon({
              coordinates: hotel?.BookingDistrict?.locationPolygon?.polygon || [],
              hotelId: hotel.hotelId,
            })
            const polygonHotels = hotel?.BookingDistrict?.locationPolygon?.hotels || []
            const highlightPolygon = (hotel.hotelId === highlightHotel)

            return(
              <div key={hotel.uuid}>
                <Source
                  id={'polygonLayer_'+hotel.uuid}
                  type="geojson"
                  data={polygonGeoJson}
                >
                  <Layer {...geoJsonLayer(highlightPolygon)} />
                  <Layer {...geoJsonLayerBorder(highlightPolygon)} />
                </Source>
                <>
                  {polygonHotels.map(polygonHotel => <Marker
                    key={'polygonMarker_'+polygonHotel.id}
                    latitude={polygonHotel.lat}
                    longitude={polygonHotel.long}
                    offset={[0, 0]}
                    style={{zIndex: 0}}
                  >
                    <MapsPinMini stroke="#fff" />
                  </Marker>)}
                  {/*(hotel.locationLat && hotel.locationLong) ? <MapsMarker
                    hotel={hotel}
                    highlightHotel={highlightHotel}
                    setHighlightHotel={setHighlightHotel}
                    style={{zIndex: 10}}
                  /> : '' */}
                  {/*(hotel.locationLat && hotel.locationLong) ? <Marker
                    key={'polygonMarker_'+hotel.uuid}
                    latitude={hotel.locationLat}
                    longitude={hotel.locationLong}
                    offset={[0, 0]}
                    style={{zIndex: 0}}
                  ><svg width="47" height="47" viewBox="0 0 800 800">
                      <path id="pinfillsharpplus-637" fill="#25a9d5" fillRule="evenodd" stroke="none" d="M 400 0 C 245.359955 0 120 125.351563 120 280 C 120 434.648438 400 800 400 800 C 400 800 680 434.648438 680 280 C 680 125.351563 554.640015 0 400 0"/>
                      <path id="Path-copy" fill="none" stroke="#ffffff" strokeWidth="22.5" strokeLinecap="round" strokeLinejoin="round" d="M 639 278.5 C 639 146.227783 531.772217 39 399.5 39 C 267.227814 39 160 146.227783 160 278.5 C 160 410.772186 267.227814 518 399.5 518 C 531.772217 518 639 410.772186 639 278.5 Z"/>
                      <path id="Shape" fill="none" stroke="#ffffff" strokeWidth="22.5" strokeLinecap="round" strokeLinejoin="round" d="M 319.222229 213.176941 L 319.382782 213.019226 M 383.444458 213.176941 L 383.60498 213.019226 M 319.222229 270.532654 L 319.382782 270.374939 M 383.444458 270.532654 L 383.60498 270.374939 M 319.222229 327.888336 L 319.382782 327.730591 M 383.444458 327.888336 L 383.60498 327.730591 M 447.666687 385.100616 L 264.633331 385.100616 C 259.312988 385.100616 255 381.248779 255 376.497284 L 255 164.28125 C 255 159.529724 259.312988 155.677856 264.633331 155.677856 L 351.333313 155.677856 L 351.333313 135.603333 C 351.333313 130.851807 355.646301 127 360.966675 127 L 438.033325 127 C 443.353699 127 447.666687 130.851807 447.666687 135.603333 L 447.666687 213.033569 M 447.666687 385.100616 L 534.366638 385.100616 C 539.687012 385.100616 544 381.248779 544 376.497284 L 544 221.636902 C 544 216.885376 539.687012 213.033569 534.366638 213.033569 L 447.666687 213.033569 M 447.666687 385.100616 L 447.666687 327.744934 M 447.666687 213.033569 L 447.666687 270.389221 M 447.666687 270.389221 L 479.777802 270.389221 M 447.666687 270.389221 L 447.666687 327.744934 M 447.666687 327.744934 L 479.777802 327.744934"/>
                    </svg>
                    </Marker>
                    : '' */}
                  {(hotel.locationLat && hotel.locationLong)
                    ? <MapsMarker
                      pinType={'district'}
                      hotel={hotel}
                      key={hotel.uuid}
                      highlightHotel={highlightHotel}
                      setHighlightHotel={setHighlightHotel}
                    />
                    : ''}
                </>
              </div>
            )
          } else {
            return ''
          }
        })}
        <NavigationControl style={{ top: 10, right: 10 }}/>
      </Map>
    )
  }

}