import React, {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {AdjustmentsHorizontalIcon} from '@heroicons/react/24/solid'
import {useDispatch, useSelector} from "react-redux";
import {SwitchInput} from "./SwitchInput";
import * as actions from "../store/actions";
import {ChevronUpDownIcon} from "@heroicons/react/20/solid";

const SearchFilterDropDown = () => {

  const dispatch = useDispatch()

  const filterList = useSelector(state => state.search.filterList)
  const inputFilter = useSelector(state => state.search.inputFilter)
  const searchFilter = useSelector(state => state.search.searchFilter)
  const searchFilterLength = searchFilter.split('|').length

  const handleFilterChange = (e, name) => {
    dispatch(actions.updateInputFilter({[name]:e}))
    dispatch(actions.updateSearchFilter())
    dispatch(actions.setSearchUrl())
  }
  const resetFilter = () => {
    dispatch(actions.resetInputFilter())
    dispatch(actions.updateSearchFilter())
    dispatch(actions.setSearchUrl())
  }

  return (
    <Menu as="div" className="relative inline-block text-left z-10">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-200 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary">
          <AdjustmentsHorizontalIcon className="w-5 h-5" />
          <span className="mx-2">{searchFilter ? <>Filter <span className="bg-red-700 rounded-3xl text-white px-2 py-1 text-xs ml-1">{searchFilterLength}</span></> : 'Alle'}</span>
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">

          <Menu.Item>
            <div className="py-3 mx-3">
              {filterList.map(x =>
                <SwitchInput
                  key={x.id}
                  enabled={inputFilter[x.id]}
                  setEnabled={(e) => handleFilterChange(e, x.id)}
                  name={x.n}
                  iconUrl={`/api/booking/media/${x.id}`}
                />
              )}
              {searchFilter && searchFilterLength > 0
                ? <div className="text-right mt-2">
                    <button className="bg-red-200 text-black rounded-lg cursor-pointer px-4 py-1 text-sm" onClick={resetFilter}>Filter Zurücksetzen</button>
                  </div>
                : ''
              }

            </div>
          </Menu.Item>

        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default SearchFilterDropDown