import * as actionTypes from '../actions/actionTypes'

const initialState = {
  snackbar: {
    message: '',
    open: false,
    variant: 'success'
  },
  showUpgradeModal: false,
  showNewsletterModal: false,
}

const showNotification = (state, action) => {
  return {
    ...state,
    snackbar: {
      message: action.message,
      open: true,
      variant: action.variant,
    },
  }
}

const hideNotification = (state) => {
  return {
    ...state,
    snackbar: {
      ...state.snackbar,
      message: '',
      open: false,
    },
  }
}

const showUpgradeModal = (state) => {
  return {
    ...state,
    showUpgradeModal: true,
  }
}

const hideUpgradeModal = (state) => {
  return {
    ...state,
    showUpgradeModal: false,
  }
}

const showNewsletterModal = (state) => {
  return {
    ...state,
    showNewsletterModal: true,
  }
}

const hideNewsletterModal = (state) => {
  return {
    ...state,
    showNewsletterModal: false,
  }
}



const reducer = ( state = initialState, action ) => {
switch ( action.type ) {
    case actionTypes.SHOW_NOTIFICATION: return showNotification(state, action)
    case actionTypes.HIDE_NOTIFICATION: return hideNotification(state)
    case actionTypes.SHOW_UPGRADE_MODAL: return showUpgradeModal(state)
    case actionTypes.HIDE_UPGRADE_MODAL: return hideUpgradeModal(state)
    case actionTypes.SHOW_NEWSLETTER_MODAL: return showNewsletterModal(state)
    case actionTypes.HIDE_NEWSLETTER_MODAL: return hideNewsletterModal(state)
    default: return state
  }
}

export default reducer
