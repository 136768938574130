import {add, sub} from 'date-fns'

/*
 *  Date Comparison
 */

export const compareDates = (date1, date2) => {
  const utcDate1 = new Date(Date.UTC(date1.getFullYear(),date1.getMonth(), date1.getDate()))
  const utcDate2 = new Date(Date.UTC(date2.getFullYear(),date2.getMonth(), date2.getDate()))
  return utcDate1.getTime() === utcDate2.getTime()
}

export const compareDateToArray = (date, arr) => {
  //console.log('compare', {date, arr})
  const cleanDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()))
  return arr.some(item => {
    const cleanArrDate = new Date(Date.UTC(item.getFullYear(),item.getMonth(), item.getDate()))
    return cleanDate.getTime() === cleanArrDate.getTime()
  })
}


/*
 *  Date calculations and ranges
 */

export const today = () => {
  let now = new Date()
  return new Date(Date.UTC(now.getFullYear(),now.getMonth(), now.getDate())).setHours(0,0,0,0)
}

export const tomorrow = () => {
  return add(today(), {days:1})
}

export const yesterday = () => {
  return sub(today(), {days:1})
}

export const inThreeDays = () => {
  return add(today(), {days:3})
}

export const dateXDaysFromDate = (date, numberOfDays) => {
  let newDate = new Date(date)
  newDate.setDate(newDate.getDate() + numberOfDays)
  return newDate
}

export const dateXMonthsAndDaysFromDate = (date, numberOfMonths, numberOfDays) => {
  return add(new Date(date), {
    months: numberOfMonths,
    days: numberOfDays,
  })
}

export const dateArrayFromDate = (numberOfDays, fromDate) => {
  return [...Array(numberOfDays)].map((u, i) => {
    let day = new Date(fromDate)
    day.setDate(day.getDate() + i)
    return day
  })
}

export const filterDayArrayByRange = (dayArray, dateFrom, dateTo) => {
  return dayArray.filter(day => (getDateAsUTC(day) >= getDateAsUTC(dateFrom) && getDateAsUTC(day) <= getDateAsUTC(dateTo)))
}


/*
 *  Transformation
 */

// Remove time value from dateString
export const dateStringToUtcIsoString = (dateString) => {
  const date = new Date(dateString)
  const offset = date.getTimezoneOffset()
  const utcWithOffset = sub(date, {minutes: offset})
  return utcWithOffset.toISOString()
}

// Remove time value from dateString
export const removeTimeFromDate = (dateString) => {
  const myDate = new Date(dateString)
  return new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate())
}

// JavaScript DateTime as UTC Date
export const getDateAsUTC = (date) => {
  const cleanDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()))
  cleanDate.setHours(0,0,0,0)
  const offset = date.getTimezoneOffset()
  return sub(cleanDate, {minutes: offset})
}

/*
 *  Work with timezone dates from users browser
 */

export const removeTimezoneOffset = (date) => {
  const offset = date.getTimezoneOffset()
  return sub(date, {minutes: offset})
}

export const addTimezoneOffset = (date) => {
  const offset = date.getTimezoneOffset()
  return add(date, {minutes: offset})
}

export const removeTimezoneOffsetFromDateArray = (dateArray) => {
  return dateArray.map(date => removeTimeFromDate(date))
}

export const addTimezoneOffsetFromDateArray = (dateArray) => {
  return dateArray.map(date => addTimezoneOffset(date))
}

/*
 * get all single dates from a time range
 */
export const dateArrayFromRange = (dateFrom, dateTo) => {
  const arr=[]
  let from=new Date(dateFrom)
  let to=new Date(dateTo)
  for(;from<=to; from.setDate(from.getDate()+1)) {
    arr.push(new Date(from))
  }
  return arr
}

/*
 * get one or more time ranges (dateFrom, dateTo) from an array of single dates
 */
export const mergeMultipleDateRangesToArray = (rangeArr) => {
  const result = []
  rangeArr.forEach(rangeObj => {
    //spread to push single dates instead of array
    result.push(...dateArrayFromRange(rangeObj.dateFrom, rangeObj.dateTo))
  })
  return result
}


/*
 *  Formatting javascript dates
 */

// Mo
export const formatDateWeekday = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  const options = { weekday: 'short' }
  return date.toLocaleDateString('de-DE', options)
}

// 30.08.20
export const formatDate = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
  return date.toLocaleDateString('de-DE', options)
}

// 30.08.
export const formatDateDayMonth = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  const options = { month: '2-digit', day: '2-digit' }
  return date.toLocaleDateString('de-DE', options)
}

// 30. Aug. 2020
export const formatDateWithMonth = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return date.toLocaleDateString('de-DE', options)
}

// 30. Aug.
export const formatDateDayMonthWithoutYear = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  const options = { month: 'short', day: 'numeric' }
  return date.toLocaleDateString('de-DE', options)
}

// 2021-08-30
export const formatDateISO = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  return date.toISOString().substring(0, 10)
}

// 30.08.2020, 18:59
export const formatDateTime = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
  return date.toLocaleDateString('de-DE', options)
}

// 30. Aug. 2020, 18:59
export const formatDateTimeWithMonth = (dateString) => {
  const date = addTimezoneOffset(new Date(dateString))
  const options = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }
  return date.toLocaleDateString('de-DE', options)
}
