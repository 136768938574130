import * as actionTypes from '../actions/actionTypes';
import {getDateAsUTC} from "../../functions/dateHelper";
import {calcSelectedNightsCountFromBookingDelta} from "../../functions/bookingHelper";

const initialState = {
  profile: {},
  subscriptions: [],
  subscriptionsLoading: false,
  invoices: [],
  invoicesLoading: false,
  selectedSubscription: {},
  bookingReservation: [],
  bookingReservationBySelectedEmployee: [],
  bookingReservationDelta: [],
  bookingReservationDeltaCalc: [],
  bookingReservationDeltaExceeded: false,
  companyInvites: [],
  companyInvitesUserList: [],
  companyInvitesLoading: false,
  companySelectedEmployee: {},
  updateReservationsLoading: false,
  bookingHistory: [],
  bookingHistoryFiltered: [],
  bookingHistoryLoading: false,
  bookingHistoryFilterAttributes: {
    uniqueMonthList: [],
    uniqueStatusList: [],
    uniqueHotelList: [],
    uniqueGuestList: [],
  },
  bookingHistoryFilterSelected: {
    month: '',
    status: [],
    hotels: [],
    guests: [],
  },
}

const setCustomerProfile = (state, action) => {
  return {
    ...state,
    profile: action.res || {},
    ...(action.res.accountType === 'businessUser' ? {
      companySelectedEmployee: action.res,
      bookingReservationBySelectedEmployee: state.bookingReservation.filter(x => x.employee === action.res?.invitationID)
    } : null),
  }
}


const setCustomerInvoice = (state, action) => {
  return {
    ...state,
    invoices: action.res || [],
    invoicesLoading: false,
  }
}

const setCustomerInvoiceLoading = (state) => {
  return {
    ...state,
    invoicesLoading: true,
  }
}


const setCustomerCompanyInvites = (state, action) => {
  const bookingReservationBySelectedEmployee = state?.bookingReservation?.filter(x => x?.employee === state?.companySelectedEmployee?.invitationID) || []
  return {
    ...state,
    companyInvites: action?.res || [],
    companyInvitesLoading: false,
    companyInvitesUserList: [
      {
        firstName: state?.profile?.firstName ?? '',
        lastName: state?.profile?.lastName + ' (Admin)' ?? '',
        email: state?.profile?.email ?? ''
      },
      ...action?.res?.BusinessCompany?.BusinessUserInvitations || []
    ],
    bookingReservationBySelectedEmployee: bookingReservationBySelectedEmployee,
  }
}

const setCustomerCompanyInvitesLoading = (state, loading) => {
  return {
    ...state,
    companyInvitesLoading: loading,
  }
}

const setCustomerSubscriptions = (state, action) => {
  const existingBookings = action?.selectedSubscription?.subscriptionData?.BookingReservations || []
  const activeExistingBookings = existingBookings.filter(b => ['new','confirmed_hotel','guarantee'].includes(b.status))
  const bookingReservation = activeExistingBookings.map(x => { return {...x, arrivalDate: new Date(x.arrivalDate)}})
  const bookingReservationBySelectedEmployee = bookingReservation.filter(x => x.employee === state.companySelectedEmployee?.invitationID) || []
  return {
    ...state,
    subscriptions: action.res || {},
    selectedSubscription: action.selectedSubscription || {},
    bookingReservation: bookingReservation,
    bookingReservationBySelectedEmployee: bookingReservationBySelectedEmployee,
    subscriptionsLoading: false,
  }
}

const setCustomerSubscriptionsLoading = (state) => {
  return {
    ...state,
    subscriptionsLoading: true,
  }
}

const setCustomerBookingReservationDelta = (state, action) => {
  let bookingReservationDeltaExceeded = false
  const isBusinessProduct = state.selectedSubscription?.subscriptionData?.product === 'business'
  //const isSingleProductWithZeroNights = state.selectedSubscription?.subscriptionData?.
  const bookingReservationDeltaCalc = state.selectedSubscription?.subscriptionEvaluation?.filter(x => x.label !== 'historic').map(m => {
    const deltaNights = calcSelectedNightsCountFromBookingDelta(m.dateFrom, m.dateTo, action.res)
    if((deltaNights > m.remainingNightsThisCycle) && !isBusinessProduct && m.segment.nightsPerMonth !== 0) bookingReservationDeltaExceeded = true
    return({
      dateFrom: m.dateFrom,
      dateTo: m.dateTo,
      remainingNights: m.remainingNights,
      deltaNights: deltaNights,
    })
  })
  return {
    ...state,
    bookingReservationDelta: action.res || [],
    bookingReservationDeltaCalc: bookingReservationDeltaCalc,
    bookingReservationDeltaExceeded: bookingReservationDeltaExceeded,
  }
}

const updateReservationsStart = (state, action) => {
  return {
    ...state,
    updateReservationsLoading: true,
  }
}

const updateReservationsSuccess = (state, action) => {
  /* resetReservationDelta: reset delta list, after successfully submitting all changes */
  return {
    ...state,
    bookingReservationDelta: [],
    updateReservationsLoading: false,
  }
}

const updateReservationsFail = (state, action) => {
  return {
    ...state,
    updateReservationsLoading: false,
  }
}

const setCustomerSelectedSubscription = (state, action) => {
  const selectedSubscription = action.res || {}
  const existingBookings = selectedSubscription?.subscriptionData?.BookingReservations || []
  const activeExistingBookings = existingBookings.filter(b => ['new','confirmed_hotel','guarantee'].includes(b.status))
  const bookingReservation = activeExistingBookings.map(x => { return {...x, arrivalDate: getDateAsUTC(new Date(x.arrivalDate))}})
  return {
    ...state,
    selectedSubscription: selectedSubscription,
    bookingReservation: bookingReservation,
  }
}

const setCustomerCompanySelectedEmployee = (state, action) => {
  const invitationID = action.res || null
  const employee = state.companyInvitesUserList.filter(x => x.invitationID === invitationID)?.[0] || state.companyInvitesUserList?.[0] || {}
  const bookingReservationBySelectedEmployee = state.bookingReservation.filter(x => x.employee === employee?.invitationID)
  return {
    ...state,
    companySelectedEmployee: employee,
    bookingReservationBySelectedEmployee: bookingReservationBySelectedEmployee,
  }
}

const setBookingHistoryAttributes = (state, action) => {
  return {
    ...state,
    bookingHistoryFilterAttributes: action.res,
    bookingHistoryFilterSelected: {
      month: action.res.uniqueMonthList[0],
      status: action.res.uniqueStatusList,
      hotels: action.res.uniqueHotelList,
      guests: action.res.uniqueGuestList,
    },
  }
}

const setBookingHistory = (state, action) => {
  return {
    ...state,
    bookingHistory: action.res,
    bookingHistoryFiltered: bookingHistoryFiltered({
      bookingHistory: action.res,
      bookingHistoryFilterSelected: state.bookingHistoryFilterSelected,
    }),
    bookingHistoryLoading: false,
  }
}

const bookingHistoryFiltered = ({bookingHistory, bookingHistoryFilterSelected}) => {
  const selectedMonth = bookingHistoryFilterSelected?.month?.id
  const selectedGuests = bookingHistoryFilterSelected.guests?.map(guest => guest.id) || []
  const selectedHotels = bookingHistoryFilterSelected.hotels?.map(hotel => hotel.id) || []
  const selectedStatus = bookingHistoryFilterSelected.status?.map(status => status.id) || []

  return bookingHistory?.filter(x => {
    const reservationMonth = new Date(x?.arrivalDate)?.toISOString()?.slice(0, 7) || ''
    const reservationGuest = x?.BusinessUserInvitation?.invitationID || x?.BookingSubscription?.BookingCustomer?.customerID
    const reservationHotel1 = x?.RebookHotel?.hotelId
    const reservationHotel2 = x?.AssignHotel?.hotelId
    const reservationHotel3 = x?.BookingSubscription?.BookingHotel?.hotelId
    const reservationStatus = x?.status
    return(
      selectedMonth === reservationMonth
      && selectedGuests.includes(reservationGuest)
      && (selectedHotels.includes(reservationHotel1) || selectedHotels.includes(reservationHotel2) || selectedHotels.includes(reservationHotel3))
      && selectedStatus.includes(reservationStatus)
    )
  })
}

const updateBookingHistoryFilter = (state, action) => {
  const newSelected = {
    ...state.bookingHistoryFilterSelected,
    ...action.res,
  }
  return {
    ...state,
    bookingHistoryFilterSelected: newSelected,
    bookingHistoryFiltered: bookingHistoryFiltered({
      bookingHistory: state.bookingHistory,
      bookingHistoryFilterSelected: newSelected,
    }),
  }
}

const setBookingHistoryLoading = (state, loading) => {
  return {
    ...state,
    bookingHistoryLoading: loading,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_CUSTOMER_PROFILE_SUCCESS: return setCustomerProfile(state, action)
    case actionTypes.GET_CUSTOMER_INVOICE_START: return setCustomerInvoiceLoading(state)
    case actionTypes.GET_CUSTOMER_INVOICE_SUCCESS: return setCustomerInvoice(state, action)
    case actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_START: return setCustomerSubscriptionsLoading(state)
    case actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS: return setCustomerSubscriptions(state, action)
    case actionTypes.UPDATE_CUSTOMER_BOOKING_RESERVATION_DELTA: return setCustomerBookingReservationDelta(state, action)
    case actionTypes.PUT_CUSTOMER_BOOKING_RESERVATION_START: return updateReservationsStart(state, action)
    case actionTypes.PUT_CUSTOMER_BOOKING_RESERVATION_SUCCESS: return updateReservationsSuccess(state, action)
    case actionTypes.PUT_CUSTOMER_BOOKING_RESERVATION_FAIL: return updateReservationsFail(state, action)
    case actionTypes.UPDATE_CUSTOMER_SELECTED_SUBSCRIPTION: return setCustomerSelectedSubscription(state, action)
    case actionTypes.UPDATE_CUSTOMER_COMPANY_SELECTED_EMPLOYEE: return setCustomerCompanySelectedEmployee(state, action)
    case actionTypes.GET_CUSTOMER_COMPANY_INVITE_START: return setCustomerCompanyInvitesLoading(state, true)
    case actionTypes.GET_CUSTOMER_COMPANY_INVITE_SUCCESS: return setCustomerCompanyInvites(state, action)
    case actionTypes.GET_CUSTOMER_COMPANY_INVITE_FAIL: return setCustomerCompanyInvitesLoading(state, false)
    case actionTypes.POST_CUSTOMER_COMPANY_INVITE_START: return setCustomerCompanyInvitesLoading(state, true)
    case actionTypes.POST_CUSTOMER_COMPANY_INVITE_SUCCESS: return setCustomerCompanyInvitesLoading(state, false)
    case actionTypes.POST_CUSTOMER_COMPANY_INVITE_FAIL: return setCustomerCompanyInvitesLoading(state, false)
    case actionTypes.PUT_CUSTOMER_COMPANY_INVITE_START: return setCustomerCompanyInvitesLoading(state, true)
    case actionTypes.PUT_CUSTOMER_COMPANY_INVITE_SUCCESS: return setCustomerCompanyInvitesLoading(state, false)
    case actionTypes.PUT_CUSTOMER_COMPANY_INVITE_FAIL: return setCustomerCompanyInvitesLoading(state, false)
    case actionTypes.GET_BOOKING_HISTORY_ATTRIBUTES_SUCCESS: return setBookingHistoryAttributes(state, action)
    case actionTypes.GET_BOOKING_HISTORY_START: return setBookingHistoryLoading(state, true)
    case actionTypes.GET_BOOKING_HISTORY_SUCCESS: return setBookingHistory(state, action)
    case actionTypes.GET_BOOKING_HISTORY_FAIL: return setBookingHistoryLoading(state, false)
    case actionTypes.UPDATE_BOOKING_HISTORY_FILTER: return updateBookingHistoryFilter(state, action)
    default: return state
  }
}

export default reducer
