import deLocale from "date-fns/locale/de";
import {DayPicker} from "react-day-picker";
import React, {useState} from "react";
import {add, formatISO} from "date-fns";
import {addTimezoneOffset, getDateAsUTC, removeTimezoneOffset} from "../functions/dateHelper";


const Test = () => {

  const [selected] = useState([
    new Date('2023-08-22'),
    new Date('2023-08-24'),
    new Date('2023-08-05T00:00:00.000Z'),
    addTimezoneOffset(new Date('2023-08-10T00:00:00.000Z')),
  ])
  const [selectedNew, setSelectedNew] = useState(new Date())
  const [month, setMonth] = useState(new Date())

  const handleDayClick = (day, { selected }) => {
    console.log('handleDayClick',{day, selected})
    setSelectedNew(day)
  }

  return(
    <div className="flex flex-col justify-center">
      <div className="flex justify-center border-red-500 border">DAY PICKER</div>

      <div className="flex justify-center">
        <DayPicker
          mode="uncontrolled"
          numberOfMonths={window.innerWidth > 800 ? 2 : 1}
          selected={selected}
          onDayClick={handleDayClick}
          defaultMonth={new Date()}
          fromDate={new Date()}
          toDate={add(new Date(), {days: 90})}
          locale={deLocale}
          showWeekNumber={true}
          showOutsideDays={false}
          fixedWeeks={false}
          month={month}
          onMonthChange={setMonth}
        />
      </div>

      <div className="text-center">
        <b>Selection:</b>
        <div>{JSON.stringify(selectedNew)}</div>
        <div>formatISO: {formatISO(selectedNew)}</div>
        <div>getTimezoneOffset: {selectedNew.getTimezoneOffset()}</div>
        <div>getDateAsUTC: {JSON.stringify(getDateAsUTC(selectedNew))}</div>
        <div>removeTimezoneOffset: {JSON.stringify(removeTimezoneOffset(selectedNew))}</div>
        <br/>
        <b>Now:</b>
        <div>browser time stringify: {JSON.stringify(new Date())}</div>
        <div>browser time formatISO: {formatISO(new Date())}</div>
        <div>getDateAsUTC: {JSON.stringify(getDateAsUTC(new Date()))}</div>
      </div>

    </div>
  )
}

export default Test