import StepperBar from '../components/StepperBar';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Box, TextField} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import * as actions from '../store/actions';
import {Disclosure} from "@headlessui/react";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {Autocomplete} from '@mui/material';
import countryList from "../assets/countryList.json"


const AccountDetails = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  /* redux states */
  const pricePerMonth = useSelector(state => state.offer.pricePerMonth)
  const pricePerNight = useSelector(state => state.offer.pricePerNight)
  const registration = useSelector(state => state.offer.registration)
  const product = useSelector(state => state.offer.product)

  /* check for preconditions, otherwise start from scratch */
  useEffect(() => {
    if (pricePerNight === 0) {
      history.push('/search/muenchen/?nights=4')
    }
  }, [history, pricePerMonth, pricePerNight])

  /* react hook form */
  const { control, handleSubmit } = useForm({
    defaultValues: {
      ...registration
    }
  })
  const onSubmit = data => {
    dispatch(actions.putAccount(data))
    //console.log(data)
  }
  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  return (
    <div className="container max-w-screen-lg mx-auto mb-10">
      <div className="mx-3 md:w-10/12 md:mx-auto">
        <StepperBar active={2} />

        <div className="text-center text-3xl font-light mt-10">Kostenlosen Account anlegen</div>
        <div className="text-center text-md font-light mt-2">Vervollständige dein Profil.</div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto p-4 rounded-lg sm:w-112">
            <div>

              <Box
                sx={{
                  textAlign: 'center',
                  '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                  '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                }}
              >
                <div>

                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Vornamen an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Vorname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Nachnamen an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Nachname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="company"
                    control={control}
                    rules={{
                      ...(product === 'business' ? {
                        required: 'Bitte gib deinen Firmenname an',
                      } : null)
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label={product === 'business' ? 'Firma' : 'Firma (optional)'}
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                </div>

                <div className="mt-4">

                  <Controller
                    name="addressStreet"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine Straße mit Hausnummer an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Straße mit Hausnr."
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="addressZip"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine Postleitzahl an',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Bitte gib eine gültige Postleitzahl an",
                      }
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="PLZ"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="addressCity"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine Stadt an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Stadt"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="addressCountry"
                    control={control}
                    rules={{
                      required: 'Bitte gib dein Wohnsitz-Land an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <Autocomplete
                        {...field}
                        id="countryAutocomplete"
                        options={countryList}
                        disableClearable={false}
                        getOptionLabel={(option) => option || ''}
                        value={field?.value}
                        onChange={(e, value) => {
                          field.onChange(value)
                        }}
                        autoHighlight={true} // required to select entry without submitting entire material-table row
                        onKeyDown={e => handleKey(e)}
                        isOptionEqualToValue={(option, value) => {
                          return option === value
                        }}
                        renderOption={(props, option) => {
                          return(
                            <li {...props}>
                              <div>{option || '---'}</div>
                            </li>
                          )
                        }}
                        size="small"
                        renderInput={(params) => {
                          params.InputProps.style = {}
                          return(
                            <TextField
                              {...params}
                              label="Land"
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                            />
                          )
                        }}
                      />
                    )}
                  />

                </div>

                <div className="mt-4">

                  <Controller
                    name="contactPhone"
                    control={control}
                    rules={{
                      required: 'Bitte gib eine Telefonnummer an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Telefon"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                </div>

              </Box>
            </div>

            <Disclosure as="div" className="mt-8" defaultOpen={false}>
              {({open}) => (
                <>
                  <Disclosure.Button
                    className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium bg-gray-100 text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
                    <div className="text-base">Abweichende Rechnungsadresse <span className="ml-4 font-light text-sm text-gray-500">(optional)</span></div>
                    <ChevronRightIcon
                      className={`${
                        open ? 'rotate-90 transform' : ''
                      } h-5 w-5 text-blue-900`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-6 mt-1 border-4 rounded-lg border-gray-100">
                    <Box
                      sx={{
                        textAlign: 'center',
                        '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                        '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                      }}
                    >
                      <div className="text-sm font-light text-left px-5 mb-4">
                        { /*
                        <ul className="list-disc ml-4">
                          <li>Nenne uns die Person, Firma oder Adresse auf die deine Rechnung ausgestellt werden soll</li>
                          <li>MyFlexHome Rechnungen findest du 100% digital in deinem Dashboard</li>
                          <li>Rechnungsdaten kannst du auch später in deinem Profil anpassen</li>
                        </ul>
                        */}
                        Nenne uns die Person, Firma oder Adresse auf die deine digitale Rechnung ausgestellt werden soll.

                      </div>
                      <div>

                        <Controller
                          name="billingFirstName"
                          control={control}
                          render={({ field,fieldState}) =>  (
                            <TextField
                              {...field}
                              label="Vorname"
                              size="small"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              variant="outlined"
                            />
                          )}
                        />

                        <Controller
                          name="billingLastName"
                          control={control}
                          render={({ field,fieldState}) =>  (
                            <TextField
                              {...field}
                              label="Nachname"
                              size="small"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              variant="outlined"
                            />
                          )}
                        />

                        <Controller
                          name="billingCompany"
                          control={control}
                          render={({ field,fieldState}) =>  (
                            <TextField
                              {...field}
                              label="Firma"
                              size="small"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              variant="outlined"
                            />
                          )}
                        />

                      </div>
                      <div className="mt-4">

                        <Controller
                          name="billingZip"
                          control={control}
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Bitte gib eine gültige Postleitzahl an",
                            }
                          }}
                          render={({ field,fieldState}) =>  (
                            <TextField
                              {...field}
                              label="PLZ"
                              size="small"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              variant="outlined"
                            />
                          )}
                        />

                        <Controller
                          name="billingCity"
                          control={control}
                          render={({ field,fieldState}) =>  (
                            <TextField
                              {...field}
                              label="Stadt"
                              size="small"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              variant="outlined"
                            />
                          )}
                        />

                        <Controller
                          name="billingStreet"
                          control={control}
                          render={({ field,fieldState}) =>  (
                            <TextField
                              {...field}
                              label="Straße mit Hausnr."
                              size="small"
                              value={field.value}
                              onChange={field.onChange}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              variant="outlined"
                            />
                          )}
                        />

                        <Controller
                          name="billingCountry"
                          control={control}
                          render={({ field,fieldState}) =>  (
                            <Autocomplete
                              {...field}
                              id="billingCountryAutocomplete"
                              options={countryList}
                              disableClearable={false}
                              getOptionLabel={(option) => option || ''}
                              value={field?.value}
                              onChange={(e, value) => {
                                field.onChange(value)
                              }}
                              autoHighlight={true} // required to select entry without submitting entire material-table row
                              onKeyDown={e => handleKey(e)}
                              isOptionEqualToValue={(option, value) => {
                                return option === value
                              }}
                              renderOption={(props, option) => {
                                return(
                                  <li {...props}>
                                    <div>{option || '---'}</div>
                                  </li>
                                )
                              }}
                              size="small"
                              renderInput={(params) => {
                                params.InputProps.style = {}
                                return(
                                  <TextField
                                    {...params}
                                    label="Land"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : null}
                                  />
                                )
                              }}
                            />
                          )}
                        />

                      </div>

                    </Box>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

          </div>
          <div className="text-center mx-auto mt-2">
            <button
              type="submit"
              className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
            >
              Weiter
            </button>
          </div>
        </form>

      </div>
    </div>
  )
}

export default AccountDetails
