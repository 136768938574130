import NewsletterFormHotelRequest from "../components/NewsletterFormHotelRequest";

const HotelRequest = () => {

  return (
    <div className="max-w-2xl mx-auto my-8">
      <NewsletterFormHotelRequest source={'StandaloneHotelRequest'} />
    </div>
    )

}

export default HotelRequest