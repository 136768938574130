import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {HotelListEntry} from './HotelListEntry';
import {MapIcon} from '@heroicons/react/24/outline'
import * as actions from "../store/actions";
import SearchFilterDropDown from "./SearchFilterDropDown";
import SearchSortDropDown from "./SearchSortDropDown";
import NewsletterFormHotelRequest from "./NewsletterFormHotelRequest";

export const HotelList = (props) => {

  const dispatch = useDispatch()

  const {highlightHotel, setHighlightHotel} = props

  const hotelList = useSelector(state => state.search.hotelList)
  const searchCity = useSelector(state => state.search.searchCity)
  const mapsWindowSearch = useSelector(state => state.map.mapsWindowSearch)

  const jumpView = () => {
    const element = document.getElementById("cityMap")
    element.scrollIntoView({behavior: "smooth", block: "end"})
    dispatch(actions.moveMapSearch({
      ...mapsWindowSearch,
      zoom: 10,
    }))
  }

  return (
    <div>

      <div className="flex justify-between items-start">
        <h2 className="text-2xl pb-5">Hotels in {searchCity?.cityName || '(error)'}</h2>

        <div className="flex justify-center items-center border border-gray-400 rounded-lg p-1 m-1 text-gray-400 lg:hidden cursor-pointer">
          <MapIcon className="w-5 h-5 mr-2" />
          <span className="text-sm" onClick={jumpView}>Zur Karte</span>
        </div>

        <div className="hidden lg:flex items-center">
          <SearchSortDropDown />
          <SearchFilterDropDown />
        </div>
      </div>
      {
        hotelList.length > 0
        ? hotelList.map((hotel, index) => {
            return(<div key={hotel.uuid}>
                {searchCity.hotelRequestModulePosition && (index === searchCity.hotelRequestModulePosition-1)
                  ? <NewsletterFormHotelRequest
                    source={'CitySearchHotelRequest'}
                    city={searchCity.cityName}
                  />
                  : ''
                }
                <HotelListEntry
                  hotel={hotel}
                  key={hotel.uuid}
                  highlightHotel={highlightHotel}
                  setHighlightHotel={setHighlightHotel}
                />
              </div>)
          })
          : <div className="mb-16">Kein Hotel mit diesen Filterkriterien gefunden.</div>
      }

      {searchCity.hotelRequestModulePosition && (hotelList.length < searchCity.hotelRequestModulePosition)
        ? <NewsletterFormHotelRequest
            source={'CitySearchHotelRequest'}
            city={searchCity.cityName}
          />
        : ''
      }

    </div>
  )
}
