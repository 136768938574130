import React from 'react';
import {Link} from "react-router-dom";
import Check from "../assets/svg/Check";


const Success = () => {

  return(
      <div className="container max-w-screen-lg mx-auto mb-10">
        <div className="w-2/3 mx-auto">
          <div className="text-center text-3xl font-light mt-24">Abo erfolgreich abgeschlossen</div>
          <div className="flex justify-center">
            <Check color="#00ab1a" />
          </div>
          <div className="text-center text-md font-light mt-8 mb-16">Willkommen bei MyFlexHome.<br/>
            In Kürze erhältst du von uns eine E-Mail mit weiteren Informationen zur Zahlung.<br/><br/>
            Gehe zum <Link className="underline" to="/dashboard/">Abo-Dashboard</Link>, um weitere Nächte zu buchen.
          </div>
        </div>
      </div>
    )

}

export default Success
