import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonBack} from "../components/Buttons";
import * as actions from "../store/actions";
import {formatDate} from "../functions/dateHelper";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import {useHistory} from "react-router";

const CustomerCompanyInvite = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const companyInvites = useSelector(state => state.customer.companyInvites)
  const companyInvitesLoading = useSelector(state => state.customer.companyInvitesLoading)
  const companyInvitesList = companyInvites?.BusinessCompany?.BusinessUserInvitations || []

  useEffect(() => {
    dispatch(actions.getCustomerCompanyInvite())
  }, [dispatch])


  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack />

        <div className="text-center text-3xl font-light mt-8">Unternehmen</div>
        <div className="text-center text-md font-light mt-2">Weitere Personen zu deinem Unternehmensaccount hinzufügen.</div>

        <div className="flex justify-center rounded-lg mt-8">

          <table className="table-auto border-collapse border border-slate-400 text-sm md:text-base">
            <thead>
            <tr className="text-gray-500">
              <th className="border border-slate-300 p-3">Name</th>
              <th className="border border-slate-300 p-3">E-Mail</th>
              <th className="border border-slate-300 p-3">Einladung</th>
              <th className="border border-slate-300 p-3">Self-Service</th>
            </tr>
            </thead>
            <tbody>
            {companyInvitesList.map(x =>
              <tr key={x.invitationID}>
                <td className="border border-slate-300 p-2">{x.firstName} {x.lastName}</td>
                <td className="border border-slate-300 p-2 break-all">{x.email}</td>
                <td className="border border-slate-300 p-2">{formatDate(x.createdAt)}</td>
                <td className="border border-slate-300 p-2">{x.selfService ? 'ja' : 'nein'}</td>
              </tr>)}
            </tbody>
          </table>

        </div>
        {companyInvitesLoading
          ? <div className="flex justify-center mt-4"><LoadingSpinner /></div>
          : ''
        }
        {!companyInvitesLoading && companyInvitesList.length === 0
          ? <div className="text-center mt-4 text-gray-500">Aktuell wurden noch niemand eingeladen.</div>
          : ''
        }

        <div className="text-center mt-10">
          <button
            className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-60 m-2"
            onClick={() => {history.push('/dashboard/company/invite/add/'); window.scrollTo(0, 0)}}
          >Person hinzufügen</button>
        </div>

      </div>

    </div>
  )
}

export default CustomerCompanyInvite
