import React from 'react'
import tripadvisorLogo from '../assets/svg/tripadvisorLogo.svg'

const RatingTripadvisor = (props) => {

  const {rating, uri} = props

  const Full = () =>
    <svg width="100%" height="100%" viewBox="0 0 24 24" className="h-3 w-3 fill-tripadvisor">
      <g transform="matrix(1.2,0,0,1.2,-2.4,-2.4)">
        <path d="M12,4C7.582,4 4,7.582 4,12C4,16.418 7.582,20 12,20C16.418,20 20,16.418 20,12C20,7.582 16.418,4 12,4ZM2,12C2,6.477 6.477,2 12,2C17.523,2 22,6.477 22,12C22,17.523 17.523,22 12,22C6.477,22 2,17.523 2,12Z"/>
      </g>
      <g transform="matrix(1.2,0,0,1.2,-4.2,-4.2)">
        <circle cx="13.5" cy="13.5" r="6.5"/>
      </g>
    </svg>

  const Half = () =>
    <svg width="100%" height="100%" viewBox="0 0 24 24" className="h-3 w-3 fill-tripadvisor">
      <g transform="matrix(1.2,0,0,1.2,-2.4,-2.4)">
        <path d="M12,4C7.582,4 4,7.582 4,12C4,16.418 7.582,20 12,20C16.418,20 20,16.418 20,12C20,7.582 16.418,4 12,4ZM2,12C2,6.477 6.477,2 12,2C17.523,2 22,6.477 22,12C22,17.523 17.523,22 12,22C6.477,22 2,17.523 2,12Z" />
      </g>
      <g transform="matrix(1.2,0,0,1.2,-4.2,-4.2)">
        <path d="M13.5,7C13.529,7 13.532,20 13.5,20C9.913,20 7,17.087 7,13.5C7,9.913 9.913,7 13.5,7Z" />
      </g>
    </svg>

  const Empty = () =>
    <svg width="100%" height="100%" viewBox="0 0 24 24" className="h-3 w-3 fill-tripadvisor">
      <g transform="matrix(1.2,0,0,1.2,-2.4,-2.4)">
        <path d="M12,4C7.582,4 4,7.582 4,12C4,16.418 7.582,20 12,20C16.418,20 20,16.418 20,12C20,7.582 16.418,4 12,4ZM2,12C2,6.477 6.477,2 12,2C17.523,2 22,6.477 22,12C22,17.523 17.523,22 12,22C6.477,22 2,17.523 2,12Z" />
      </g>
    </svg>


  const countFull = Math.floor(rating)
  const countHalf = (rating % 1 > 0) ? 1 : 0
  const countEmpty = 5 - (countFull + countHalf)

  return (
    <div className="flex items-center text-gray-400 hover:opacity-70" onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      window.open(uri, '_blank');
    }}>
      <img src={tripadvisorLogo} style={{ width: 27, height: 18, marginRight: 4 }} alt="Tripadvisor" />
      { [...Array(countFull)].map((e, i) => <Full key={'full'+i} />) }
      { [...Array(countHalf)].map((e, i) => <Half key={'full'+i} />) }
      { [...Array(countEmpty)].map((e, i) => <Empty key={'full'+i} />) }
    </div>
  )

}

export default RatingTripadvisor





