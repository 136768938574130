import {FormField} from './FormField';
import React, {useState} from 'react';
import axios from 'axios';
import LoadingSpinner from '../assets/svg/LoadingSpinner';
import Check from '../assets/svg/Check';
import FormCheckbox from './FormCheckbox';
import * as actions from "../store/actions";
import {useDispatch} from "react-redux";

const NewsletterForm = (props) => {
  const {
    headline,
    description,
    source,
    city,
    showCityInput=false,
    showNewsletterInput=false,
    showDiscountNewsletterInput=false,
    showBetaInput=false,
    inputDefaultNewsletter=false,
    inputDefaultBeta=false,
    sendButtonText='Senden',
    readyButtonText='Gesendet'
  } = props

  const dispatch = useDispatch()

  const LoadingButton = () => {
    return(
      <button
        className="bg-primary text-white hover:bg-primaryHover p-2 w-56 rounded inline-flex cursor-wait"
        type="button"
      >
        <LoadingSpinner color="white" /> Senden
      </button>
    )
  }

  const SendButton = () => {
    return(
      <button
        className="bg-primary text-white p-2 w-56 rounded hover:bg-secondaryBlue"
        type="submit"
      >
        {sendButtonText}
      </button>
    )
  }

  const ReadyButton = () => {
    return(
      <button
        className="bg-green-600 hover:bg-green-600 text-white p-2 w-56 rounded inline-flex cursor-default"
        type="button"
      >
        <Check color="white" /> <div className="ml-2">{readyButtonText}</div>
      </button>
    )
  }

  const inputDefaults = {
    name: '',
    email: '',
    city: city,
    source: source,
    newsletter: inputDefaultNewsletter,
    betaUser: inputDefaultBeta,
  }

  // local state - form controller
  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set())
  const [forceUpdate, setForceUpdate] = useState(1)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const inputChangeHandler = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    if(errors.has(key)) validateInput(event.target)
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    //setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (key, value) => {
    let error
    switch (key) {
      case 'name':
        if (value?.length < 2) error = true; break;
      case 'email':
        if (typeof(value) === 'undefined' || value === null || !RegExp("^.+@.+(\\.).+$").test(value)) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      console.log(errors)
    } else {
      console.log('ok')
      postMessage(input)
    }
  }

  const postMessage = (payload) => {
    setLoading(true)
    axios.post('/api/newsletter',payload)
      .then ( response => {
        console.log('message sent')
        setSuccess(true)
        setLoading(false)
        setTimeout(() => {
          dispatch(actions.hideNewsletterModal())
        }, 1500)
        return true
      })
      .catch(error => {
        console.log('API error')
        setSuccess(false)
        setLoading(false)
        setTimeout(() => {
          dispatch(actions.hideNewsletterModal())
        }, 1500)
        return false
      })
  }

  let formButton = <SendButton />
  if(loading) formButton = <LoadingButton />
  if(success) formButton = <ReadyButton />

  return (
    <form id="contact_form" onSubmit={handleSubmit} autoComplete="on" className="w-full border border-secondaryLightGray rounded-md bg-gray-50 py-6 px-8">
      { headline ? <h3 className="text-3xl font-light mb-2">{headline}</h3> : null }
      { description ? <p className="mb-8 font-light">{description}</p> : null }
      <div className="flex justify-end text-xs text-gray-400 mb-4 mx-4">*Pflichtfeld</div>
      {FormField({
        fieldId: 'name',
        fieldName:'Vorname',
        required: true,
        handlers: handlers,
        errorText: 'Bitte gib deinen Vornamen an.'
      })}
      {FormField({
        fieldId: 'email',
        fieldName:'E-Mail',
        required: true,
        handlers: handlers,
        errorText: 'Bitte gib eine gültige E-Mail-Adresse an.'
      })}
      { showCityInput ? FormField({
        fieldId: 'city',
        fieldName:'Stadt',
        required: false,
        handlers: handlers,
      }) : null }
      { showNewsletterInput ? FormCheckbox({
        fieldId: 'newsletter',
        fieldName:<div><b>Zusätzlich zum Newsletter anmelden</b><br/>Attraktive "Pre-Release" Angebote und spannende News zu MyFlexHome in deiner Inbox.</div>,
        handlers: handlers,
      }) : null}
      { showBetaInput ? FormCheckbox({
        fieldId: 'betaUser',
        fieldName:<div><b>Pilotkunde</b> - Ich möchte mich als Pilotkunde vormerken lassen und attraktive “Pre-Release” Angebote erhalten.<br />
          Sei der Erste in deiner Stadt! Jetzt kostenlos & unverbindlich auf die Warteliste eintragen.</div>,
        handlers: handlers,
      }) : null}
      { showDiscountNewsletterInput ? FormCheckbox({
        fieldId: 'newsletter',
        fieldName:<div>Ja, ich möchte mir 10% Rabatt sichern und zukünftig über Rabatte und Aktionen informiert werden.</div>,
        handlers: handlers,
      }) : null}
      <div className="text-xs font-light py-6 text-center">
        Die E-Mail Benachrichtigung kann jederzeit widerrufen werden. <br/>
        Mehr dazu findest du in unserer <a href="/datenschutz/" className="underline hover:text-primaryHover">Datenschutzerklärung</a>.
      </div>
      <div className="flex justify-center">{formButton}</div>
    </form>
  )
}

export default NewsletterForm

