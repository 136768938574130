import React from 'react';

const MfhLogo = ({fill='#FFFFFF'}) => (
  <svg width="16" height="16" viewBox="0 0 570 546">
    <path id="E1" fill={fill} fillRule="evenodd" stroke="none" d="M 284.5 537 C 132.345703 537 9 418.80304 9 273 C 9 127.196991 132.345703 9 284.5 9 C 436.654297 9 560 127.196991 560 273 C 560 418.80304 436.654297 537 284.5 537 Z M 284.5 501 C 416.772064 501 524 398.920807 524 273 C 524 147.079193 416.772064 45 284.5 45 C 152.227951 45 45 147.079193 45 273 C 45 398.920807 152.227951 501 284.5 501 Z"/>
    <path id="P1" fill={fill} fillRule="evenodd" stroke="none" d="M 139 463 L 143 320 L 300 197 L 451 325 L 452 440 L 495 397 L 495 311 C 495 311 497.770233 304.928772 491 299 C 487.930817 296.312286 487 295 487 295 C 487 295 318 156 318 156 C 318 156 313.998688 149.347595 301 149 C 288.100372 148.65506 281 156 281 156 L 114 289 C 114 289 105.325989 296.811249 103 302 C 100.992653 306.477905 101 318 101 318 L 99 429 L 139 463 Z"/>
    <path id="R1" fill={fill} fillRule="evenodd" stroke="none" d="M 353 360 C 353 362.209137 354.790863 364 357 364 L 409 364 C 411.209137 364 413 362.209137 413 360 L 413 346 C 413 343.790863 411.209137 342 409 342 L 357 342 C 354.790863 342 353 343.790863 353 346 Z"/>
  </svg>
)

export default MfhLogo





