
export const createUrlWithParams = (base, params={}) => {
  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value)
  }
  const paramsString = searchParams.toString() // e.g. "type=all&query=coins"

  if(paramsString.length > 0) {
    return base + '?' + paramsString
  } else {
    return base
  }
}


export const updateCurrentUrlParams = (history, params) => {
  const searchParams = new URLSearchParams(window.location.search)
  for (const [key, value] of Object.entries(params)) {
    /* use 'set' to replace existing parameters with same name */
    searchParams.set(key, value)
  }
  /*
   * Windows location is not used, as this would force a full SPA page reload
   */
  //window.location.search = urlParams

  /*
   * React router history push just updates url
   */
  history.push(window.location.pathname + '?' + searchParams)
}


