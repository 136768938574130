import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/solid'
import {ClockIcon, FingerPrintIcon, HomeIcon} from '@heroicons/react/24/outline'
import React, {Fragment, useEffect} from "react";
import * as actions from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {subscriptionStatusLabel} from "../functions/enum";
import {formatDate} from "../functions/dateHelper";
import {useHistory} from "react-router";
import Tippy from "@tippyjs/react";


const CustomerSubscriptionSelection = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.updateCustomerSelectedSubscription())
  }, [dispatch])

  const subscriptions = useSelector(state => state.customer.subscriptions)
  const selectedSubscription = useSelector(state => state.customer.selectedSubscription)
  const runningSubscriptions = subscriptions.filter(subscription => ['upcoming', 'active', 'cancelled', 'paused'].includes(subscription?.subscriptionData?.status))

  const hotelName = selectedSubscription?.subscriptionData?.BookingHotel?.name || ''
  const roomName = selectedSubscription?.subscriptionData?.BookingRoom?.name || ''
  const startDate = selectedSubscription?.subscriptionData?.startDate || ''
  const endDate = selectedSubscription?.subscriptionData?.endDate || ''
  const status = subscriptionStatusLabel[selectedSubscription?.subscriptionData?.status] || ''
  const subscriptionID = selectedSubscription?.subscriptionID || ''

  const handleSubscriptionChange = (e) => {
    dispatch(actions.updateCustomerBookingReservationDelta([]))
    dispatch(actions.updateCustomerSelectedSubscription(e))
    dispatch(actions.updateCustomerCompanySelectedEmployee(null))
    history.push('/dashboard/booking/'+e.subscriptionID)
  }

  return(
    <div className="w-80">
      <div className="text-gray-500 text-sm">Auf welches Abo wird gebucht?</div>
      <Listbox value={selectedSubscription} onChange={handleSubscriptionChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default bg-white py-2 pl-3 pr-10 text-left rounded-md border border-gray-200 shadow-sm focus:outline-none focus-visible:border-teal-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary sm:text-sm">
            <div className="block truncate">

              <Tippy theme="light" content="Hotel">
                <span className="text-base">{hotelName}</span>
              </Tippy>
              <div className="flex items-center font-light text-sm">
                <Tippy theme="light" content="Zimmer-Kategorie">
                  <div className="flex items-center">
                    <HomeIcon className="h-4 w-4 mr-1" />
                    <span className="text-gray-600">{roomName}</span>
                  </div>
                </Tippy>
                &nbsp;&nbsp;
                <Tippy theme="light" content="Abo-ID">
                  <div className="flex items-center">
                    <FingerPrintIcon className="h-4 w-4 mr-1" />
                    <span className="text-gray-600">{subscriptionID}</span>
                  </div>
                </Tippy>
              </div>
              <div className="flex items-center font-light text-sm">
                <Tippy theme="light" content="Laufzeit und Abo-Status">
                  <div className="flex items-center">
                    <ClockIcon className="h-4 w-4 mr-1" title="Laufzeit" />
                    <span className="text-gray-700">
                      {endDate ? '' : 'Ab '}{startDate ? formatDate(startDate) : ''} {endDate ? `bis ${formatDate(endDate)}` : ''}
                      &nbsp;|&nbsp;
                      <span className="">{status}</span>
                    </span>
                  </div>
                </Tippy>
              </div>

            </div>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {runningSubscriptions.map((sub, subIdx) => {
                const hotelName = sub.subscriptionData?.BookingHotel?.name
                const roomName = sub.subscriptionData?.BookingRoom?.name
                const startDate = sub.subscriptionData?.startDate
                const endDate = sub.subscriptionData?.endDate
                const status = subscriptionStatusLabel[sub.subscriptionData?.status]

                return(
                <Listbox.Option
                  key={subIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-yellow-50' : 'text-gray-800'
                    }`
                  }
                  value={sub}
                >
                  {({ selected }) => (
                    <>
                      <div
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >

                        <Tippy theme="light" content="Hotel">
                          <span className="text-base">{hotelName}</span>
                        </Tippy>
                        <div className="flex items-center font-light text-sm">
                          <Tippy theme="light" content="Zimmer-Kategorie">
                            <div className="flex items-center">
                              <HomeIcon className="h-4 w-4 mr-1" />
                              <span className="text-gray-600">{roomName}</span>
                            </div>
                          </Tippy>
                          &nbsp;&nbsp;
                          <Tippy theme="light" content="Abo-ID">
                            <div className="flex items-center">
                              <FingerPrintIcon className="h-4 w-4 mr-1" />
                              <span className="text-gray-600">{sub.subscriptionID}</span>
                            </div>
                          </Tippy>
                        </div>
                        <div className="flex items-center font-light text-sm">
                          <Tippy theme="light" content="Laufzeit und Abo-Status">
                            <div className="flex items-center">
                              <ClockIcon className="h-4 w-4 mr-1" title="Laufzeit" />
                              <span className="text-gray-700">
                                {endDate ? '' : 'Ab '}{startDate ? formatDate(startDate) : ''} {endDate ? `bis ${formatDate(endDate)}` : ''}
                                &nbsp;|&nbsp;
                                <span className="">{status}</span>
                              </span>
                            </div>
                          </Tippy>
                        </div>

                      </div>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-yellow-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default CustomerSubscriptionSelection