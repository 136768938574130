import React from 'react'
import Tippy from "@tippyjs/react";

const RatingStars = (props) => {

  const {rating, size='h-3 w-3', classes='flex'} = props

  /* const Star = () =>
    <svg viewBox="0 0 24 24" className={`${size} fill-yellow`}>
      <path d="M15.668,8.626L24,9.785L17.935,15.659L19.415,24L11.999,20.003L4.583,24L6.064,15.659L-0,9.785L8.331,8.626L11.999,1L15.668,8.626Z"/>
    </svg>
  */

  const Circle = () =>
    <svg viewBox="0 0 42 42" className={`${size} fill-star`}>
      <ellipse cx="21.057" cy="20.815" rx="17.964" ry="18.148" />
    </svg>


  const timesFull = Math.round(rating)

  return (
   <Tippy theme="light" content={
     <div>Diese Einstufung oder Sternebewertung spiegelt wider, wie das Hotel im Hinblick auf branchenübliche Standards eingeschätzt wird.</div>
   }>
    <div className={classes}>
      { [...Array(timesFull)].map((e, i) => <Circle key={'full'+i} />) }
    </div>
   </Tippy>
  )

}

export default RatingStars





