import * as React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {formatDate, formatDateDayMonth, formatDateWeekday} from '../functions/dateHelper';
import {useSelector} from 'react-redux';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 4,
    marginBottom: 4,
  },
  fontSize: 12,
  minHeight: 30,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  fontSize: 12,
}))


const HotelDetailsSpecialDates = () => {
  const [expanded, setExpanded] = React.useState('panel1')

  const subscriptionRoomUuid = useSelector(state => state.offer.subscriptionRoomUuid)
  const hotelDetail = useSelector(state => state.offer.hotelDetail)
  const specialDates = hotelDetail?.BookingSpecialDates || []

  const specialDatesBlackOut = specialDates.filter(d => d.blackOut)
  const specialDatesSurcharge = specialDates.filter(d => !d.blackOut && !d.fullyBooked)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <div className="text-lg mb-2 mt-4">Termine mit Aufpreis</div>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          Was sind besondere Termine/ Messezeiten in diesem {hotelDetail.hotelOfferType === 'district' ? 'Stadtteil-Abo' : 'Hotel'}?
        </AccordionSummary>
        <AccordionDetails>

          {specialDatesSurcharge.length > 0
            ? <>
                <div className="">An folgenden Anreisetagen sind zum {hotelDetail.hotelOfferType === 'district' ? 'Abo' : 'Hotel'} <i>{hotelDetail.name}</i> Aufpreise
                  pro Nacht hinterlegt, die nicht vom Abo abgedeckt sind:
                </div>
                <ul className="pl-6 mt-2">
                  {
                    specialDatesSurcharge.map(x => {
                      const surchargeSelectedRoom = x.BookingSpecialDateSurcharges?.filter(sc =>
                        sc.BookingRoom.uuid === subscriptionRoomUuid)?.[0]?.surcharge
                      const surchargeString = surchargeSelectedRoom
                        ? surchargeSelectedRoom.toFixed(2).replace('.',',')+' €'
                        :  ''
                      return (<li key={x.dateFrom}>
                        {formatDateWeekday(x.dateFrom)} {formatDateDayMonth(x.dateFrom)} - {formatDateWeekday(x.dateTo)} {formatDate(x.dateTo)} &mdash; {x.description} &nbsp;
                        {surchargeString}
                      </li>)})
                  }
                </ul>
                <div className="mt-4 mb-4">Wir möchten die Abo-Preise konstant halten.
                  Leider sind die Schwankungen zu speziellen Events und Messen so groß, dass wir es nicht ganz ohne Aufpreis schaffen.
                  Nur durch diese wenige Termine mit Aufschlag ist es uns möglich, den Abo Preis für alle günstig zu halten.
                </div>
              </>
            : ''
          }

          {specialDatesBlackOut.length > 0
            ? <>
                <div className="">An folgenden Anreisetagen ist eine Übernachtung im {hotelDetail.hotelOfferType === 'district' ? 'Stadtteil-Abo' : 'Hotel'} leider nicht möglich.
                  Für eine Alternative schreib uns an hello@myflexhome.de</div>
                <ul className="pl-6 mt-2">
                  {
                    specialDatesBlackOut.map(x => {
                      return (<li key={x.dateFrom}>
                        {formatDateWeekday(x.dateFrom)} {formatDateDayMonth(x.dateFrom)} - {formatDateWeekday(x.dateTo)} {formatDate(x.dateTo)} &mdash; {x.description}
                      </li>)})
                  }
                </ul>
              </>
            : ''
          }


        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default HotelDetailsSpecialDates
