import React, {useEffect, useState} from 'react';
import deLocale from 'date-fns/locale/de';
import {isAfter, isBefore} from 'date-fns';
import {Button, DayPicker, useDayRender} from 'react-day-picker';
import StepperBar from '../components/StepperBar';
import {ThemeProvider} from '@emotion/react';
import {createTheme, TextField} from '@mui/material';
import {deDE} from '@mui/material/locale';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import Tippy from '@tippyjs/react';
import {
  addTimezoneOffset,
  compareDates,
  compareDateToArray,
  dateArrayFromRange,
  dateXDaysFromDate,
  dateXMonthsAndDaysFromDate,
  filterDayArrayByRange,
  formatDateDayMonthWithoutYear,
  formatDateWeekday,
  getDateAsUTC,
  mergeMultipleDateRangesToArray,
  removeTimezoneOffset,
} from '../functions/dateHelper';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../store/actions';
import {DayPickerSummary} from "../components/DayPickerSummary";
import {DayPickerLegend} from "../components/DayPickerLegend";

const Booking = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  /* date helper */
  const today = new Date().setHours(0,0,0,0)
  const tomorrow = dateXDaysFromDate(today, 1)
  const inThreeDays = dateXDaysFromDate(today, 3)

  /* redux states */
  const subscriptionCategory = useSelector(state => state.offer.subscriptionCategory)
  const subscriptionDuration = useSelector(state => state.offer.subscriptionDuration)
  const offerID = useSelector(state => state.offer.offerID)
  const subscriptionStartDate = useSelector(state => state.offer.subscriptionStartDate)
  const selectedNights = useSelector(state => state.offer.selectedNights)
  const hotelDetail = useSelector(state => state.offer.hotelDetail)
  const product = useSelector(state => state.offer.product)
  const subscriptionRoomUuid = useSelector(state => state.offer.subscriptionRoomUuid)
  const fromDate = useSelector(state => state.offer.bookingCalendarFrom)
  const toDate = useSelector(state => state.offer.bookingCalendarTo)

  const specialDateRanges = hotelDetail?.BookingSpecialDates || []
  const specialDatesMerged = mergeMultipleDateRangesToArray(specialDateRanges)
  const specialDates = filterDayArrayByRange(specialDatesMerged, tomorrow, dateXDaysFromDate(today, 90))

  const blackOutRanges = hotelDetail?.BookingSpecialDates?.filter(x => x.blackOut === true || x.fullyBooked === true) || []
  const blackOutRangesMerged = mergeMultipleDateRangesToArray(blackOutRanges)

  const month1from = subscriptionStartDate
  const month1to = dateXMonthsAndDaysFromDate(subscriptionStartDate, 1, -1)
  const month1days = filterDayArrayByRange(selectedNights, month1from, month1to)

  const month2from = dateXMonthsAndDaysFromDate(subscriptionStartDate, 1,0)
  const month2to = dateXMonthsAndDaysFromDate(subscriptionStartDate, 2,-1)
  const month2days = filterDayArrayByRange(selectedNights, month2from, month2to)

  const month3from = dateXMonthsAndDaysFromDate(subscriptionStartDate, 2,0)
  const month3to = dateXMonthsAndDaysFromDate(subscriptionStartDate, 3,-1)
  const month3days = filterDayArrayByRange(selectedNights, month3from, month3to)

  /* check for preconditions, otherwise start from scratch */
  useEffect(() => {
    if (!subscriptionRoomUuid) {
      history.push('/search/muenchen/?nights=4')
    }
  }, [history, subscriptionRoomUuid])

  /* local state */
  const [defaultMonth] = useState(today)
  const [month, setMonth] = useState(today)

  const disabledStartDates = (date) => {
    return isBefore(date, inThreeDays)
  }

  const [errors] = useState(new Set())

  const handleStartDateChange = (date) => {
    dispatch(actions.updateOfferSelectionNights([]))
    dispatch(actions.updateOfferSelectionSubscriptionStartDate(getDateAsUTC(date)))
    setMonth(date)
  }

  const unselectDay = (day) => {
    const days = [...selectedNights]
    const newDays = days.filter(item => compareDates(item, day) === false)
    dispatch(actions.updateOfferSelectionNights(newDays))
  }

  const handleDayClick = (day, { selected }) => {
    const cleanDay = getDateAsUTC(day)
    if (selected) {
      /* day ist already in the selection --> remove day from selection */
      unselectDay(cleanDay)
    } else {
      /* add day to selection, check limits */
      const days = [...selectedNights]
      //if(days.length < subscriptionCategory) {
        days.push(cleanDay)
        days.sort((a,b)=>a.getTime()-b.getTime())
      //}
      dispatch(actions.updateOfferSelectionNights(days))
    }
  }

  const handleSaveSelectedNights = () => {
    dispatch(actions.putCheckoutUpdateCart({
      hotelId: hotelDetail.hotelId,
      subscriptionCategory: subscriptionCategory,
      subscriptionDuration: subscriptionDuration,
      offerID: offerID,
      subscriptionStartDate: getDateAsUTC(subscriptionStartDate),
      subscriptionRoomUuid: subscriptionRoomUuid,
      selectedNights: selectedNights,
    }))
  }

  const skipNightSelection = () => {
    dispatch(actions.updateOfferSelectionNights([]))
    dispatch(actions.putCheckoutUpdateCart({
      hotelId: hotelDetail.hotelId,
      subscriptionCategory: subscriptionCategory,
      subscriptionDuration: subscriptionDuration,
      offerID: offerID,
      subscriptionStartDate: getDateAsUTC(subscriptionStartDate),
      subscriptionRoomUuid: subscriptionRoomUuid,
      selectedNights: [],
    }))
  }

  const specialDateStyle = { border: '2px solid #cbcbcb' }
  //const noGuaranteeDays = dateArrayFromDate(6, tomorrow)
  //const noGuaranteeStyle = { textDecorationLine: 'underline', textDecorationStyle: 'wavy', textDecorationColor: '#CCC' }

  const getTooltipText = (date) => {
    let tooltipText = ''
    //if(compareDates(props.date, blackOutDates) {
    //  tooltipText = 'Blackout Datum. Nur mit Aufpreis buchbar.'
    //}
    //if(compareDateToArray(date, noGuaranteeDays)) {
    //  const text = <div><div className="font-bold">Kurzfristige Buchung</div>Das Hotel muss diese Buchung erst final bestätigen.</div>
    //  tooltipText = <>{tooltipText}{text}<br /></>
    //}
    specialDateRanges.forEach(specialDateRange => {
      const rangeArr = dateArrayFromRange(specialDateRange.dateFrom, specialDateRange.dateTo)
      const surcharge = specialDateRange.BookingSpecialDateSurcharges.filter(surcharge => surcharge.BookingRoom.uuid === subscriptionRoomUuid)[0]?.surcharge

      if(compareDateToArray(date, rangeArr) && (specialDateRange.blackOut || surcharge)) {
        const text = <div>
          <div className="font-bold">{specialDateRange.description}</div>
            <div>{specialDateRange.blackOut ? 'Diese Nacht ist nicht verfügbar.'
              : <>
                <div className="text-secondaryBlue">{surcharge ? <span>{surcharge.toFixed(2).replace('.',',')} € Aufpreis</span> : ''}</div>
                Dies ist eine der wenigen Nächte mit Aufpreis in diesem Hotel.
                Die komplette Liste findest du transparent auf der Hotelseite.
                Nur mit wenigen einzelnen Aufpreisen können wir den Abo-Preis für das restliche Jahr niedrig halten.
                </>
            }</div>
          </div>
        tooltipText = <>{tooltipText}{text}<br /></>
      }

      if(compareDateToArray(date, rangeArr) && (specialDateRange.fullyBooked)) {
        const text = <div>
          <div className="font-bold">Ausgebucht</div>
          <div>Für eine Alternative schreibe uns an hello@myflexhome.de.</div>
        </div>
        tooltipText = <>{tooltipText}{text}<br /></>
      }
    })

    if(compareDateToArray(date, selectedNights)) {
      const text = <div>
          <div className="font-bold">Ausgewählte Nacht</div>
          {formatDateWeekday(removeTimezoneOffset(date))} auf {formatDateWeekday(dateXDaysFromDate(removeTimezoneOffset(date),1))}
        </div>
      tooltipText = <>{tooltipText}{text}<br /></>
    }
    if(isBefore(date, addTimezoneOffset(subscriptionStartDate))) {
      tooltipText = <div>Diese Nacht liegt vor Beginn deines Abos.<br /><br /></div>
    }
    if(isBefore(date, today)) {
      tooltipText = <div>Diese Nacht liegt in der Vergangenheit und ist nicht mehr buchbar.<br /><br /></div>
    }
    if(isAfter(date, toDate)) {
      tooltipText = <div>Diese Nacht liegt mehr als 90 Tage in der Zukunft und ist heute noch nicht buchbar.<br /><br /></div>
    }
    return tooltipText
  }

  const CustomDay = (props) => {
    const buttonRef = React.useRef(null)

    const dayRender = useDayRender(
      props.date,
      props.displayMonth,
      buttonRef
    )

    if (dayRender.isHidden) {
      return <></>
    }
    if (!dayRender.isButton) {
      return <div {...dayRender.divProps} />
    }

    const handleClick = (e) => {
      dayRender.buttonProps?.onClick?.(e)
    }

    const tooltipText = getTooltipText(props.date)

    if(tooltipText && window.innerWidth > 640) {
      return <Tippy theme="light" content={
        <div>{tooltipText}</div>
      }><div><Button {...dayRender.buttonProps} ref={buttonRef} onClick={handleClick} /></div></Tippy>
    } else {
      return <Button {...dayRender.buttonProps} ref={buttonRef} onClick={handleClick} />
    }

  }

  const muiTheme = createTheme({
    palette: {
      primary: {
        light: '#2bbdee',
        main: '#25A9D5',
        dark: '#4b9bc1',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#f3d970',
        main: '#E5CC68',
        dark: '#cab25c',
        contrastText: '#000000',
      }
    },
  }, deDE)

  const month1NightsOverbooked = month1days.length > subscriptionCategory && product !== 'business' && subscriptionCategory !== 0
  const month2NightsOverbooked = month2days.length > subscriptionCategory && product !== 'business' && subscriptionCategory !== 0
  const month3NightsOverbooked = month3days.length > subscriptionCategory && product !== 'business' && subscriptionCategory !== 0
  const errorNightsOverbooked = month1NightsOverbooked || month2NightsOverbooked || month3NightsOverbooked
  const errorWrongStartDate = subscriptionStartDate < inThreeDays || subscriptionStartDate > dateXDaysFromDate(today, 60)


  return (
    <div className="container max-w-screen-lg mx-auto mb-10">

      <div className="mx-auto px-3">
        <StepperBar active={1} />

        <div className="text-center text-2xl font-bold mt-10 text-secondaryBlue">Wähle deinen Abo-Beginn</div>
        <div className="text-center text-md mt-3 mb-8 text-gray-500">Frühester Start ist in 3 Tagen, spätester in 2 Monaten.</div>
        <div className="text-center">
          <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
              <DesktopDatePicker
                mask={'__.__.____'}
                format="dd.MM.yyyy"
                minDate={inThreeDays}
                maxDate={dateXDaysFromDate(today, 60)}
                shouldDisableDate={disabledStartDates}
                id="startDate"
                label="Start Datum"
                value={subscriptionStartDate}
                onChange={handleStartDateChange}
                error={errors.has('startDate')}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </div>

        <>
          <div className="text-center text-2xl font-bold mt-16 text-secondaryBlue">Wähle deine Nächte</div>

          {product === 'business'
            ? <div className="text-center mt-3 mb-8 text-gray-500">
                Buche optional die ersten Nächte für dich als Abo-Admin.<br/>
                Nach Abschluss des Business Abos kannst du beliebig viele Personen zum Unternehmensaccount einladen.<br/>
                Du entscheidest, ob du für andere direkt buchst oder für sie den Self-Service freischaltest.
              </div>
            : <div className="text-center text-md mt-3 mb-8 text-gray-500">
                Du musst nicht alle Nächte direkt wählen.<br/>
                Buche weitere Übernachtungen jederzeit in deinem Dashboard.
              </div>
          }

          <div className="flex justify-center">
            <DayPicker
              mode="uncontrolled"
              numberOfMonths={window.innerWidth > 800 ? 2 : 1}
              selected={selectedNights}
              onDayClick={handleDayClick}
              defaultMonth={defaultMonth}
              fromDate={fromDate}
              toDate={toDate}
              disabled={blackOutRangesMerged}
              locale={deLocale}
              showWeekNumber={true}
              showOutsideDays={false}
              fixedWeeks={false}
              month={month}
              onMonthChange={setMonth}
              modifiers={{
                //noGuarantee: noGuaranteeDays,
                special: specialDates,
              }}
              modifiersStyles={{
                //noGuarantee: noGuaranteeStyle,
                special: specialDateStyle,
              }}
              components={{ Day: CustomDay }}
            />
          </div>

          <DayPickerLegend />

          <div className="text-center text-base mt-4 mb-6 text-gray-600">
            <div className="text-xl text-secondaryBlue">Abo-Monat {formatDateDayMonthWithoutYear(month1from)} - {formatDateDayMonthWithoutYear(month1to)}</div>
            {product === 'business' || subscriptionCategory === 0
            ? <div>
                <span className="font-semibold">{month1days.length}</span> {month1days.length === 1 ? 'Nacht':'Nächte'} im ersten Abo-Monat gewählt
              </div>
            : <div>
                Du hast <span className={month1NightsOverbooked ? 'text-red-700' : 'text-green-700'}>
                <span className="font-semibold">{month1days.length}</span> von <span
                className="font-semibold">{subscriptionCategory}</span></span> Nächten im ersten Abo-Monat gewählt.
              </div>
            }
          </div>
          <DayPickerSummary
            selectedNights={month1days}
            specialDates={specialDateRanges}
            room={subscriptionRoomUuid}
            unselectDay={unselectDay}
          />

          { month2days.length
            ? <>
              <div className="text-center text-base mb-6 text-gray-600">
                <div className="text-xl text-secondaryBlue">Abo-Monat {formatDateDayMonthWithoutYear(month2from)} - {formatDateDayMonthWithoutYear(month2to)}</div>
                {product === 'business' || subscriptionCategory === 0
                  ? <div>
                    <span className="font-semibold">{month2days.length}</span> {month2days.length === 1 ? 'Nacht':'Nächte'} im zweiten Abo-Monat gewählt
                  </div>
                  : <div>
                    Du hast <span className={month2NightsOverbooked ? 'text-red-700' : 'text-green-700'}>
                    <span className="font-semibold">{month2days.length}</span> von <span
                    className="font-semibold">{subscriptionCategory}</span></span> Nächten im zweiten Abo-Monat gewählt.
                  </div>
                }
              </div>
              <DayPickerSummary
                selectedNights={month2days}
                specialDates={specialDateRanges}
                room={subscriptionRoomUuid}
                unselectDay={unselectDay}
              />
            </>
            : '' }
          { month3days.length
            ? <>
              <div className="text-center text-base mb-6 text-gray-600">
                <div className="text-xl text-secondaryBlue">Abo-Monat {formatDateDayMonthWithoutYear(month3from)} - {formatDateDayMonthWithoutYear(month3to)}</div>
                {product === 'business' || subscriptionCategory === 0
                  ? <div>
                    <span className="font-semibold">{month3days.length}</span> {month3days.length === 1 ? 'Nacht':'Nächte'} im dritten Abo-Monat gewählt
                  </div>
                  : <div>
                    Du hast <span className={month3NightsOverbooked ? 'text-red-700' : 'text-green-700'}>
                    <span className="font-semibold">{month3days.length}</span> von <span
                    className="font-semibold">{subscriptionCategory}</span></span> Nächten im dritten Abo-Monat gewählt.
                  </div>
                }
              </div>
              <DayPickerSummary
                selectedNights={month3days}
                specialDates={specialDateRanges}
                room={subscriptionRoomUuid}
                unselectDay={unselectDay}
              />
            </>
            : '' }


          <div className="text-center mx-auto mt-10">
            {errorNightsOverbooked
              ? <>
                <div className="text-sm text-red-700 mb-1">Nächte Limit überschritten. Bitte überprüfe deine Buchungen.</div>
                <button className="btn bg-gray-300 text-white text-lg rounded p-2 w-56">Buchungen speichern</button>
              </>
              : errorWrongStartDate
                ? <>
                  <div className="text-sm text-red-700 mb-1">Ungültiges Startdatum. Frühester Start ist in 3 Tagen, spätester in 2 Monaten.</div>
                  <button className="btn bg-gray-300 text-white text-lg rounded p-2 w-56">Buchungen speichern</button>
                </>
                : <button
                  className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
                  onClick={handleSaveSelectedNights}
                >
                  Buchungen speichern
                </button>

            }
          </div>
          <div className="text-center mx-auto mt-5">
            <div className="font-light mb-5">- oder -</div>
            {errorWrongStartDate
              ? <>
                <button className="btn bg-white text-gray-300 border border-gray-300 text-lg rounded p-2 w-56">
                  Später wählen
                </button>
              </>
              : <>
                <button
                  className="btn bg-white text-primary border border-primary text-lg hover:bg-gray-300 rounded p-2 w-56"
                  onClick={skipNightSelection}
                >
                  Später wählen
                </button>
              </>
            }
          </div>
        </>


      </div>

    </div>
  )
}

export default Booking
