import NewsletterForm from './NewsletterForm';
import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

export const NoHotelFound = () => {

  const searchCity = useSelector(state => state.search.searchCity)
  const cityList = useSelector(state => state.search.cityList)

  return (<>
    {searchCity?.cityId
      ? <div>
          <h2 className="text-2xl pb-5">Leider ist unser Angebot in {searchCity.cityName} noch nicht verfügbar</h2>
          <div className="max-w-lg mt-10">
            <NewsletterForm
              headline={'Erfahre sobald MyFlexHome in ' + searchCity.cityName + ' verfügbar ist.'}
              description={'Sei der Erste in deiner Stadt! Wir informieren dich per Mail.'}
              source={'CityNotYetAvailable'}
              city={searchCity.cityName}
              inputDefaultBeta={true}
              showNewsletterInput={true}
              showBetaInput={false}
              showCityInput={false}
            />
          </div>
        </div>
      : <div>
          <h2 className="text-2xl pb-5">Leider konnten wir die von dir gesuchte Stadt nicht finden</h2>
        <p>Bitte wähle eine der folgende Städte.</p>
          <ul className="list-disc mx-10 my-5">
            {cityList?.map(city => <li key={city.cityId}>
              <Link to={'/search/'+city.cityId+'/'} className="text-secondaryBlue underline">{city.cityName}</Link>
            </li>)}
          </ul>
        <p>Schreibe uns an <a href="mailto:hello@myflexhome.de?subject=Wunschstadt"
                                   className="text-secondaryBlue underline">hello@myflexhome.de</a>,
          wenn deine Wunschstadt noch nicht dabei ist.</p>
        </div>
    }
  </>)
}
