import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoggedIn: null,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    customerID: '',
    BookingSubscriptions: [],
  }
}

const setLogin = (state, action, isLoggedIn) => {
  return {
    ...state,
    isLoggedIn: true,
    user: action.res,
  }
}

const setLogout = (state) => {
  return {
    ...state,
    isLoggedIn: false,
    user: initialState.user,
  }
}

const setLoginWithRegistration = (state, action) => {
  return {
    ...state,
    isLoggedIn: true,
    user: {
      ...state.user,
      email: action.res.email,
      customerID: action.res.customerID,
    },
  }
}

const setLoginWithRegistrationDetail = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      firstName: action.res.data.firstName,
      lastName: action.res.lastName,
    },
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.LOGIN_SUCCESS: return setLogin(state, action)
    case actionTypes.POST_ACCOUNT_SUCCESS: return setLoginWithRegistration(state, action)
    case actionTypes.PUT_ACCOUNT_SUCCESS: return setLoginWithRegistrationDetail(state, action)
    case actionTypes.RESTORE_SESSION_SUCCESS: return setLogin(state, action)
    case actionTypes.LOGOUT_SUCCESS: return setLogout(state)
    //case actionTypes.GET_VERSION_SUCCESS: return setVersion(state, action)
    default: return state
  }
};

export default reducer
