import * as actionTypes from "../actions/actionTypes";


const initialState = {
  companyProfile: {},
}

const setCompanyProfile = (state, action) => {
  return {
    ...state,
    companyProfile: action.res || {},
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_BUSINESS_COMPANY_PROFILE_SUCCESS: return setCompanyProfile(state, action)

    default: return state
  }
}

export default reducer