import React from "react";
import Tippy from "@tippyjs/react";
import {formatDate} from "../functions/dateHelper";
import {Savings} from "../assets/svg/Savings";
import google_preis from "../assets/images/google_preis_mobil_compress.webp";
import {Info} from "@mui/icons-material";

export const HotelBadgeSavings = (props) => {
  const {hotel, align='justify-start m-1'} = props

  return(
    <div className={`flex ${align}`}>
      <Tippy theme="light" content={
        <div>

          <div className="text-savings mb-4">
            <div className="flex items-center mb-3"><Savings size="w-8 h-8"/><span className="text-lg ml-2">Deine Einsparung</span></div>
            Gegenüber Einzelbuchungen sparst du mit diesem MyFlexHome Angebot im Durchschnitt <span className="font-bold">{hotel.savingsInPercent}%</span>.
          </div>

          <div className="text-gray-600">
            Die Berechnung erfolgt auf Basis des MyFlexHome Preis
            von <span className="font-bold">{hotel.pricePerNightWithDiscount.toFixed(0)} €</span> pro Nacht gegenüber
            dem Durschnittspreises des {hotel.name} in der Google Hotel-Suche
            von <span className="font-bold">{hotel.avgGooglePrice.toFixed(0)} €</span> pro Nacht.
            <img src={google_preis} alt="Google Hotel Preisvergleich mit MyFlexHome" className="mt-2" />
            <div className="text-right text-xs text-gray-600">Google Preis abgefragt am {formatDate(hotel.googlePriceCheckedAt)}</div>
          </div>

        </div>
      }>
        <div
          onClick={(e) => {e.preventDefault();e.stopPropagation(); return false;}}
          className="text-gray-700 bg-green-50 text-xs rounded-lg px-3 py-2 sm:py-1 flex justify-center items-center">
          <span className="text-savings mr-1">{hotel.savingsInPercent}% Ersparnis im Abo</span>
          <Info className="text-savings"/>
        </div>
      </Tippy>
    </div>
  )
}