import React from 'react';

const Check = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} viewBox="0 0 20 20">
    <g stroke={props.color} strokeWidth="2.3" fill="none">
      <circle cx="10" cy="10" r="8.5"/>
      <path d="M5.2,10 8.5,13.4 14.8,7.2"/>
    </g>
  </svg>
)

export default Check





