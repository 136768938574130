import {format, isAfter} from "date-fns";
import deLocale from "date-fns/locale/de";
import {
  addTimezoneOffset,
  compareDates,
  compareDateToArray,
  dateXDaysFromDate,
  removeTimeFromDate,
  yesterday
} from "../functions/dateHelper";
import React from "react";
import Tippy from "@tippyjs/react";
import {getSurchargeByRoomAndDay} from "../functions/bookingHelper";
import {CheckIn} from "../assets/svg/CheckIn"

export const DayPickerSummary = (props) => {
  const {selectedNights, deltaDaysBookDays=[], deltaDaysCancelDays=[], bookingReservation=[], specialDates, room, unselectDay, readOnlyMode=false} = props

  return(
    <div className="flex flex-col items-center my-5">
      {selectedNights.length > 0
        ? selectedNights.map(item => {
          const day = new Date(item)
          const printDayInBrowser = addTimezoneOffset(new Date(item))
          let removeButtonDescription = 'Diese Buchung stornieren'
          if(compareDateToArray(day, deltaDaysBookDays)) removeButtonDescription = 'Buchungswunsch löschen'
          if(compareDateToArray(day, deltaDaysCancelDays)) removeButtonDescription = 'Stornierungswunsch löschen'
          const selectedNightReservation = bookingReservation.filter(reservation =>
            compareDates(day, reservation.arrivalDate)
          )?.[0] || {}
          return(
            <div className="mb-4" key={Date.parse(day)}>
              <div
                className="flex flex-row justify-between items-center border border-secondaryBlue text-secondaryBlue rounded p-1 w-72"
              >
                <div className="ml-2">
                  {
                    format(printDayInBrowser, 'eee dd.MM.', {locale: deLocale})
                    + ' - '
                    + format(dateXDaysFromDate(printDayInBrowser,1), 'dd.MM.yyyy', {locale: deLocale})
                  }
                  {
                    (selectedNightReservation?.status === 'new' && compareDateToArray(day, deltaDaysCancelDays) === false)
                      ? <div className="text-xs text-gray-500 mt-0">
                        <span className="text-white bg-calendarUnconfirmed rounded-lg px-2">Buchung bei Hotel angefragt</span></div>
                      : ''
                  }
                  {
                    /* Assigned Hotel (district) */
                    (selectedNightReservation?.status === 'confirmed_hotel'
                      && selectedNightReservation?.assignHotel
                      && !selectedNightReservation?.rebookHotel
                      && compareDateToArray(day, deltaDaysCancelDays) === false
                    )
                      ? <div className="text-xs text-gray-500 mt-0">
                          <span className="text-white bg-primary rounded-lg px-2">Buchung bestätigt</span>
                          <div className="mx-2">
                            <div className="font-bold mt-1">{selectedNightReservation.assignHotel.name}</div>
                            <div>
                              <a className="underline hover:text-secondaryBlue" href={`https://www.google.com/maps/dir//${selectedNightReservation.assignHotel.addressStreet},+${selectedNightReservation.assignHotel.addressZip}+${selectedNightReservation.assignHotel.addressCity}/`}
                                 target="_blank" rel="noreferrer">{selectedNightReservation.assignHotel.addressStreet}, {selectedNightReservation.assignHotel.addressZip} {selectedNightReservation.assignHotel.addressCity}</a>
                            </div>
                            <div className="">Check-In ab {selectedNightReservation.assignHotel.checkInTime || 'n/a'}, Check-Out bis {selectedNightReservation.assignHotel.checkOutTime || 'n/a'}</div>
                          </div>
                        </div>
                      : ''
                  }
                  {
                    /* Rebook Hotel (with optional message to customer) */
                    (selectedNightReservation?.rebookHotel && compareDateToArray(day, deltaDaysCancelDays) === false)
                      ? <div className="text-xs text-gray-500 mt-0">
                          <span className="text-white bg-orange-400 rounded-lg px-2">Hotel Änderung</span>
                          <div className="mx-2">
                            <div className="font-bold mt-1">{selectedNightReservation.rebookHotel.name}</div>
                            <div>
                              <a className="underline hover:text-secondaryBlue" href={`https://www.google.com/maps/dir//${selectedNightReservation.rebookHotel.addressStreet},+${selectedNightReservation.rebookHotel.addressZip}+${selectedNightReservation.rebookHotel.addressCity}/`}
                                 target="_blank" rel="noreferrer">{selectedNightReservation.rebookHotel.addressStreet}, {selectedNightReservation.rebookHotel.addressZip} {selectedNightReservation.rebookHotel.addressCity}</a>
                            </div>
                            <div className="">Check-In ab {selectedNightReservation.rebookHotel.checkInTime || 'n/a'}, Check-Out bis {selectedNightReservation.rebookHotel.checkOutTime || 'n/a'}</div>
                          </div>
                          <div className="text-orange-600 ml-2 my-1">{selectedNightReservation.message}</div>
                        </div>

                      : ''
                  }
                  {
                    /* Message to customer (without Rebook Hotel) */
                    (selectedNightReservation?.message && !selectedNightReservation?.rebookHotel && compareDateToArray(day, deltaDaysCancelDays) === false)
                      ? <div className="text-xs text-gray-500 mt-0">
                          <span className="text-white bg-orange-400 rounded-lg px-2">Hinweis</span>
                          <div className="text-orange-600 ml-2 my-1">{selectedNightReservation.message}</div>
                        </div>
                      : ''
                  }
                  {
                    (selectedNightReservation?.status === 'confirmed_hotel'
                      && selectedNightReservation?.selfCheckInUrl
                      && compareDateToArray(day, deltaDaysCancelDays) === false)
                      ? <div className="text-xs mt-1">
                          <div className="flex text-gray-500">
                            <CheckIn />
                            <div className="ml-1"><a href={selectedNightReservation?.selfCheckInUrl} target="_blank" rel="noreferrer" className="underline hover:text-primaryHover">Online Check-In starten</a></div>
                          </div>
                        </div>
                      : ''
                  }
                  {/*
                    (selectedNightReservation.status === 'confirmed_hotel')
                      ? <div className="text-xs text-gray-500 mt-0">
                        <span className="text-white bg-primary rounded-lg px-2">Buchung von Hotel bestätigt</span></div>
                      : ''
                  */}
                  {
                    compareDateToArray(day, deltaDaysBookDays)
                      ? <div className="text-xs text-gray-500 mt-0">
                        <span className="text-white bg-calendarBook rounded-lg px-2">Neue Buchung</span></div>
                      : ''
                  }
                  {
                    compareDateToArray(day, deltaDaysCancelDays)
                      ? <div className="text-xs text-gray-500 mt-0">
                        <span className="text-white bg-calendarCancel rounded-lg px-2">Zu stornierende Buchung</span></div>
                      : ''
                  }
                  {
                    /* surcharges for new nights - lookup price attached to specialDates */
                    (selectedNightReservation?.surchargePrice === undefined) && getSurchargeByRoomAndDay(room, day, specialDates)?.surcharge > 0
                      ? <div className="text-xs text-gray-500 ml-3">
                        <div className="flex items-center">Aufpreis wegen Messezeit &nbsp;
                          <Tippy theme="light" content={
                            <div className="w-72 text-gray-600 p-3">
                              Dies ist eine der wenigen Nächte mit Aufpreis in diesem Hotel.
                              Die komplette Liste findest du transparent auf der Hotelseite.
                              Nur mit wenigen einzelnen Aufpreisen können wir den Abo-Preis für das restliche Jahr niedrig halten.
                              <br/>
                              <div className="text-green-700 mt-2">Der Aufpreis wird erstattet, falls du fristgerecht stornierst.</div>
                            </div>
                          }>
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                              <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"/>
                            </svg>
                          </Tippy>
                        </div>
                        <div className="font-bold">+{(getSurchargeByRoomAndDay(room, day, specialDates)?.surcharge || 0).toFixed(2).replace('.',',')} €
                          <span className="ml-2">{getSurchargeByRoomAndDay(room, day, specialDates)?.description || ''}</span></div>
                      </div>
                      : ''
                  }
                  {
                    /* surcharges for existing nights - lookup price attached to reservation */
                    selectedNightReservation?.surchargePrice > 0
                      ? <div className="text-xs text-gray-500 ml-3">
                        <div className="flex items-center">Aufpreis wegen Messezeit &nbsp;
                          <Tippy theme="light" content={
                            <div className="w-72 text-gray-600 p-3">
                              Dies ist eine der wenigen Nächte mit Aufpreis in diesem Hotel.
                              Die komplette Liste findest du transparent auf der Hotelseite.
                              Nur mit wenigen einzelnen Aufpreisen können wir den Abo-Preis für das restliche Jahr niedrig halten.
                              <br/>
                              <div className="text-green-700 mt-2">Der Aufpreis wird erstattet, falls du fristgerecht stornierst.</div>
                            </div>
                          }>
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                              <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"/>
                            </svg>
                          </Tippy>
                        </div>
                        <div className="font-bold">+{(selectedNightReservation.surchargePrice || 0).toFixed(2).replace('.',',')} €
                          <span className="ml-2">{getSurchargeByRoomAndDay(room, day, specialDates)?.description || ''}</span></div>
                      </div>
                      : ''
                  }
                </div>
                <div>
                  {
                    isAfter(removeTimeFromDate(day), yesterday()) && !readOnlyMode
                      ? <Tippy theme="light" content={<div>{removeButtonDescription}</div>}>
                          <button onClick={() => unselectDay(day)} className="bg-secondaryLightGray hover:bg-secondaryGray text-white rounded px-2 py-1">X</button>
                        </Tippy>
                      :''
                  }
                </div>
              </div>
            </div>
          )
        })
        : ''
      }
    </div>
  )
}