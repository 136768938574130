import * as React from 'react';

const MapsPinDistrict = ({size = 20, fill='#25A9D5', stroke='none'}) => {
  return (
    <svg height={size} viewBox="0 0 2885 3486">
      <g fillRule="evenodd">
        <path style={{fill:fill, stroke: stroke, strokeWidth: 100}}
              d="M1438.667 3263.04c-427.309-296.445-884.644-751.711-1123.404-1224.6-44.253-80.173-121.502-306.111-128.146-331.6l2.756.023c-19.262-86.947-29.412-177.229-29.412-269.824 0-696.857 574.833-1262.619 1282.869-1262.619 708.031 0 1282.871 565.762 1282.871 1262.619 0 100.148-11.877 197.589-34.311 291.031l2.207.02c-7.885 29.44-71.645 210.973-100.664 268.58-228.841 481.638-704.741 966.422-1154.766 1266.369z"/>
        <g fill="#fff">
          <path
            d="M2424.11 1401.45c0-16.598-13.475-30.073-30.073-30.073h-468.38c-16.598 0-30.073 13.475-30.073 30.073v300.314c0 16.597 13.475 30.073 30.073 30.073h468.38c16.598 0 30.073-13.476 30.073-30.073V1401.45z"/>
          <path
            d="M2199.7 1174.05c7.932-6.877 8.812-18.919 1.963-26.876l-24.817-28.831c-6.849-7.957-18.849-8.833-26.781-1.956l-324.319 281.169c-7.932 6.877-8.812 18.919-1.963 26.875l24.817 28.832c6.849 7.956 18.849 8.833 26.781 1.956L2199.7 1174.05z"/>
          <path
            d="M2184.26 1122.13a18.95 18.95 0 0 0-26.814 1.38l-25.429 28.29c-7.017 7.806-6.396 19.864 1.385 26.909l318.229 288.103a18.95 18.95 0 0 0 26.814-1.381l25.429-28.289c7.017-7.807 6.397-19.865-1.385-26.91L2184.26 1122.13z"/>
          <path d="M2158.94 1173.56l250.332 214.771h-500.663l250.331-214.771z"/>
        </g>
        <g style={{fill:fill}}>
          <use xlinkHref="#B"/>
          <path
            d="M2301.58 1660.23c2.583-8.524 3.887-17.249 3.887-26.002 0-63.042-66.344-114.224-148.062-114.224s-148.061 51.182-148.061 114.224c0 8.753 1.304 17.478 3.887 26.002h288.349z"/>
        </g>
        <g fill="#fff">
          <path
            d="M1684.76 901.9c0-16.597-13.475-30.073-30.073-30.073h-468.38c-16.597 0-30.073 13.476-30.073 30.073v300.314c0 16.598 13.476 30.073 30.073 30.073h468.38c16.598 0 30.073-13.475 30.073-30.073V901.9z"/>
          <path
            d="M1460.35 674.494c7.932-6.877 8.812-18.919 1.963-26.875l-24.817-28.832c-6.849-7.956-18.848-8.833-26.781-1.956L1086.396 898c-7.932 6.877-8.811 18.919-1.963 26.876l24.817 28.831c6.849 7.957 18.849 8.833 26.781 1.956l324.319-281.169z"/>
          <path
            d="M1444.91 622.573a18.95 18.95 0 0 0-26.814 1.38l-25.429 28.29c-7.017 7.807-6.396 19.865 1.386 26.91l318.228 288.102a18.95 18.95 0 0 0 26.814-1.38l25.429-28.29c7.017-7.807 6.397-19.865-1.385-26.91L1444.91 622.573z"/>
          <path d="M1419.6 674.01l250.331 214.771h-500.663L1419.6 674.01z"/>
        </g>
        <g style={{fill:fill}}>
          <use xlinkHref="#B" x="-739.34" y="-499.55"/>
          <path
            d="M1562.24 1160.67c2.583-8.524 3.887-17.248 3.887-26.002 0-63.042-66.344-114.224-148.062-114.224s-148.061 51.182-148.061 114.224c0 8.754 1.304 17.478 3.887 26.002h288.349z"/>
        </g>
        <g fill="#fff">
          <path
            d="M981.68 1391.79c0-16.598-13.475-30.073-30.073-30.073h-468.38c-16.597 0-30.073 13.475-30.073 30.073v300.313c0 16.598 13.476 30.073 30.073 30.073h468.38c16.598 0 30.073-13.475 30.073-30.073V1391.79z"/>
          <path
            d="M757.27 1164.39c7.932-6.877 8.812-18.919 1.963-26.875l-24.817-28.832c-6.848-7.956-18.848-8.833-26.78-1.956l-324.32 281.169c-7.932 6.877-8.811 18.92-1.963 26.876l24.817 28.831c6.849 7.957 18.849 8.833 26.781 1.957l324.319-281.17z"/>
          <path
            d="M741.84 1112.47a18.95 18.95 0 0 0-26.814 1.38l-25.429 28.29c-7.017 7.807-6.396 19.865 1.386 26.91l318.228 288.102a18.95 18.95 0 0 0 26.814-1.38l25.429-28.29c7.018-7.807 6.397-19.865-1.385-26.91L741.84 1112.47z"/>
          <path d="M716.52 1163.9l250.331 214.771H466.188L716.52 1163.9z"/>
        </g>
        <g style={{fill:fill}}>
          <use xlinkHref="#B" x="-1442.42" y="-9.65"/>
          <path
            d="M859.16 1650.57c2.583-8.524 3.887-17.248 3.887-26.002 0-63.042-66.344-114.223-148.062-114.223s-148.061 51.181-148.061 114.223c0 8.754 1.304 17.478 3.887 26.002H859.16z"/>
        </g>
        <g fill="#fff">
          <path
            d="M1860 1807.53c0-31.887-24.024-57.774-53.616-57.774h-775.769c-29.591 0-53.615 25.887-53.615 57.774v576.946c0 31.886 24.024 57.774 53.615 57.774h775.769c29.592 0 53.616-25.888 53.616-57.774V1807.53z"/>
          <path
            d="M1484.27 1431.56c14.294-12.473 15.889-34.304 3.559-48.72l-44.678-52.243a33.98 33.98 0 0 0-48.238-3.522l-583.815 509.433c-14.294 12.473-15.889 34.304-3.56 48.721l44.678 52.242c12.329 14.417 33.944 15.995 48.238 3.523l583.816-509.434z"/>
          <path
            d="M1456.53 1337.48a33.97 33.97 0 0 0-48.291 2.484l-45.779 51.261c-12.633 14.146-11.508 36.002 2.512 48.777l572.865 522.007a33.97 33.97 0 0 0 48.291-2.484l45.779-51.261c12.633-14.146 11.508-36.002-2.511-48.777L1456.53 1337.48z"/>
        </g>
        <path style={{fill:fill}}
              d="M1499.87 1264.98a20.1 20.1 0 0 0-28.573 1.47l-27.087 30.33c-7.474 8.37-6.808 21.302 1.487 28.86l593.609 540.91a20.1 20.1 0 0 0 28.573-1.47l27.086-30.33c7.475-8.37 6.809-21.302-1.486-28.86l-593.609-540.91zm-85.33 52.76a20.1 20.1 0 0 0 2.112-28.533l-26.706-30.665c-7.37-8.462-20.284-9.417-28.82-2.132l-610.861 521.348a20.1 20.1 0 0 0-2.113 28.533l26.707 30.665c7.37 8.462 20.284 9.417 28.82 2.132l610.861-521.348z"/>
        <path fill="#fff" d="M1410.93 1430.66l450.667 389.166H960.263l450.667-389.166z"/>
        <path
          d="M1533.631 1866.29c0 70.281-56.645 127.256-126.521 127.256s-126.521-56.974-126.521-127.256 56.645-127.256 126.521-127.256 126.521 56.974 126.521 127.256zm134.089 446.21c4.65-15.445 6.998-31.254 6.998-47.115 0-114.232-119.438-206.973-266.553-206.973s-266.553 92.741-266.553 206.973c0 15.861 2.348 31.67 6.999 47.115h519.109z"
          style={{fill:fill}}/>
      </g>
      <defs>
        <path id="B"
              d="M2227.099 1413.97c0 38.787-31.465 70.23-70.279 70.23s-70.279-31.443-70.279-70.23 31.465-70.23 70.279-70.23 70.279 31.443 70.279 70.23z"/>
      </defs>
    </svg>
  )
}

export default React.memo(MapsPinDistrict)