import React from "react";

export const DayPickerLegend = () => {
  return (
    <div className="flex items-center text-xs justify-center text-gray-400 md:hidden">
      <div className="font-bold">Legende:</div>
      <div style={{border: "2px solid rgb(203, 203, 203)"}} className="rdp-day ml-3 mr-1 text-gray-900"
           tabIndex="-1"><span
        aria-hidden="true">TT</span><span className="rdp-vhidden">xx</span></div>
      = Messezeit
      <div style={{border: "2px solid rgb(203, 203, 203)"}} disabled={true}
           className="rdp-button_reset rdp-button rdp-day rdp-day_disabled ml-3 mr-1 text-gray-900" tabIndex="-1"><span
        aria-hidden="true">TT</span><span className="rdp-vhidden">xx</span></div> = nicht verfügbar
    </div>
  )
}