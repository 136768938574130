import logo from '../assets/images/logo_final.png';
import React from 'react';
import HeaderLogin from "./HeaderLogin";

const HeaderLight = () => {

  return (
    <div className="bg-white shadow h-24 sticky top-0 z-50">
      <div className="px-3 py-2 lg:p-4 mx-auto flex">
        <div className="w-3/12">
          <a href="https://www.myflexhome.de/"><img src={logo} alt="MyFlexHome Logo" className="header__logo cursor-pointer" /></a>
        </div>
        <div className="w-6/12">
        </div>
        <div className="w-3/12 flex justify-end">
          <HeaderLogin />
        </div>
      </div>
    </div>
  )
}

export default HeaderLight
