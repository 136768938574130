import React from 'react';

const District = ({fill='#FFFFFF'}) => (
  <svg width="20" height="20" viewBox="0 0 2253 1989">
    <path id="P!" fill={fill} fillRule="evenodd" stroke="none" d="M 1706.75 1190.837891 C 1706.75 1190.837891 1589.583984 1085.066406 1589.583984 1085.066406 L 1589.583984 896.703125 L 1569.380859 914.21875 C 1561.44873 921.095703 1549.448486 920.219727 1542.599609 912.263672 L 1517.783203 883.431641 C 1510.934326 875.475586 1511.813965 863.433594 1519.746094 856.556641 L 1844.064453 575.386719 C 1851.834717 568.650146 1863.508667 569.355835 1870.419922 576.867188 C 1873.308716 577.637085 1876.002197 579.084839 1878.259766 581.128906 L 2196.488281 869.232422 C 2204.270264 876.277344 2204.89209 888.335449 2197.875 896.142578 L 2172.445313 924.431641 C 2169.077637 928.178711 2164.355713 930.43042 2159.324219 930.689453 C 2154.292725 930.94873 2149.365479 929.194092 2145.630859 925.8125 L 2118.109375 900.896484 L 2118.109375 1160.763672 C 2118.109375 1177.360596 2104.63501 1190.837891 2088.037109 1190.837891 L 1706.75 1190.837891 Z M 1707.230469 1119.230469 L 1995.580078 1119.230469 C 1998.163086 1110.706543 1999.466797 1101.981445 1999.466797 1093.228516 C 1999.466797 1030.186523 1933.122192 979.003906 1851.404297 979.003906 C 1769.686401 979.003906 1703.34375 1030.186523 1703.34375 1093.228516 C 1703.34375 1101.981445 1704.647461 1110.706543 1707.230469 1119.230469 Z M 1850.820313 943.199219 C 1889.634277 943.199219 1921.099609 911.757568 1921.099609 872.970703 C 1921.099609 834.183838 1889.634277 802.740234 1850.820313 802.740234 C 1812.006104 802.740234 1780.541016 834.183838 1780.541016 872.970703 C 1780.541016 911.757568 1812.006104 943.199219 1850.820313 943.199219 Z"/>
    <path id="P2" fill={fill} fillRule="evenodd" stroke="none" d="M 880.306641 691.287109 C 863.709595 691.287109 850.234375 677.812988 850.234375 661.214844 L 850.234375 397.148438 L 830.03125 414.664063 C 822.099243 421.541016 810.098999 420.664063 803.25 412.707031 L 778.433594 383.875 C 771.585571 375.917969 772.464478 363.876953 780.396484 357 L 1104.714844 75.832031 C 1112.485962 69.095459 1124.158936 69.800171 1131.070313 77.310547 C 1133.959106 78.080566 1136.652588 79.528198 1138.910156 81.572266 L 1457.138672 369.675781 C 1464.920654 376.720703 1465.540527 388.778809 1458.523438 396.585938 L 1433.095703 424.875 C 1429.728027 428.62207 1425.006104 430.873779 1419.974609 431.132813 C 1414.943115 431.391846 1410.015869 429.635742 1406.28125 426.253906 L 1378.759766 401.337891 L 1378.759766 661.214844 C 1378.759766 677.812988 1365.2854 691.287109 1348.6875 691.287109 L 880.306641 691.287109 Z M 967.890625 619.669922 L 1256.240234 619.669922 C 1258.823242 611.145996 1260.126953 602.421875 1260.126953 593.667969 C 1260.126953 530.626099 1193.782349 479.443359 1112.064453 479.443359 C 1030.34668 479.443359 964.003906 530.626099 964.003906 593.667969 C 964.003906 602.421875 965.307617 611.145996 967.890625 619.669922 Z M 1111.480469 443.650391 C 1150.294556 443.650391 1181.759766 412.207031 1181.759766 373.419922 C 1181.759766 334.633057 1150.294556 303.189453 1111.480469 303.189453 C 1072.666504 303.189453 1041.201172 334.633057 1041.201172 373.419922 C 1041.201172 412.207031 1072.666504 443.650391 1111.480469 443.650391 Z"/>
    <path id="P3" fill={fill} fillRule="evenodd" stroke="none" d="M 177.226563 1181.175781 C 160.629639 1181.175781 147.154297 1167.701538 147.154297 1151.103516 L 147.154297 887.044922 L 126.951172 904.560547 C 119.019196 911.436523 107.01889 910.560547 100.169922 902.603516 L 75.353516 873.771484 C 68.505524 865.81543 69.38443 853.773438 77.316406 846.896484 L 401.636719 565.726563 C 409.406158 558.990601 421.077576 559.696533 427.988281 567.205078 C 430.881409 567.974243 433.579376 569.423828 435.839844 571.470703 L 754.068359 859.572266 C 761.850342 866.617188 762.471191 878.675537 755.453125 886.482422 L 730.025391 914.771484 C 726.657715 918.518555 721.935791 920.770264 716.904297 921.029297 C 711.872803 921.28833 706.945435 919.53418 703.210938 916.152344 L 675.748047 891.289063 L 676 1040 C 676 1040 510.345703 1181.175781 510.345703 1181.175781 L 177.226563 1181.175781 Z M 264.810547 1109.570313 L 553.160156 1109.570313 C 555.743164 1101.046387 557.046875 1092.322388 557.046875 1083.568359 C 557.046875 1020.526306 490.702301 969.345703 408.984375 969.345703 C 327.266449 969.345703 260.923828 1020.526306 260.923828 1083.568359 C 260.923828 1092.322388 262.2276 1101.046387 264.810547 1109.570313 Z M 408.400391 933.550781 C 447.214325 933.550781 478.679688 902.107422 478.679688 863.320313 C 478.679688 824.533691 447.214325 793.089844 408.400391 793.089844 C 369.586334 793.089844 338.121094 824.533691 338.121094 863.320313 C 338.121094 902.107422 369.586334 933.550781 408.400391 933.550781 Z"/>
    <path id="P4" fill={fill} fillRule="evenodd" stroke="none" d="M 724.615234 1901.25 C 695.024658 1901.25 671 1875.362427 671 1843.476563 L 671 1333.199219 L 594.453125 1399.994141 C 580.15918 1412.466187 558.545898 1410.887695 546.216797 1396.470703 L 501.537109 1344.228516 C 489.20813 1329.811523 490.803589 1307.980713 505.097656 1295.507813 L 1088.912109 786.074219 C 1095.750977 780.088135 1104.703003 777.092285 1113.767578 777.753906 C 1122.450439 778.388184 1130.541748 782.330444 1136.390625 788.740234 C 1141.607788 790.136475 1146.467651 792.7677 1150.529297 796.480469 L 1723.396484 1318.486328 C 1737.415527 1331.26123 1738.539307 1353.117676 1725.90625 1367.263672 L 1680.128906 1418.525391 C 1674.08667 1425.312012 1665.57666 1429.394531 1656.501953 1429.861328 C 1647.427246 1430.328125 1638.542725 1427.140625 1631.835938 1421.009766 L 1554 1350.083984 L 1554 1843.476563 C 1554 1875.362427 1529.976563 1901.25 1500.384766 1901.25 L 724.615234 1901.25 Z M 842.611328 1771.5 L 1361.720703 1771.5 C 1366.37085 1756.054932 1368.71875 1740.24585 1368.71875 1724.384766 C 1368.71875 1610.152954 1249.278931 1517.412109 1102.164063 1517.412109 C 955.049194 1517.412109 835.611328 1610.152954 835.611328 1724.384766 C 835.611328 1740.24585 837.960327 1756.054932 842.611328 1771.5 Z M 1101.109375 1452.546875 C 1170.984863 1452.546875 1227.630859 1395.570557 1227.630859 1325.289063 C 1227.630859 1255.007568 1170.984863 1198.033203 1101.109375 1198.033203 C 1031.233887 1198.033203 974.589844 1255.007568 974.589844 1325.289063 C 974.589844 1395.570557 1031.233887 1452.546875 1101.109375 1452.546875 Z"/>
  </svg>
)

export default District





