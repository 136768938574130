import React from 'react';

export const FormCheckbox = (options) => {
  const {fieldId, fieldName, handlers, errorText='Fehler'} = options;
  const {checkboxChangeHandler, input, errors} = handlers;

  const styleOK = 'bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-primary'
  const styleNOK = 'bg-white border-2 rounded border-red-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-primary'

  /*
   * Input checkbox with SVG requires additional CSS,
   * https://tailwindcomponents.com/component/checkbox-1
   */
  return(
    <div className="">
      <label className="flex justify-start items-start mt-2">
        <div className={errors.has(fieldId) ? styleNOK : styleOK}>
          <input
            type="checkbox"
            id={fieldId}
            name={fieldId}
            defaultChecked={input[fieldId]}
            onChange={(e) => checkboxChangeHandler(e)}
            className="opacity-0 absolute"
          />
          <svg className="fill-current hidden w-4 h-4 text-green-500 pointer-events-none" viewBox="0 0 20 20">
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
          </svg>
        </div>
        <div className="select-none text-gray-700 text-sm">
          {fieldName}
        </div>
      </label>
      <div className="text-red-700 text-xs pl-8">{errors.has(fieldId) ? errorText : ''}</div>

    </div>
  )
}

export default FormCheckbox
