import React from "react";
import {Button, useDayRender} from "react-day-picker";
import Tippy from "@tippyjs/react";
import {
  compareDates,
  compareDateToArray,
  dateArrayFromRange,
  dateXDaysFromDate,
  formatDateWeekday,
  getDateAsUTC,
  mergeMultipleDateRangesToArray,
  removeTimeFromDate,
  removeTimezoneOffset,
  today,
  tomorrow,
} from "../functions/dateHelper";
import {isAfter, isBefore, isToday} from "date-fns";
import {useSelector} from "react-redux";


const DayPickerCustomDay = (props) => {

  /*
   * Redux State
   */
  const selectedNightsOffer = useSelector(state => state.offer.selectedNights) /* nights selection in journey of a new customer */
  const subscription = useSelector(state => state.customer.selectedSubscription)
  const bookingReservation = useSelector(state => state.customer.bookingReservation)
  const bookingReservationBySelectedEmployee = useSelector(state => state.customer.bookingReservationBySelectedEmployee)
  const bookingReservationList = subscription?.subscriptionData?.shareable
    ? bookingReservationBySelectedEmployee
    : bookingReservation
  const selectedNightsCustomer = bookingReservationList.map(r => getDateAsUTC(new Date(r.arrivalDate))) /* nights selection of existing customer in dashboard */
  const bookingReservationDelta = useSelector(state => state.customer.bookingReservationDelta) /* new nights or cancellations of existing customer in dashboard */
  const bookingReservationDeltaDayArray = bookingReservationDelta.map(br => br.arrivalDate)
  const selectedNights = [...selectedNightsOffer, ...selectedNightsCustomer, ...bookingReservationDeltaDayArray]
  const deltaDaysBookDays = bookingReservationDelta.filter(d => d.request === 'book').map(d => d.arrivalDate)
  const deltaDaysCancelDays = bookingReservationDelta.filter(d => d.request === 'cancel').map(d => d.arrivalDate)
  const unbookableCycles = subscription?.subscriptionEvaluation?.filter(cycle => cycle?.segment?.bookingAllowed === false) || []
  const unbookableCycleDays = mergeMultipleDateRangesToArray(unbookableCycles)

  const subscriptionStartDateCustomer = removeTimeFromDate(useSelector(state => state.customer.selectedSubscription?.subscriptionData?.startDate))
  const subscriptionEndDateString = useSelector(state => state.customer.selectedSubscription?.subscriptionData?.endDate) || null
  const subscriptionEndDate = subscriptionEndDateString ? removeTimeFromDate(subscriptionEndDateString) : null
  const subscriptionStartDateOffer = useSelector(state => state.offer.subscriptionStartDate)
  const subscriptionStartDate = subscriptionStartDateCustomer || subscriptionStartDateOffer
  const hotelDetail = useSelector(state => state.offer.hotelDetail)

  const subscriptionRoomUuidOffer = useSelector(state => state.customer.selectedSubscription?.subscriptionData?.BookingRoom?.uuid || '')
  const subscriptionRoomUuidCustomer = useSelector(state => state.customer.selectedSubscription?.subscriptionData?.BookingOffer?.BookingRoom?.uuid)
  const subscriptionRoomUuid = subscriptionRoomUuidOffer || subscriptionRoomUuidCustomer

  /*
   * Date Variables
   */
  //const noGuaranteeDays = dateArrayFromDate(6, tomorrow)
  const specialDateRanges = hotelDetail?.BookingSpecialDates || []
  const toDate = dateXDaysFromDate(tomorrow(), 90)

  /*
   * Tooltip Messages
   */
  const getTooltipText = (date) => {
    let tooltipText = ''
    const selectedNightReservation = bookingReservationList.filter(reservation =>
      compareDates(date, reservation?.arrivalDate || null)
    )?.[0] || undefined

    specialDateRanges.forEach(rangeObj => {
      const rangeArr = dateArrayFromRange(rangeObj.dateFrom, rangeObj.dateTo)
      const surchargeNew = rangeObj.BookingSpecialDateSurcharges.filter(surcharge => surcharge.BookingRoom.uuid === subscriptionRoomUuid)[0]?.surcharge || 0
      const blackOut = rangeObj.blackOut
      const fullyBooked = rangeObj.fullyBooked

      /* surcharges and blackouts for new nights - lookup price attached to specialDates */
      if(compareDateToArray(date, rangeArr) && (blackOut || surchargeNew) && (!selectedNightReservation)) {
        const text = <div>
          <div className="font-bold">{rangeObj.description}</div>
          { blackOut
            ? <div>Diese Nacht ist nicht verfügbar.</div>
            : surchargeNew
              ? <>
                  <div className="text-secondaryBlue">
                    <span>{surchargeNew.toFixed(2).replace('.',',')} € Aufpreis</span>
                  </div>
                  Dies ist eine der wenigen Nächte mit Aufpreis in diesem Hotel.
                  Die komplette Liste findest du transparent auf der Hotelseite.
                  Nur mit wenigen einzelnen Aufpreisen können wir den Abo-Preis für das restliche Jahr niedrig halten.
                </>
              : ''
          }
          </div>
        tooltipText = <>{tooltipText}{text}<br /></>
      }

      if(compareDateToArray(date, rangeArr) && (fullyBooked) && (!selectedNightReservation)) {
        const text = <div>
          <div className="font-bold">Ausgebucht</div>
          <div>Für eine Alternative schreibe uns an hello@myflexhome.de.</div>
        </div>
        tooltipText = <>{tooltipText}{text}<br /></>
      }
    })

    if(selectedNightReservation?.surchargePrice > 0) {
      /* surcharges for existing nights - lookup price attached to reservation */
      tooltipText = <>
          {tooltipText}
          <div>
            <div className="font-bold">{selectedNightReservation.surchargeDescription}</div>
            <div className="text-secondaryBlue">
              <span>{selectedNightReservation.surchargePrice.toFixed(2).replace('.',',')} € Aufpreis</span>
            </div>
            Dies ist eine der wenigen Nächte mit Aufpreis in diesem Hotel.
            Die komplette Liste findest du transparent auf der Hotelseite.
            Nur mit wenigen einzelnen Aufpreisen können wir den Abo-Preis für das restliche Jahr niedrig halten.
          </div>
          <br />
        </>
    }

    if(compareDateToArray(date, selectedNights)) {
      const text = <div>
        <div className="font-bold">Ausgewählte Nacht</div>
        {formatDateWeekday(removeTimezoneOffset(date))} auf {formatDateWeekday(dateXDaysFromDate(removeTimezoneOffset(date),1))}
      </div>
      tooltipText = <>{tooltipText}{text}<br /></>
    }
    if(compareDateToArray(date, deltaDaysBookDays)) {
      tooltipText = <>{tooltipText} <span className="text-xs text-white bg-calendarBook rounded-lg px-2">Neue Buchung</span><br /></>
    }
    if(compareDateToArray(date, deltaDaysCancelDays)) {
      tooltipText = <>{tooltipText} <span className="text-xs text-white bg-calendarCancel rounded-lg px-2">Zu stornierende Buchung</span><br /></>
    }
    if(compareDateToArray(date, unbookableCycleDays)) {
      tooltipText = <div>Diese Nacht liegt in deiner Pause und ist nicht buchbar.<br /><br /></div>
    }
    if(bookingReservationList.filter(reservation =>
      compareDates(date, reservation?.arrivalDate || null)
    )?.[0]?.status === 'new' && compareDateToArray(date, deltaDaysCancelDays) === false) {
      tooltipText = <>{tooltipText} <span className="text-xs text-white bg-calendarUnconfirmed rounded-lg px-2">Buchung bei Hotel angefragt</span><br /></>
    }
    if(bookingReservationList.filter(reservation =>
      compareDates(date, reservation?.arrivalDate || null)
    )?.[0]?.status === 'confirmed_hotel' && compareDateToArray(date, deltaDaysCancelDays) === false) {
      tooltipText = <>
        {tooltipText} <span className="text-xs text-white bg-primary rounded-lg px-2">Buchung bestätigt</span><br />
        {
          selectedNightReservation?.assignHotel && !selectedNightReservation?.rebookHotel
          ? <div className="text-xs mx-2">
              <div className="font-bold mt-1">{selectedNightReservation.assignHotel.name}</div>
              <div>
                {selectedNightReservation.assignHotel.addressStreet}, {selectedNightReservation.assignHotel.addressZip} {selectedNightReservation.assignHotel.addressCity}
              </div>
              <div className="">Check-In ab {selectedNightReservation.assignHotel.checkInTime || 'n/a'}, Check-Out bis {selectedNightReservation.assignHotel.checkOutTime || 'n/a'}</div>
            </div>
          : ''
        }
      </>
    }
    if(selectedNightReservation?.rebookHotel && compareDateToArray(date, deltaDaysCancelDays) === false) {
      tooltipText = <>
        {tooltipText}
        <span className="text-xs text-white bg-orange-400 rounded-lg px-2">Hotel Änderung</span><br />
        <div className="text-xs mx-2">
          <div className="font-bold mt-1">{selectedNightReservation.rebookHotel.name}</div>
          <div>
            {selectedNightReservation.rebookHotel.addressStreet}, {selectedNightReservation.rebookHotel.addressZip} {selectedNightReservation.rebookHotel.addressCity}
          </div>
          <div className="">Check-In ab {selectedNightReservation.rebookHotel.checkInTime || 'n/a'}, Check-Out bis {selectedNightReservation.rebookHotel.checkOutTime || 'n/a'}</div>
        </div>
        <div className="text-xs text-orange-600 ml-2 my-1">{selectedNightReservation.message}</div>
      </>
    }
    if(selectedNightReservation?.message && !selectedNightReservation?.rebookHotel && compareDateToArray(date, deltaDaysCancelDays) === false) {
      tooltipText = <>
        {tooltipText}
        <span className="text-xs text-white bg-orange-400 rounded-lg px-2">Hinweis</span><br />
        <div className="text-xs text-orange-600 ml-2 my-1">{selectedNightReservation.message}</div>
      </>
    }
    if(isBefore(date, subscriptionStartDate)) {
      tooltipText = <div>Diese Nacht liegt vor Beginn deines Abos.<br /><br /></div>
    }
    if(isBefore(date, today())) {
      tooltipText = <div>Diese Nacht liegt in der Vergangenheit und ist nicht mehr buchbar.<br /><br /></div>
    }
    if(isToday(date)) {
      tooltipText = <div>Buchung frühstens ab Folgetag möglich.<br /><br /></div>
    }
    if(isAfter(date, toDate)) {
      tooltipText = <div>Diese Nacht liegt mehr als 90 Tage in der Zukunft und ist heute noch nicht buchbar.<br /><br /></div>
    }
    if(subscriptionEndDate && isAfter(date, subscriptionEndDate)) {
      tooltipText = <div>Diese Nacht liegt nach Ende deines Abozeitraums.<br /><br /></div>
    }
    return tooltipText
  }


  /*
   * DayPicker - day handling
   */
  const buttonRef = React.useRef(null)

  const dayRender = useDayRender(
    props.date,
    props.displayMonth,
    buttonRef
  )

  if (dayRender.isHidden) {
    return <></>
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />
  }

  const handleClick = (e) => {
    dayRender.buttonProps?.onClick?.(e)
  }

  const tooltipText = getTooltipText(props.date)

  if(tooltipText && window.innerWidth > 640) {
    return <Tippy theme="light" content={
      <div>{tooltipText}</div>
    }><div><Button {...dayRender.buttonProps} ref={buttonRef} onClick={handleClick} /></div></Tippy>
  } else {
    return <Button {...dayRender.buttonProps} ref={buttonRef} onClick={handleClick} />
  }

}

export default DayPickerCustomDay