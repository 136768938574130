import React from 'react';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {Box, Checkbox, FormControlLabel, TextField} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import * as actions from "../store/actions";

const AccountOnly = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  /* react hook form */
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
      privacy: false,
      mailNotification: false,
    }
  })
  const onSubmit = data => {
    const payload = {
      ...data,
      registrationSource: 'standaloneAccount',
      product: 'single',
    }
    dispatch(actions.postAccount(payload,'/dashboard/'))
  }

  return (
    <div className="container max-w-screen-lg mx-auto mb-10">
        <div className="mx-3 md:w-10/12 md:mx-auto">

          <div className="text-center text-3xl font-light mt-10">Werde Teil der MyFlexHome Community!</div>
          <div className="text-center text-md font-light mt-2">Benutzerkonto anlegen</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto p-4 rounded-lg sm:w-112">
              <div>

                <Box
                  sx={{
                    textAlign: 'center',
                    '& .MuiTextField-root': {m:1, p:0, width:'90%'},
                  }}
                >
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine E-Mail-Adresse an',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Bitte gib eine gültige E-Mail-Adresse an"
                      }
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="E-Mail"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'Bitte wähle ein Passwort',
                      minLength: {
                        value: 8,
                        message: 'Bitte wähle ein sicheres Passwort >= 8 Zeichen',
                      },
                      maxLength: 99,
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Passwort"
                        type="password"
                        autoComplete="new-password"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <div className="text-left mx-6">
                    <Controller
                      name="privacy"
                      control={control}
                      rules={{
                        required: 'Bitte akzeptiere die AGB und Datenschutzerklräung',
                      }}
                      render={({ field,fieldState}) =>  (
                        <FormControlLabel
                          control={<Checkbox
                            {...field}
                            checked={field.value}
                            sx={fieldState.error ? {color: 'rgb(185 28 28)'} : {}}
                          />}
                          label={
                            <div className={fieldState.error ? 'font-light text-sm my-3 text-red-700' : 'font-light text-sm my-3'}>Hiermit akzeptiere ich
                              die <a href="https://www.myflexhome.de/agb/" target="_blank" rel="noreferrer" className="underline text-secondaryBlue">AGB</a> und
                              stimme der Verarbeitung meiner Daten entsprechend
                              der <a href="https://www.myflexhome.de/datenschutz/" target="_blank" rel="noreferrer" className="underline text-secondaryBlue">Datenschutzerklärung</a> zu.
                            </div>
                          }
                        />

                      )}
                    />
                    <Controller
                      name="mailNotification"
                      control={control}
                      render={({ field,fieldState}) =>  (
                        <FormControlLabel
                          control={<Checkbox
                            {...field}
                            checked={field.value}
                          />}
                          label={
                            <div className="font-light text-sm my-3">Ich möchte über Neuigkeiten, Angebote
                              und Rabatte informiert werden.</div>
                          }
                        />
                      )}
                    />
                  </div>

                </Box>
              </div>

            </div>
            <div className="text-center mx-auto mt-2">
              <button
                type="submit"
                className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
              >
                Jetzt Registrieren
              </button>
            </div>
          </form>

          <div className="text-center mx-auto my-16 text-sm text-gray-500">
            Schon registriert? <button onClick={() => {history.push('/login/?target=/dashboard/'); window.scrollTo(0, 0)}} className="underline text-secondaryBlue">Direkt zum Login</button>
          </div>

        </div>
    </div>
  )
}

export default AccountOnly
