
export const reservationStatus = {
  NEW: 'new',
  CANCELED_REFUNDED: 'canceled_refunded',
  CANCELED_DEDUCTED: 'canceled_deducted',
  CONFIRMED_HOTEL: 'confirmed_hotel',
  CONFIRMED_GUARANTEE: 'confirmed_guarantee',
  DECLINED: 'declined',
}
export const reservationStatusLabel = {
  new: 'neu',
  canceled_refunded: 'storniert (erstattet)',
  canceled_deducted: 'storniert',
  confirmed_hotel: 'bestätigt',
  confirmed_guarantee: 'bestätigt',
  declined: 'abgelehnt',
}

export const subscriptionStatus = {
  ACTIVE: 'active',
  TERMINATED: 'terminated',
  PAUSED: 'paused',
  CANCELED: 'canceled',
}

export const subscriptionStatusLabel = {
  active: 'aktiv',
  terminated: 'beendet',
  paused: 'pausiert',
  cancelled: 'aktiv (gekündigt)',
  upcoming: 'aktiv (bevorstehend) ',
}

export const segmentTypeLabel = {
  subscription: 'Abo-Monat',
  breakMonth: 'Pause',
  breakCustom: 'Pause',
}