import {FormField} from './FormField';
import React, {useState} from 'react';
import axios from 'axios';
import LoadingSpinner from '../assets/svg/LoadingSpinner';
import Check from '../assets/svg/Check';
import FormCheckbox from './FormCheckbox';
import HotelIcon from '../assets/svg/hotel-icon.svg'
import {FormTextArea} from "./FormTextArea";

const NewsletterFormHotelRequest = (props) => {
  const {
    source,
    city,
  } = props

  const LoadingButton = () => {
    return(
      <button
        className="bg-primary text-white hover:bg-primaryHover p-2 w-56 rounded inline-flex cursor-wait"
        type="button"
      >
        <LoadingSpinner color="white" /> Senden
      </button>
    )
  }

  const SendButton = () => {
    return(
      <button
        className="bg-primary text-white p-2 w-56 rounded hover:bg-secondaryBlue"
        type="submit"
      >
        Senden
      </button>
    )
  }

  const ReadyButton = () => {
    return(
      <button
        className="bg-green-600 hover:bg-green-600 text-white p-2 w-56 rounded inline-flex cursor-default"
        type="button"
      >
        <Check color="white" /> <div className="ml-2">Gesendet</div>
      </button>
    )
  }

  const inputDefaults = {
    city: city,
    source: source,
    betaUser: false,
    hotelRequested: true,
    hotelRequestText: '',
    hotelRequestComment: '',
    name: '',
    email: '',
    updateOnNewHotelsInCity: false,
    newsletter: false,
  }

  // local state - form controller
  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set())
  const [forceUpdate, setForceUpdate] = useState(1)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const inputChangeHandler = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    if(errors.has(key)) validateInput(event.target)
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    //setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (key, value) => {
    let error
    switch (key) {
      case 'name':
        if (value?.length < 2) error = true; break;
      case 'email':
        if (typeof(value) === 'undefined' || value === null || !RegExp("^.+@.+(\\.).+$").test(value)) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      console.log(errors)
    } else {
      console.log('ok')
      postMessage(input)
    }
  }

  const postMessage = (payload) => {
    setLoading(true)
    axios.post('/api/newsletter',payload)
      .then ( response => {
        console.log('message sent')
        setSuccess(true)
        setLoading(false)
        return true
      })
      .catch(error => {
        console.log('API error')
        setSuccess(false)
        setLoading(false)
        return false
      })
  }

  let formButton = <SendButton />
  if(loading) formButton = <LoadingButton />
  if(success) formButton = <ReadyButton />

  return (
    <form id="contact_form" onSubmit={handleSubmit} autoComplete="on"
          className="rounded-lg mb-6 shadow-sm border-gray-200 border px-4 py-4" style={{background: '#fffbf2'}}>
      <div className="flex mb-2 items-start">
        <img src={HotelIcon} className="w-8 mr-3" alt="Hotel Icon"/>
        <h3 className="text-2xl font-light mb-2">Dein Wunschhotel{city ? ' in ' + city : ''} ist noch nicht
          dabei?</h3>
      </div>

      {success
        ? <div className="flex flex-col justify-center items-center my-5">
            <Check color="#14b431" width={50} height={50}/>
            <b>Gesendet</b>
            <p>Vielen Dank für deine Anfrage.</p>
          </div>
        : <div>
            <p className="mb-8">Kein Problem. Schlage uns ein Hotel vor - wir setzen uns direkt mit dem Hotel in
              Verbindung.
              Sobald ein Abo verfügbar ist, melden wir uns bei dir.</p>
            {FormField({
              fieldId: 'hotelRequestText',
              fieldName: 'Dein Hotel Vorschlag',
              required: false,
              handlers: handlers,
            })}
            {city
              ? ''
              : FormField({
                fieldId: 'city',
                fieldName: 'Stadt',
                required: true,
                handlers: handlers,
              })
            }
            {FormTextArea({
              fieldId: 'hotelRequestComment',
              fieldName: 'Kommentar',
              cols: 40,
              rows: 3,
              required: false,
              handlers: handlers,
            })}
            <div style={{maxWidth: 500}}>
              {FormField({
                fieldId: 'name',
                fieldName: 'Vorname',
                required: true,
                handlers: handlers,
                errorText: 'Bitte gib deinen Vornamen an.'
              })}
              {FormField({
                fieldId: 'email',
                fieldName: 'E-Mail',
                required: true,
                handlers: handlers,
                errorText: 'Bitte gib eine gültige E-Mail-Adresse an.'
              })}
            </div>

            <div className="md:ml-24">
              {FormCheckbox({
                fieldId: 'updateOnNewHotelsInCity',
                fieldName: `Zu allen neuen Hotels${city ? ' in ' + city : ' in der Stadt'} benachrichtigt werden`,
                handlers: handlers,
              })}
              {FormCheckbox({
                fieldId: 'newsletter',
                fieldName: 'Zum MyFlexHome Newsletter anmelden (z.B. aktuelle Angebote, Produktupdates)',
                handlers: handlers,
              })}
            </div>

            <div className="flex justify-center md:ml-24 md:justify-start">
              <div className="text-xs font-light py-6 text-center md:text-left" style={{maxWidth: 365}}>
                Die E-Mail Benachrichtigung kann jederzeit widerrufen werden.
                Mehr dazu findest du in unserer <a href="/datenschutz/"
                                                   className="underline hover:text-primaryHover">Datenschutzerklärung</a>.
              </div>
            </div>

            <div className="flex justify-center md:ml-24 md:justify-start">{formButton}</div>
        </div>
      }

    </form>
  )
}

export default NewsletterFormHotelRequest

