export const GET_ALL_HOTELS_START = 'GET_ALL_HOTELS_START'
export const GET_ALL_HOTELS_SUCCESS = 'GET_ALL_HOTELS_SUCCESS'
export const GET_ALL_HOTELS_FAIL = 'GET_ALL_HOTELS_FAIL'

export const GET_HOTEL_DETAIL_START = 'GET_HOTEL_DETAIL_START'
export const GET_HOTEL_DETAIL_SUCCESS = 'GET_HOTEL_DETAIL_SUCCESS'
export const GET_HOTEL_DETAIL_FAIL = 'GET_HOTEL_DETAIL_FAIL'

export const GET_CITIES_START = 'GET_CITIES_START'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL'

export const GET_NIGHTS_START = 'GET_NIGHTS_START'
export const GET_NIGHTS_SUCCESS = 'GET_NIGHTS_SUCCESS'
export const GET_NIGHTS_FAIL = 'GET_NIGHTS_FAIL'

export const UPDATE_SEARCH_CITY = 'UPDATE_SEARCH_CITY'
export const UPDATE_SEARCH_NIGHTS = 'UPDATE_SEARCH_NIGHTS'
export const UPDATE_SEARCH_SORT = 'UPDATE_SEARCH_SORT'
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER'
export const UPDATE_SEARCH_PRODUCT = 'UPDATE_SEARCH_PRODUCT'
export const UPDATE_INPUT_CITY = 'UPDATE_INPUT_CITY'
export const UPDATE_INPUT_NIGHTS = 'UPDATE_INPUT_NIGHTS'
export const UPDATE_INPUT_SORT = 'UPDATE_INPUT_SORT'
export const UPDATE_INPUT_FILTER = 'UPDATE_INPUT_FILTER'
export const RESET_INPUT_FILTER = 'RESET_INPUT_FILTER'
export const SET_SEARCH_URL = 'SET_SEARCH_URL'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL'
export const HIDE_UPGRADE_MODAL = 'HIDE_UPGRADE_MODAL'

export const SHOW_NEWSLETTER_MODAL = 'SHOW_NEWSLETTER_MODAL'
export const HIDE_NEWSLETTER_MODAL = 'HIDE_NEWSLETTER_MODAL'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const RESTORE_SESSION_START = 'RESTORE_SESSION_START'
export const RESTORE_SESSION_SUCCESS = 'RESTORE_SESSION_SUCCESS'
export const RESTORE_SESSION_FAIL = 'RESTORE_SESSION_FAIL'

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const PUT_PASSWORD_START = 'PUT_PASSWORD_START'
export const PUT_PASSWORD_SUCCESS = 'PUT_PASSWORD_SUCCESS'
export const PUT_PASSWORD_FAIL = 'PUT_PASSWORD_FAIL'

export const UPDATE_OFFER_SELECTION_SUBSCRIPTION_CATEGORY = 'UPDATE_OFFER_SELECTION_SUBSCRIPTION_CATEGORY'
export const UPDATE_OFFER_SELECTION_SUBSCRIPTION_ROOM = 'UPDATE_OFFER_SELECTION_SUBSCRIPTION_ROOM'
export const UPDATE_OFFER_SELECTION_SUBSCRIPTION_DURATION = 'UPDATE_OFFER_SELECTION_SUBSCRIPTION_DURATION'
export const UPDATE_OFFER_SELECTION_SUBSCRIPTION_START_DATE = 'UPDATE_OFFER_SELECTION_SUBSCRIPTION_START_DATE'
export const UPDATE_OFFER_SELECTION_NIGHTS = 'UPDATE_OFFER_SELECTION_NIGHTS'
export const UPDATE_OFFER_PRICE = 'UPDATE_OFFER_PRICE'
export const UPDATE_OFFER_PRICE_ANIMATION = 'UPDATE_OFFER_PRICE_ANIMATION'

export const MOVE_MAP_HOTEL_DETAIL = 'MOVE_MAP_HOTEL_DETAIL'
export const MOVE_MAP_SEARCH = 'MOVE_MAP_SEARCH'

export const POST_ACCOUNT_START = 'POST_ACCOUNT_START'
export const POST_ACCOUNT_SUCCESS = 'POST_ACCOUNT_SUCCESS'
export const POST_ACCOUNT_FAIL = 'POST_ACCOUNT_FAIL'

export const PUT_ACCOUNT_START = 'PUT_ACCOUNT_START'
export const PUT_ACCOUNT_SUCCESS = 'PUT_ACCOUNT_SUCCESS'
export const PUT_ACCOUNT_FAIL = 'PUT_ACCOUNT_FAIL'

export const PUT_CHECKOUT_UPDATE_CART_START = 'PUT_CHECKOUT_UPDATE_CART_START'
export const PUT_CHECKOUT_UPDATE_CART_SUCCESS = 'PUT_CHECKOUT_UPDATE_CART_SUCCESS'
export const PUT_CHECKOUT_UPDATE_CART_FAIL = 'PUT_CHECKOUT_UPDATE_CART_FAIL'

export const POST_CHECKOUT_FINALIZE_START = 'POST_CHECKOUT_FINALIZE_START'
export const POST_CHECKOUT_FINALIZE_SUCCESS = 'POST_CHECKOUT_FINALIZE_SUCCESS'
export const POST_CHECKOUT_FINALIZE_FAIL = 'POST_CHECKOUT_FINALIZE_FAIL'

export const GET_CUSTOMER_PROFILE_START = 'GET_CUSTOMER_PROFILE_START'
export const GET_CUSTOMER_PROFILE_SUCCESS = 'GET_CUSTOMER_PROFILE_SUCCESS'
export const GET_CUSTOMER_PROFILE_FAIL = 'GET_CUSTOMER_PROFILE_FAIL'
export const PUT_CUSTOMER_PROFILE_START = 'PUT_CUSTOMER_PROFILE_START'
export const PUT_CUSTOMER_PROFILE_SUCCESS = 'PUT_CUSTOMER_PROFILE_SUCCESS'
export const PUT_CUSTOMER_PROFILE_FAIL = 'PUT_CUSTOMER_PROFILE_FAIL'

export const GET_CUSTOMER_COMPANY_INVITE_START = 'GET_CUSTOMER_COMPANY_INVITE_START'
export const GET_CUSTOMER_COMPANY_INVITE_SUCCESS = 'GET_CUSTOMER_COMPANY_INVITE_SUCCESS'
export const GET_CUSTOMER_COMPANY_INVITE_FAIL = 'GET_CUSTOMER_COMPANY_INVITE_FAIL'
export const POST_CUSTOMER_COMPANY_INVITE_START = 'POST_CUSTOMER_COMPANY_INVITE_START'
export const POST_CUSTOMER_COMPANY_INVITE_SUCCESS = 'POST_CUSTOMER_COMPANY_INVITE_SUCCESS'
export const POST_CUSTOMER_COMPANY_INVITE_FAIL = 'POST_CUSTOMER_COMPANY_INVITE_FAIL'
export const PUT_CUSTOMER_COMPANY_INVITE_START = 'PUT_CUSTOMER_COMPANY_INVITE_START'
export const PUT_CUSTOMER_COMPANY_INVITE_SUCCESS = 'PUT_CUSTOMER_COMPANY_INVITE_SUCCESS'
export const PUT_CUSTOMER_COMPANY_INVITE_FAIL = 'PUT_CUSTOMER_COMPANY_INVITE_FAIL'
export const DELETE_CUSTOMER_COMPANY_INVITE_START = 'DELETE_CUSTOMER_COMPANY_INVITE_START'
export const DELETE_CUSTOMER_COMPANY_INVITE_SUCCESS = 'DELETE_CUSTOMER_COMPANY_INVITE_SUCCESS'
export const DELETE_CUSTOMER_COMPANY_INVITE_FAIL = 'DELETE_CUSTOMER_COMPANY_INVITE_FAIL'

export const GET_CUSTOMER_INVOICE_START = 'GET_CUSTOMER_INVOICE_START'
export const GET_CUSTOMER_INVOICE_SUCCESS = 'GET_CUSTOMER_INVOICE_SUCCESS'
export const GET_CUSTOMER_INVOICE_FAIL = 'GET_CUSTOMER_INVOICE_FAIL'

export const GET_CUSTOMER_SUBSCRIPTIONS_START = 'GET_CUSTOMER_SUBSCRIPTIONS_START'
export const GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS = 'GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS'
export const GET_CUSTOMER_SUBSCRIPTIONS_FAIL = 'GET_CUSTOMER_SUBSCRIPTIONS_FAIL'

export const UPDATE_CUSTOMER_BOOKING_RESERVATION_DELTA = 'UPDATE_CUSTOMER_BOOKING_RESERVATION_DELTA'

export const PUT_CUSTOMER_BOOKING_RESERVATION_START = 'PUT_CUSTOMER_BOOKING_RESERVATION_START'
export const PUT_CUSTOMER_BOOKING_RESERVATION_SUCCESS = 'PUT_CUSTOMER_BOOKING_RESERVATION_SUCCESS'
export const PUT_CUSTOMER_BOOKING_RESERVATION_FAIL = 'PUT_CUSTOMER_BOOKING_RESERVATION_FAIL'

export const UPDATE_CUSTOMER_SELECTED_SUBSCRIPTION = 'UPDATE_CUSTOMER_SELECTED_SUBSCRIPTION'
export const UPDATE_CUSTOMER_COMPANY_SELECTED_EMPLOYEE = 'UPDATE_CUSTOMER_COMPANY_SELECTED_EMPLOYEE'

export const GET_BUSINESS_COMPANY_PROFILE_START = 'GET_BUSINESS_COMPANY_PROFILE_START'
export const GET_BUSINESS_COMPANY_PROFILE_SUCCESS = 'GET_BUSINESS_COMPANY_PROFILE_SUCCESS'
export const GET_BUSINESS_COMPANY_PROFILE_FAIL = 'GET_BUSINESS_COMPANY_PROFILE_FAIL'

export const GET_BOOKING_HISTORY_ATTRIBUTES_START = 'GET_BOOKING_HISTORY_ATTRIBUTES_START'
export const GET_BOOKING_HISTORY_ATTRIBUTES_SUCCESS = 'GET_BOOKING_HISTORY_ATTRIBUTES_SUCCESS'
export const GET_BOOKING_HISTORY_ATTRIBUTES_FAIL = 'GET_BOOKING_HISTORY_ATTRIBUTES_FAIL'
export const GET_BOOKING_HISTORY_START = 'GET_BOOKING_HISTORY_START'
export const GET_BOOKING_HISTORY_SUCCESS = 'GET_BOOKING_HISTORY_SUCCESS'
export const GET_BOOKING_HISTORY_FAIL = 'GET_BOOKING_HISTORY_FAIL'

export const UPDATE_BOOKING_HISTORY_FILTER = 'UPDATE_BOOKING_HISTORY_FILTER'
