import React from 'react';
import {Link} from "react-router-dom";

const PageNotFound = () => {

  return (
    <div className="container max-w-screen-lg mx-auto mb-10">
      <div className="md:w-2/3 mx-auto">
        <div className="text-center text-3xl font-light mt-24">Nicht gefunden :-(</div>
        <div className="text-center mt-8 mb-16">

          Leider konnten wir die von dir gesuchte Seite nicht finden.<br/>
          Starte mit neuer <Link className="text-secondaryBlue hover:text-primary underline" to="/">Hotel Suche</Link> oder
          schreibe uns an <a href="mailto:hello@myflexhome.de" className="text-secondaryBlue hover:text-primary underline">hello@myflexhome.de</a>.

        </div>
      </div>
    </div>
  )
}

export default PageNotFound
