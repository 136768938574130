import * as actionTypes from '../actions/actionTypes';

const initialState = {
  mapsWindowHotelDetail: {
    latitude: 48.14185487956389,
    longitude: 11.57305881429826,
    zoom: 13,
  },
  mapsWindowSearch: {
    latitude: 48.14185487956389,
    longitude: 11.57305881429826,
    zoom: 11,
  },
  markerHotelDetail: {
    latitude: 48.14185487956389,
    longitude: 11.57305881429826,
  }
}


const updateMapsWithHotelLocation = (state, action) => {
  return {
    ...state,
    mapsWindowHotelDetail: {
      ...state.mapsWindowHotelDetail,
      latitude: action.res.locationLat || 48,
      longitude: action.res.locationLong || 11,
    },
    markerHotelDetail: {
      latitude: action.res.locationLat || 0,
      longitude: action.res.locationLong || 0,
    }
  }
}

const updateMapsWithSearchCityLocation = (state, action) => {
  return {
    ...state,
    mapsWindowSearch: {
      ...state.mapsWindowSearch,
      latitude: action.res.cityCenterLat || 45,
      longitude: action.res.cityCenterLong || 11,
      zoom: action.res.zoomLevel || 11,
    },
  }
}

const moveMapHotelDetail = (state, action) => {
  return {
    ...state,
    mapsWindowHotelDetail: {
      latitude: action.res.latitude,
      longitude: action.res.longitude,
      zoom: action.res.zoom,
    }
  }
}

const moveMapSearch = (state, action) => {
  return {
    ...state,
    mapsWindowSearch: {
      latitude: action.res.latitude,
      longitude: action.res.longitude,
      zoom: action.res.zoom,
    }
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.MOVE_MAP_HOTEL_DETAIL: return moveMapHotelDetail(state, action)
    case actionTypes.MOVE_MAP_SEARCH: return moveMapSearch(state, action)
    case actionTypes.GET_HOTEL_DETAIL_SUCCESS: return updateMapsWithHotelLocation(state, action)
    case actionTypes.UPDATE_SEARCH_CITY: return updateMapsWithSearchCityLocation(state, action)
    default: return state
  }
}

export default reducer
