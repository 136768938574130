import React from "react";
import {useHistory} from "react-router";

export const ButtonBack = (props) => {
  const {target, name} = props
  const history = useHistory()

  return(
    <button
      className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded flex items-center focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary"
      onClick={() => history.push(target || '/dashboard/')}
    >
      <svg className="w-5 h-7 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
        <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256" />
      </svg>
      <div className="h-7">{name || 'zurück'}</div>
    </button>
  )
}