import * as React from 'react';

const MapsPinMini = ({size = 20, fill='#25A9D5', stroke='none'}) => {
  return (
    <svg height={size} viewBox="0 0 24 24" style={{
      //cursor: 'pointer',
      fill: fill,
      stroke: stroke,
      strokeWidth: 1,
    }}>
      <path id="Path" stroke="none" d="M 21 10 C 21 17 12 23 12 23 C 12 23 3 17 3 10 C 3 5.029438 7.029437 1 12 1 C 16.970562 1 21 5.029438 21 10 Z"/>
      <path id="path1" fill="#ffffff" stroke="none" d="M 15 10 C 15 11.656855 13.656855 13 12 13 C 10.343145 13 9 11.656855 9 10 C 9 8.343145 10.343145 7 12 7 C 13.656855 7 15 8.343145 15 10 Z"/>
    </svg>
  )
}


export default React.memo(MapsPinMini)