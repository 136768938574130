import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Check from '@mui/icons-material/Check'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#086381',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#086381',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#e0e0e5',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#e0e0e5',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#086381',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#086381',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

const QontoStepIcon = (props) => {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
}

const steps = ['Hotel wählen', 'Konfigurieren', 'Login / Registrierung', 'Zusammenfassung','Zahlung']

const StepperBar = (props) => {
  const {active} = props
  const labelProps = {}

  labelProps.sx = {
    '& .MuiStepLabel-label': {
      color: '#086381',
      marginTop: 1,
    },
    '& .Mui-disabled': {
      color: '#b8babe',
    },
  }

  return (
    <>
      <div className="hidden md:block">
        <Stack sx={{ width: '100%' }} spacing={4} className="mt-6 bg-gray-50 rounded-lg p-2">
          <Stepper alternativeLabel activeStep={active} connector={<QontoConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon} {...labelProps}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
      <div className="block md:hidden mt-6 bg-gray-50 rounded-lg p-2 text-center">
        <span className="font-light">Schritt {active+1} von {steps.length}:</span> {steps[active]}
      </div>
    </>

  )
}

export default StepperBar
