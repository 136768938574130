export const geoJsonLayer = (highlight) => ({
  type: 'fill',
  paint: {
    'fill-color': highlight ? '#086381' : '#25A9D5',
    //'fill-color': highlight ? '#25A9D5' : '#34887d',
    'fill-opacity': 0.2,
  }
})

export const geoJsonLayerBorder = (highlight) => ({
  type: 'line',
  paint: {
    'line-color': highlight ? '#086381' : '#25A9D5',
    //'line-color': highlight ? '#25A9D5' : '#34887d',
    'line-width': highlight ? 3 : 2,
  }
})

export const geoJsonPolygon = (props) => {
  const {hotelId, coordinates} = props
  return ({
    properties:{name: hotelId},
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      // These coordinates outline the polygon in format [long,lat]
      coordinates: [
        coordinates
      ]
    }
  })
}