import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {ButtonBack} from "../components/Buttons";
import {Link} from "react-router-dom";

const CustomerProfile = () => {
  const history = useHistory()

  const profile = useSelector(state => state.customer.profile)


  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack/>

        <div className="text-center text-3xl font-light mt-8">Account</div>
        <div className="text-center text-md font-light mt-2">Kundenprofil und Einstellungen</div>

        <div className="flex justify-center rounded-lg mt-8">

          <table className="table-auto border-collapse border border-slate-400 sm:w-112 bg-gray-50">
            <tbody>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Vorname</td>
              <td className="border border-slate-300 p-3">{profile?.firstName}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Nachname</td>
              <td className="border border-slate-300 p-3">{profile?.lastName}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Firma</td>
              <td className="border border-slate-300 p-3">{profile?.BusinessCompany?.name}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Stadt</td>
              <td className="border border-slate-300 p-3">{profile?.addressZip} {profile?.addressCity}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Straße mit HausNr</td>
              <td className="border border-slate-300 p-3">{profile?.addressStreet}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Land</td>
              <td className="border border-slate-300 p-3">{profile?.addressCountry}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">E-Mail</td>
              <td className="border border-slate-300 p-3">{profile?.email}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Passwort</td>
              <td className="border border-slate-300 p-3">******** <br/>
                <Link to="/dashboard/password/"
                      className="border border-primary bg-blue-50 hover:bg-blue-100 rounded-md p-2 my-1">Passwort
                  ändern</Link>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Telefon</td>
              <td className="border border-slate-300 p-3">{profile?.contactPhone}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Newsletter</td>
              <td className="border border-slate-300 p-3">{profile?.mailNotification ? 'ja' : 'nein'}</td>
            </tr>
            </tbody>
          </table>

        </div>

        {profile.billingLastName || profile.billingStreet
          ? <div className="flex justify-center rounded-lg mt-8">

            <table className="table-auto border-collapse border border-slate-400 sm:w-112 bg-gray-50">
              <tbody>
              <tr>
                <td colSpan="2" className="border border-slate-300 p-3 font-light text-center bg-gray-200">
                  Abweichende Rechnungsadresse
                </td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-3 font-bold">Vorname</td>
                <td className="border border-slate-300 p-3">{profile?.billingFirstName}</td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-3 font-bold">Nachname</td>
                <td className="border border-slate-300 p-3">{profile?.billingLastName}</td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-3 font-bold">Firma</td>
                <td className="border border-slate-300 p-3">{profile?.billingCompany}</td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-3 font-bold">Stadt</td>
                <td className="border border-slate-300 p-3">{profile?.billingZip} {profile?.billingCity}</td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-3 font-bold">Straße mit HausNr</td>
                <td className="border border-slate-300 p-3">{profile?.billingStreet}</td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-3 font-bold">Land</td>
                <td className="border border-slate-300 p-3">{profile?.billingCountry}</td>
              </tr>
              </tbody>
            </table>

          </div>
          : ''

        }

        <div className="text-center mt-6">
          <button
            className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56 m-2"
            onClick={() => {
              history.push('/dashboard/profile/edit/');
              window.scrollTo(0, 0)
            }}
          >Daten bearbeiten
          </button>
        </div>

        {profile?.stripeCustomer
          ? <div className="flex justify-center mt-20">
              <table className="table-auto border-collapse border border-slate-400 sm:w-112 bg-gray-50">
                <tbody>
                <tr>
                  <td colSpan="2" className="border border-slate-300 p-3 font-light text-center bg-gray-200">
                    Zahlungsmethode
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="border border-slate-300 p-3 text-center bg-gray-50">
                    <form action="/api/customer/payment/update" method="POST">
                      <button
                        className="border border-primary bg-blue-50 hover:bg-blue-100 rounded-md p-2 my-1"
                        type="submit"
                      >
                        Zahlungsmethode anpassen
                      </button>
                    </form>
                    <div className="text-xs text-gray-500">Weiterleitung zu stripe.com</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          : ''}


      </div>

    </div>
  )
}

export default CustomerProfile
