import * as actionTypes from './actionTypes';
import axios from '../../axios.config';


/*
 * GET business company profile
 */
export const getBusinessCompanyProfile = (companyUUID) => {
  return dispatch => {
    dispatch(getBusinessCompanyProfileStart())
    axios.get('/business/company/publicProfile/'+companyUUID)
      .then ( response => {
        dispatch(getBusinessCompanyProfileSuccess(response.data))
      })
      .catch(error => {
        dispatch(getBusinessCompanyProfileFail(error))
      })
  }
}

const getBusinessCompanyProfileStart = () => {
  return {
    type: actionTypes.GET_BUSINESS_COMPANY_PROFILE_START,
  }
}

const getBusinessCompanyProfileSuccess = (res) => {
  return {
    type: actionTypes.GET_BUSINESS_COMPANY_PROFILE_SUCCESS,
    res: res,
  }
}

const getBusinessCompanyProfileFail = (error) => {
  return {
    type: actionTypes.GET_BUSINESS_COMPANY_PROFILE_FAIL,
    error: error,
  }
}