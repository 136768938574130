import {Dialog, Transition} from '@headlessui/react'
import {Fragment} from 'react'
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../store/actions';

const UpgradeModal = () => {
  const dispatch = useDispatch()
  const showUpgradeModal = useSelector(state => state.mainWindow.showUpgradeModal)

  return (
    <>
      <Transition appear show={showUpgradeModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => dispatch(actions.hideUpgradeModal())}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-white opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border-4 border-secondaryBlue shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Aktualisierung verfügbar
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Die MyFlexHome App wurde aktualisiert.<br/>
                    Damit dir die neusten Funktionen zur Verfügung stehen, muss die Seite einmal neu geladen werden.
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-secondaryBlue border border-transparent rounded-md hover:bg-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary"
                    onClick={() => window.location.reload()}
                  >
                    Los gehts!
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default UpgradeModal
