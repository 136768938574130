import axios from 'axios';
import versionJson from './versions.json';

const instance = axios.create({
  baseURL: '/api',
  headers: {
    "x-mfh-client" : versionJson.version
    }
})

export default instance
