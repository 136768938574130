import React, {useState} from 'react';
import * as actions from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {AdjustmentsHorizontalIcon, ArrowsUpDownIcon, MagnifyingGlassIcon, XMarkIcon} from '@heroicons/react/24/solid'
import ListBoxCity from "./ListBoxCity";
import ListBoxNights from "./ListBoxNights";
import ListBoxDefault from "./ListBoxDefault";
import {SwitchInput} from "./SwitchInput";
import SearchBarBusinessSwitch from "./SearchBarBusinessSwitch";

const SearchBarMobile = () => {

  const dispatch = useDispatch()

  const cityList = useSelector(state => state.search.cityList)
  const inputCity = useSelector(state => state.search.inputCity)

  const nightList = useSelector(state => state.search.nightListCurrentProduct)
  const inputNights = useSelector(state => state.search.inputNights)

  const sortList = useSelector(state => state.search.sortList)
  const inputSort = useSelector(state => state.search.inputSort)

  const filterList = useSelector(state => state.search.filterList)
  const inputFilter = useSelector(state => state.search.inputFilter)
  const searchFilter = useSelector(state => state.search.searchFilter)
  const searchFilterLength = searchFilter.split('|').length

  const searchProduct = useSelector(state => state.search.searchProduct)
  const business = (searchProduct === 'business')

  const [overlayStyle, setOverlayStyle] = useState('hidden')
  const [searchBoxStyle, setSearchBoxStyle] = useState('hidden')
  const [filterBoxStyle, setFilterBoxStyle] = useState('hidden')


  const handleCityChange = (value) => {
    dispatch(actions.updateInputCity(value.cityId))
  }

  const handleNightsChange = (value) => {
    dispatch(actions.updateInputNights(value.n))
  }

  const handleSortChange = (value) => {
    dispatch(actions.updateInputSort(value))
  }

  const handleFilterChange = (e, name) => {
    dispatch(actions.updateInputFilter({[name]:e}))
  }

  const handleSearchButton = () => {
    dispatch(actions.updateSearchCity(inputCity.cityId))
    dispatch(actions.updateSearchNights(inputNights))
    dispatch(actions.setSearchUrl())
    closeOverlay()
  }

  const handleFilterButton = () => {
    dispatch(actions.updateSearchSort())
    dispatch(actions.updateSearchFilter())
    dispatch(actions.setSearchUrl())
    closeOverlay()
  }

  const openSearchOverlay = () => {
    setFilterBoxStyle('hidden')
    setSearchBoxStyle('slide-in-top')
    setOverlayStyle('')
  }

  const openFilterOverlay = () => {
    setSearchBoxStyle('hidden')
    setFilterBoxStyle('slide-in-top')
    setOverlayStyle('')
  }

  const closeOverlay = (event=null) => {
    setSearchBoxStyle('slide-out-top')
    setFilterBoxStyle('slide-out-top')
    setOverlayStyle('hidden')
    if(event) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  const resetFilter = () => {
    dispatch(actions.resetInputFilter())
    dispatch(actions.updateSearchFilter())
    dispatch(actions.setSearchUrl())
  }


  return (
    <div>

      <div className="flex justify-between mt-1">
        <div className="flex align-middle justify-center items-center text-gray-600 bg-gray-100 rounded-lg mx-5 py-1 px-3" onClick={openSearchOverlay}>
          <MagnifyingGlassIcon className="w-5 h-5" />
          <div className="ml-2 font-thin">{inputCity?.cityName || ''} &nbsp; | &nbsp; {business ? 'ab ': ''} {inputNights} Nächte</div>
        </div>

        <div className="flex align-middle justify-center items-center text-gray-600 bg-gray-100 rounded-lg mx-3 py-1 px-3" onClick={openFilterOverlay}>
          {searchFilter ? <span className="bg-red-700 rounded-3xl text-white px-1 text-xs mr-1">{searchFilterLength}</span> : ''}
          <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
          <ArrowsUpDownIcon className="w-5 h-5" />
        </div>
      </div>



      <div id="overlay" className={`absolute top-0 left-0 w-full h-screen ${overlayStyle}`} >

        <div className="absolute top-0 left-0 w-full h-screen bg-gray-400 bg-opacity-75" onClick={closeOverlay} onTouchEnd={closeOverlay}> </div>

        <div id="searchBox" className={`absolute top-0 left-0 bg-gray-50 rounded-b-xl shadow-lg w-full ${searchBoxStyle}`}>
          <div className="flex justify-end text-primary" onClick={closeOverlay}>
            <XMarkIcon className="text-primary w-8 h-8 mt-2 mr-2" />
          </div>

          <div className="mt-5 mb-5 flex justify-center">
            <SearchBarBusinessSwitch />
          </div>

          <div className="flex justify-center">
            <div className="mx-auto flex justify-center flex-wrap sm:flex-nowrap items-center bg-gray-50 rounded-lg p-1 sm:bg-gray-100 sm:mb-5">

              <div className="pb-2 px-3 mx-1 my-0 w-full sm:w-auto">
                <div className="text-xs text-gray-500 font-medium">STADT</div>
                <ListBoxCity data={cityList} searchCity={inputCity} setSearchCity={handleCityChange} />
              </div>

              <div className="p-2 px-3 mx-1 my-0 w-full sm:w-auto">
                <div className="text-xs text-gray-500 font-medium">ANZAHL-NÄCHTE</div>
                <ListBoxNights data={nightList} searchNights={inputNights} addFrom={business} setSearchNights={handleNightsChange} />
              </div>

              <div className="w-full sm:w-40 ml-2 flex my-2 mr-2">
                <button
                  className="btn flex justify-center items-center rounded-lg text-white bg-primary hover:bg-secondaryBlue mx-1 my-1 px-3 py-3 sm:px-4 w-full noShadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-orange-300"
                  onClick={handleSearchButton}
                ><MagnifyingGlassIcon className="w-5 h-5" /><span className="font-bold ml-2">Suchen</span></button>
              </div>

            </div>
          </div>

        </div>


        <div id="filterBox" className={`absolute top-0 left-0 bg-gray-50 rounded-b-2xl w-full ${filterBoxStyle}`}>
          <div className="flex justify-end text-primary" onClick={closeOverlay}>
            <XMarkIcon className="text-primary w-8 h-8 mt-2 mr-2" />
          </div>

          <div className="mx-auto flex justify-center flex-wrap items-stretch w-full bg-gray-50 rounded-lg p-1 shadow-lg">

            <div className="bg-gray-50 pb-2 px-3 mx-1 my-0 w-full sm:w-72">
              <div className="text-xs text-gray-500 font-medium">FILTERN</div>

              <div className="py-3 mx-3">
                {filterList.map(x =>
                  <SwitchInput
                    key={x.id}
                    enabled={inputFilter[x.id]}
                    setEnabled={(e) => handleFilterChange(e, x.id)}
                    name={x.n}
                    iconUrl={`/api/booking/media/${x.id}`}
                  />
                )}
                {searchFilter && searchFilterLength > 0
                  ? <div className="text-right mt-2">
                    <button className="bg-red-200 text-black rounded-lg cursor-pointer px-4 py-1 text-sm" onClick={resetFilter}>Filter Zurücksetzen</button>
                  </div>
                  : ''
                }
              </div>
            </div>

            <div className="bg-gray-50 pb-2 px-3 mx-1 my-0 w-full sm:w-56">
              <div className="text-xs text-gray-500 font-medium">SORTIEREN</div>
              <div className="mt-1"><ListBoxDefault data={sortList} value={inputSort} setValue={handleSortChange} /></div>
            </div>

            <div className="w-full sm:w-40 ml-2 flex my-2 mr-2">
              <button
                className="btn flex justify-center items-center rounded-lg text-white bg-primary hover:bg-secondaryBlue mx-1 my-1 px-3 py-3 sm:px-4 w-full sm:h-16 noShadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-orange-300"
                onClick={handleFilterButton}
              ><AdjustmentsHorizontalIcon className="w-5 h-5" /><span className="font-bold ml-2">Filtern & Sortieren</span></button>
            </div>

          </div>
        </div>



      </div>
    </div>
  )

}

export default SearchBarMobile
