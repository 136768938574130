import * as React from 'react';

const MapsPinSingle = ({size = 20, fill='#25A9D5', stroke='none'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 2556 3063" fillRule="evenodd">
      <path style={{fill:fill, stroke: stroke, strokeWidth: 100}}
            d="M1273.667 3063.38c-379.222-263.084-782.093-651.255-1034.85-1065.82-99.554-136.985-172.051-294.428-209.73-464.672l-6.971-25.707 1.572.014C7.907 1427.984-.37 1346.126-.37 1262.374-.37 565.517 572.355-.245 1277.79-.245s1278.16 565.762 1278.16 1262.619c0 91.255-9.828 180.26-28.482 266.043l1.629.014-9.178 32.586c-36.727 149.364-100.375 288.375-185.572 411.727-250.262 421.218-665.449 827.211-1060.68 1090.637z"/>
      <path fill="#fff"
            d="M1562.981 1750.032c-.159 0-.318-.004-.477-.006H871.176c-27.336 0-49.529-22.193-49.529-49.529v-434.906l-33.271 28.846c-13.064 11.326-32.828 9.881-44.107-3.223l-40.873-47.486c-11.28-13.104-9.831-32.937 3.232-44.264l534.154-463.086c12.791-11.09 32.005-9.934 43.389 2.42 4.665 1.235 9.113 3.573 12.939 7.037l222.82 201.727V851.52c0-23.76 19.291-43.051 43.051-43.051h86.1c23.76 0 43.049 19.291 43.049 43.051v251.938l129.105 116.883c12.816 11.603 13.836 31.463 2.279 44.32l-41.881 46.594c-11.558 12.858-31.346 13.875-44.162 2.271l-45.342-41.049v428.02 6.484c0 23.76-19.289 43.051-43.049 43.051h-86.1z"/>
      <path
        d="M1367.659 1226.51c0 63.882-51.823 115.669-115.749 115.669s-115.749-51.787-115.749-115.669 51.823-115.669 115.749-115.669 115.749 51.787 115.749 115.669zm122.681 405.58c4.254-14.039 6.402-28.409 6.402-42.826 0-103.83-109.269-188.126-243.857-188.126s-243.858 84.296-243.858 188.126c0 14.417 2.148 28.787 6.402 42.826h474.911z"
        style={{fill:fill}} />
    </svg>
  )
}

export default React.memo(MapsPinSingle)