import React, {useEffect, useState} from "react";
import {ArrowRightIcon, XCircleIcon} from "@heroicons/react/24/solid";
import {Controller, useForm, useWatch} from "react-hook-form";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import LoadingSpinner from "../assets/svg/LoadingSpinner";

export const SecretPriceReveal = (props) => {

  const dispatch = useDispatch()

  const postAccountIsLoading = useSelector(state => state.offer.registration.postAccountIsLoading)
  const product = useSelector(state => state.offer.product)
  const [showEmailInput, setShowEmailInput] = useState(false)
  const [showRegistration, setShowRegistration] = useState(false)

  const handleShowPrice = (e) => {
    setShowEmailInput(!showEmailInput)
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const stopPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  /* react hook form */
  const { control, handleSubmit, resetField} = useForm({
    defaultValues: {
      email: '',
      privacy: false,
      mailNotification: false,
    }
  })

  const emailWatch = useWatch({
    control,
    name: "email",
  })
  const eMailIsValid = emailWatch.match(/\S+@\S+\.\S+/)

  const onSubmit = data => {
    const payload = {
      ...data,
      password: 'passwordless_registration',
      registrationSource: 'membersOnly/'+props?.hotel?.hotelId || 'membersOnly',
      product: product,
    }
    dispatch(actions.postAccount(payload,'/search/muenchen/?nights=4'))
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setShowEmailInput(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return(
    <div className="relative top-0 right-0">
      <div className="flex flex-col justify-end items-end">

        <button
          className="bg-membersOnly hover:bg-membersOnlyHover text-white rounded-lg py-2 px-3 inline-flex items-center shadow-lg"
          onClick={handleShowPrice}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
          </svg>
          <span className="ml-1 text-sm">Angebot freischalten</span>
        </button>

        <div className={` ${showEmailInput ? 'block' : 'hidden'} absolute top-10 right-0 border-2 w-72 border-secondaryBlue shadow-xl bg-gray-100 p-2 rounded-lg z-10`}>
          <form id="mail_register_form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="">

            <div className="w-full flex justify-stretch">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Bitte gib deine E-Mail-Adresse an',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Bitte gib eine gültige E-Mail-Adresse an"
                  }
                }}
                render={({ field,fieldState}) =>  (
                  <div className="w-full">
                    <input
                      className="w-full py-2 px-3 mr-1 appearance-none border border-gray-300 rounded text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="email"
                      id="emailForPriceReveal"
                      placeholder="E-Mail eingeben"
                      autoComplete="none"
                      data-lpignore="true"
                      value={field.value}
                      onChange={field.onChange}
                    />

                  </div>
                )}/>

              <button
                type="button"
                className="items-center py-2 px-1 text-gray-300 hover:text-gray-400 rounded-md -ml-7"
                onClick={() =>
                  {
                    resetField('email')
                    setShowEmailInput(false)
                  }
                }
              >
                <XCircleIcon className="h-5 w-5" aria-hidden="true"/>
              </button>
              {postAccountIsLoading
                ? ''
                : <button
                  className="bg-gray-400 hover:bg-gray-500 text-white text-sm rounded-lg py-1 px-2 ml-1 inline-flex items-center"
                  onClick={() => {setShowRegistration(!showRegistration); stopPropagation();}}
                  type="submit"
                >
                  <ArrowRightIcon className="h-5 w-5" aria-hidden="true"/>
                </button>
              }

            </div>

            <div className={eMailIsValid ? "block ml-3 mt-1" : "hidden"}>

              <Controller
                name="privacy"
                control={control}
                rules={{
                  required: 'Bitte akzeptiere die AGB und Datenschutzerklräung',
                }}
                render={({ field,fieldState}) =>  (
                  <FormControlLabel
                    control={<Checkbox
                      {...field}
                      checked={field.value}
                      sx={fieldState.error ? {color: 'rgb(185 28 28)'} : {}}
                    />}
                    label={
                      <div className={fieldState.error
                        ? 'font-light text-xs text-red-700' :
                        'font-light text-xs'}>Ich akzeptiere <a
                        href="https://www.myflexhome.de/agb/"
                        target="_blank" rel="noreferrer"
                        className="underline text-secondaryBlue">AGB</a> und <a
                        href="https://www.myflexhome.de/datenschutz/"
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-secondaryBlue">Datenschutzerklärung</a>.
                      </div>
                    }
                  />

                )}
              />

              <Controller
                name="mailNotification"
                control={control}
                render={({ field,fieldState}) =>  (
                  <FormControlLabel
                    control={<Checkbox
                      {...field}
                      checked={field.value}
                    />}
                    label={
                      <div className="font-light text-xs">Ich möchte über Angebote
                        und Rabatte informiert werden.</div>
                    }
                  />

                )}
              />

              <div className="ml-8 mt-2">
                {postAccountIsLoading
                  ? <button type="button" onClick={() => false} className="bg-gray-400 border-t-2 border-l-2 border-gray-700 text-white text-sm rounded-lg py-2 px-7 inline-flex cursor-wait">
                      <LoadingSpinner color="white" /> Preis anzeigen
                    </button>
                  : <button type="submit" className="bg-primary text-white text-sm hover:bg-primaryHover rounded-lg py-2 px-7">
                      Preis anzeigen
                    </button>
                }
              </div>

              <div className="ml-8 mt-3 text-xs text-gray-600 font-bold">
                Schon registriert? <a href={'/login/?target=/dashboard/'} className="underline text-secondaryBlue">Zum Login</a>
              </div>

            </div>

          </form>

        </div>
      </div>
    </div>
  )
}