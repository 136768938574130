import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../store/actions';
import NewsletterForm from "./NewsletterForm";
import mediaUrl from '../assets/images/hotel_blur_bg.jpg'

const NewsletterModal = () => {
  const dispatch = useDispatch()
  const showNewsletterModal = useSelector(state => state.mainWindow.showNewsletterModal)

  return (
    <>
      <Transition appear show={showNewsletterModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => dispatch(actions.hideNewsletterModal())}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0">
                <div className="absolute inset-0 bg-black opacity-70"/>
              </Dialog.Overlay>
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >

              <Dialog.Panel className="inline-block w-full max-w-xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white border-4 border-primary shadow-xl rounded-2xl">
                <div className="flex">
                  <div>
                    <NewsletterForm
                      headline={'10% Rabatt auf deinen ersten Abo Monat'}
                      description={'Gültig bei Abo Abschluss bis zum 30.09.2022, nicht kombinierbar mit anderen Rabatt-Aktionen.'}
                      source={'DiscountOnPageLeave'}
                      showDiscountNewsletterInput={true}
                    />
                  </div>
                  <div className="hidden md:block" style={{backgroundImage:`url(${mediaUrl})`, backgroundSize:'cover', backgroundPosition:'center', width:400}}> </div>
                  <div
                    className="absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
                    onClick={() => dispatch(actions.hideNewsletterModal())}
                  >
                    <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                         viewBox="0 0 18 18">
                      <path
                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                    </svg>
                  </div>
                </div>

              </Dialog.Panel>

            </Transition.Child>
          </div>

        </Dialog>
      </Transition>
    </>
  )
}

export default NewsletterModal
