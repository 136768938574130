import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import * as actions from "../store/actions";
import {Link} from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.logout())
  }, [dispatch])

  return (
    <div className="container max-w-screen-lg mx-auto mb-10">
      <div className="w-2/3 mx-auto">
        <div className="text-center text-3xl font-light mt-24">Erfolgreich abgemeldet</div>
        <div className="text-center text-md font-light mt-8 mb-16">
          Zurück zum <Link className="underline" to="/dashboard/">Login / Dashboard</Link> oder zur <a
          className="underline" href="https://www.myflexhome.de/">MyFlexhome Startseite</a>.
        </div>
      </div>
    </div>
  )
}

export default Logout
