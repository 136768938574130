import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import FsLightbox from 'fslightbox-react';

const Comparison = () => {

  const [toggler, setToggler] = useState(false);

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-10 mb-20 mx-auto">
        <h1 className="text-4xl font-extralight mb-8">Zweitwohnsitz Kosten-Rechner</h1>

        <div className="flex">
          <div className="w-1/2 text-left mx-auto p-4 bg-gray-50">
            <h2 className="text-xl font-bold mb-4">Zweitwohnung</h2>
            <div className="mx-auto ml-5 mr-12">
              <label htmlFor="miete" className="text-sm font-bold text-secondaryGray">Kalt-Miete</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border-2 border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:border-primary"
                defaultValue="691,12"
                type="text" name="miete"/>
              <label htmlFor="miete" className="text-sm font-bold text-secondaryGray">Nebenkosten</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border-2 border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:border-primary"
                defaultValue="165,73"
                type="text" name="miete"/>
              <label htmlFor="miete" className="text-sm font-bold text-secondaryGray">Zweitwohnsitzsteuer</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border-2 border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:border-primary"
                defaultValue="82,45"
                type="text" name="miete"/>
              <label htmlFor="miete" className="text-sm font-bold text-secondaryGray">Internet</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border-2 border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:border-primary"
                defaultValue="33,50"
                type="text" name="miete"/>
              <label htmlFor="miete" className="text-sm font-bold text-secondaryGray">Rundfunkbeitrag / GEZ</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border-2 border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:border-primary"
                defaultValue="18,36"
                type="text" name="miete"/>
              <label htmlFor="miete" className="text-sm font-bold text-secondaryGray">Putz-Hilfe</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border-2 border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:border-primary"
                defaultValue="97,50"
                type="text" name="miete"/>
              <label htmlFor="miete" className="text-sm font-bold text-secondaryGray">Sonstiges</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border-2 border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:border-primary"
                type="text" name="miete"/>

            </div>
          </div>

          <div className="table w-12">
            <div className="table-cell align-middle transform -rotate-90 font-bold text-4xl text-gray-400">vs.</div>
          </div>

          <div className="w-1/2 text-left mx-auto p-4 bg-gray-50">
            <h2 className="text-xl font-bold mb-4">MyFlexHome Hotel-Abo</h2>
            <div className="mx-auto ml-5 mr-12">

              <div className="">
                <div className="bg-white rounded pb-3 px-3 mx-1 my-2 w-full border-2 border-gray-300 focus-within:border-primary">
                  <FormControl
                    className="w-full"
                    //error={errors.has('nights')}
                  >
                    <InputLabel id="nights">Abo-Nächte pro Monat</InputLabel>
                    <Select
                      labelId="nights"
                      className="mt-4"
                      //value={nights}
                      //onChange={handleNightsChange}
                      displayEmpty
                      disableUnderline
                      inputProps={{ 'aria-label': 'Abo-Nächte pro Monat' }}
                    >
                      {[...Array(7)].map((x, i) =>
                        <MenuItem value={i+4} key={i+4}>{i+4}</MenuItem>
                      )}

                    </Select>
                  </FormControl>
                </div>

                <div className="mt-12 ml-12 mb-2 text-primary text-xl font-bold">All-Inclusive</div>
                <div className="ml-20 text-secondaryBlue">
                  <ul className="list-disc">
                    <li>keine Zweitwohnsitzsteuer</li>
                    <li>Wifi inklusive</li>
                    <li>keine GEZ</li>
                    <li>Zimmer Reinigung inklusive</li>
                  </ul>
                </div>

                <div className="mt-12 ml-12 text-lg text-gray-400 font-light">Zum Hotel-Abo in deiner Stadt:</div>
                <div className="mt-2 ml-12">
                  <button
                    className="btn bg-primary text-white hover:bg-secondaryBlue rounded p-2 w-56"
                    onClick={null}
                  >
                    Jetzt Hotel finden
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="w-1/2 text-left mx-auto">
            <div className="bg-yellow-50 p-3 text-right">
              <div className="font-bold">Summe</div>
              <div className="font-light text-2xl">1089,46 <span className="text-xs">EUR / Monat</span></div>
            </div>
          </div>
          <div className="table w-12">
            <div className="table-cell align-middle transform -rotate-90 font-bold text-4xl text-secondaryYellow"> &nbsp; </div>
          </div>
          <div className="w-1/2 text-left mx-auto">
            <div className="bg-green-50 p-3 text-right">
              <div className="font-bold">Summe</div>
              <div className="font-light text-2xl">ab 450 <span className="text-xs">EUR / Monat</span></div>
            </div>
          </div>
        </div>


        <div id="lightbox19">
          <button onClick={() => setToggler(!toggler)}>
            Toggle Lightbox
          </button>
          <FsLightbox
            toggler={toggler}
            sources={[
              'https://i.imgur.com/fsyrScY.jpg',
              'https://i.imgur.com/5yeBVeM.jpeg',
              'https://i.imgur.com/WHnTGPB.jpeg'
            ]}
          />
        </div>

      </div>
    </div>
  )
}

export default Comparison;
