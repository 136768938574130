const BusinessBuilding = ({fill = 'currentColor', className = 'w-4 h-4'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={fill} className={className} viewBox="0 0 50 50">
      <path d="M7 2v5H2v39h12v-2H4V9h5V4h20v3H11v2h23v11h2V7h-5V2zm1 10v2h4v-2zm9 0v2h4v-2zm9 0v2h4v-2zM8 17v2h4v-2zm9
      0v2h4v-2zm9 0v2h4v-2zm3 3c-3.258 0-5.672 1.324-6.926 3.297-1.145 1.797-1.199
      4.043-.48 6.137-.078.133-.191.246-.266.406-.156.352-.293.75-.273 1.258v.004c.055 1.277.797 1.945
      1.344 2.32.23 1.219.809 2.238 1.602 2.969v2.141c-.176.422-.527.777-1.203 1.148-.707.391-1.664.758-2.652
      1.238s-2.02 1.094-2.82 2.07S16 45.309 16
      47v1h32.051l-.059-1.059c-.09-1.578-.676-2.824-1.504-3.719s-1.844-1.457-2.809-1.902l-2.57-1.148c-.645-.34-.961-.66-1.109-1.012v-1.687c.598-.863.859-1.855.938-2.785.477-.422
      1.031-1.07 1.188-2.23.105-.832-.105-1.461-.43-1.992.449-1.187.633-2.621.238-4.047-.227-.828-.656-1.641-1.371-2.246-.559-.48-1.324-.746-2.172-.863L37.75
      22h-.625c-1.027 0-2.039.238-2.91.578a7.92 7.92 0 0 0-.949.449c-.164-.219-.344-.426-.551-.613-.609-.551-1.453-.863-2.391-.992L29.621
      20zM8 22v2h4v-2zm9 0v2h2.484l1.277-2zm11.438.113l.59 1.188h.617c.82 0 1.324.234 1.727.594s.695.902.867 1.535c.344 1.266.051 2.91-.23
      3.363l-.363.578.406.551c.238.316.391.645.313 1.086-.109.617-.332.699-.773 1.082l-.332.285-.012.438c-.035.98-.375 1.965-.945
      2.527l-.301.293v3.355l.059.164c.395 1.098 1.277 1.781 2.176 2.277s1.867.859 2.742 1.285 1.633.91 2.156 1.547c.363.438.586 1.027.723
      1.738H18.145c.137-.711.359-1.301.723-1.738.523-.637 1.281-1.121 2.156-1.547l2.742-1.285c.898-.496 1.781-1.18
      2.176-2.277l.059-.164v-3.465l-.437-.297c-.461-.312-1.219-1.457-1.324-2.484l-.055-.555-.5-.242c-.285-.137-.602-.191-.633-.93
      0 0 .031-.191.105-.359s.219-.352.176-.309l.48-.48-.27-.625c-.746-1.719-.645-3.52.219-4.875.789-1.242 2.336-2.098
      4.676-2.254zm8.121 2l.531 1.086h.625c.758 0 1.207.199 1.551.492s.594.723.738 1.258c.297 1.07.082 2.531-.258
      3.195l-.328.652.516.512c-.066-.066.262.477.207.887-.129.98-.27.918-.691 1.195l-.418.277-.031.504c-.047.871-.484
      2.18-.719 2.418l-.281.289v2.742l.059.16c.379 1.055 1.238 1.695 2.109 2.156s1.813.801 2.676 1.199 1.633.848 2.176
      1.441c.348.375.582.855.75 1.422h-5.848c-.164-1.223-.609-2.234-1.246-3.012-.801-.977-1.832-1.59-2.82-2.07l-2.652-1.238c-.676-.371-1.027-.727-1.203-1.148v-2.234c.691-.875
      1.055-1.906 1.156-2.953.387-.34.988-.926 1.176-1.984.152-.867-.105-1.574-.426-2.148.531-1.223.688-2.75.262-4.309-.004-.016-.012-.031-.016-.047a4.7 4.7 0 0 1
      .797-.414c.488-.191 1.059-.262 1.609-.328zM8 27v2h4v-2zm9 0v2h2.754l-.359-2zm-9 5v2h4v-2zm9 0v2h3.449l-.836-2zm-9 5v2h4v-2zm9 0v2h4v-2z"/>
    </svg>
  )
}

export default BusinessBuilding
