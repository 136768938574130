import * as actionTypes from './actionTypes';

export const showNotification = (message, variant='success') => {
  return {
    type: actionTypes.SHOW_NOTIFICATION,
    message: message,
    variant: variant,
  }
}

export const hideNotification = () => {
  return {
    type: actionTypes.HIDE_NOTIFICATION,
  }
}

export const showUpgradeModal = () => {
  return {
    type: actionTypes.SHOW_UPGRADE_MODAL,
  }
}

export const hideUpgradeModal = () => {
  return {
    type: actionTypes.HIDE_UPGRADE_MODAL,
  }
}

export const showNewsletterModal = () => {
  return {
    type: actionTypes.SHOW_NEWSLETTER_MODAL,
  }
}

export const hideNewsletterModal = () => {
  return {
    type: actionTypes.HIDE_NEWSLETTER_MODAL,
  }
}
