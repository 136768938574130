import React from 'react';
import {useDispatch} from "react-redux";
import * as actions from "../store/actions";
import {Link} from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch()

  const [values, setValues] = React.useState({
    email:'',
    password:''
  })

  const loginHandler = (event) => {
    event.preventDefault()
    dispatch(actions.login(values))
    return true
  }

  const inputChangedHandler = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <div className="container max-w-screen-lg mx-auto mb-10">
      <div className="md:w-2/3 mx-auto">
        <div className="text-center text-3xl font-light mt-10">MyFlexHome Login</div>
        <div className="mx-auto mt-8 mb-16 w-80 md:w-96">

          <div className="p-6 text-left border border-gray-200 rounded-lg bg-gray-50">
            <form id="contact_form" onSubmit={loginHandler} autoComplete="on" className="">
              <label htmlFor="eMail" className="text-sm font-light text-secondaryGray">E-Mail</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="email"
                id="email"
                onChange={(e) => inputChangedHandler(e)}
              />
              <label htmlFor="password" className="text-sm font-light text-secondaryGray">Passwort</label>
              <input
                className="w-full py-3 px-3 mb-3 appearance-none border border-gray-300 rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                name="password"
                autoComplete="current-password"
                id="password"
                onChange={(e) => inputChangedHandler(e)}
              />
              <button
                className="w-full mt-4 bg-primary text-white hover:bg-secondaryBlue rounded p-2"
                type="submit"
              >
                Einloggen
              </button>
              <div className="mt-8 text-sm font-light text-gray-500 text-right">
                <Link to="/reset-password/" className="underline">Passwort vergessen?</Link>
              </div>
            </form>
          </div>
          <div className="mt-10 text-sm text-center text-gray-500">
            Noch keinen Account bei MyFlexHome?<br/> Jetzt unverbindlich registrieren<br/>
            <Link to="/new-account/" className="underline text-secondaryBlue">Für Dich</Link> &nbsp; | &nbsp;
            <Link to="/business/new-account/" className="underline text-secondaryBlue">Für Unternehmen</Link>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Login
