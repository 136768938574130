import React from 'react';
import helpIcon from '../assets/svg/help.svg';
import Tippy from '@tippyjs/react';

export const FormTextArea = ({fieldId, fieldName, cols=40, rows=4, required, handlers, errorText='Fehler', help=false}) => {
  const {inputChangeHandler, inputBlurHandler, input, errors} = handlers;

  const styleOK = 'shadow appearance-none border-2 border-gray-400 rounded w-8/12 lg:w-2/3 xl:w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus-within:border-primary'
  const styleNOK = 'shadow appearance-none border-2 border-red-500 rounded w-8/12 lg:w-2/3 xl:w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus-within:border-primary'
  return(
    <div className="mb-4">
      <div className="flex flex-row items-center">
        <label className="text-gray-700 text-sm font-bold mx-2 w-20" htmlFor={fieldId}>{fieldName}{required ? '*':''}</label>
        <textarea
          className={errors.has(fieldId) ? styleNOK : styleOK}
          id={fieldId}
          name={fieldId}
          cols={cols}
          rows={rows}
          defaultValue={input[fieldId]}
          onChange={(e) => inputChangeHandler(e)}
          onBlur={() => inputBlurHandler(fieldId)}
        />
        { help
          ? <Tippy theme="light" content={
              <span>{help}</span>
            }>
              <img src={helpIcon} className="ml-3 w-5" alt="Hilfe" />
            </Tippy>
          : ''
        }
      </div>
      <div className="text-red-700 text-xs pl-24">{errors.has(fieldId) ? errorText : ''}</div>
    </div>
  )
}
