import Tippy from "@tippyjs/react";
import React from "react";
import {Info} from "@mui/icons-material";

export const HotelBadgeDiscount = (props) => {
  const {hotel, align='justify-start m-1'} = props

  return (
    <div className={`flex ${align}`}>
      <Tippy theme="tippyDiscount" content={
        <div>
          {hotel.standardRoomOfferDiscount?.description}
        </div>
      }>
        <div
          onClick={(e) => {e.preventDefault();e.stopPropagation(); return false;}}
          className="text-gray-700 bg-red-50 text-xs rounded-lg px-3 py-2 sm:py-1 flex justify-center items-center">
          <span className="text-discount mr-1">{hotel.standardRoomOfferDiscount?.name}</span>
          <Info className="text-discount"/>
        </div>
      </Tippy>
    </div>
  )
}