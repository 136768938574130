import {compareDateToArray, dateArrayFromRange} from "./dateHelper";


export const calcSelectedNightsCountFromBookingDelta = (dateFrom, dateTo, bookingReservationDelta) => {
  /* increase counter for nights with request='book', decrease for nights with request='cancel' */
  const currentMonth = bookingReservationDelta.filter(day => (day.arrivalDate >= new Date(dateFrom) && day.arrivalDate <= new Date(dateTo)))
  const bookRequests = currentMonth.filter(day => day.request === 'book')
  const cancelRequests = currentMonth.filter(day => day.request === 'cancel')
  return 0 + bookRequests.length - cancelRequests.length
}


export const getSurchargeByRoomAndDay = (subscriptionRoomUuid, day, specialDateRanges) => {
  let result = null
  specialDateRanges.forEach(specialDateRange => {
    /* find surcharge value to selected room, fallback 0 if there is a special date without surcharge */
    const surcharge = specialDateRange?.BookingSpecialDateSurcharges?.filter(surcharge => surcharge.BookingRoom.uuid === subscriptionRoomUuid)[0]?.surcharge || 0
    /* create array of days from start and end-date */
    const dateArray = dateArrayFromRange(specialDateRange.dateFrom, specialDateRange.dateTo)
    /* if selected day is within date array, show surcharge value */
    if(compareDateToArray(day, dateArray)) {
      result = {
        surcharge: surcharge,
        description: specialDateRange.description,
      }
    }
  })

  return result
}