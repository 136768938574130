import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from './mainWindow';
import * as actions from "./index";

/*
 *   LOGIN
 */

export const login = (payload) => {
  return dispatch => {
    dispatch(loginStart())
    axios.post('/customer/login', payload)
      .then ( response => {
        dispatch(showNotification('Login erfolgreich'))
        dispatch(loginSuccess(response.data))
        dispatch(restoreSession(response.data))
      })
      .catch(error => {
        dispatch(showNotification('Login fehlgeschlagen', 'error'))
        dispatch(loginFail(error))
      })
  }
}

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  }
}

export const loginSuccess = (res) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    res: res,
  }
}

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: error,
  }
}

/*
 *   LOGOUT
 */

export const logout = () => {
  return dispatch => {
    dispatch(logoutStart())
    axios.post('/customer/logout?t='+ new Date().getTime())
      .then ( response => {
        dispatch(logoutSuccess(response.data))
      })
      .catch(error => {
        dispatch(logoutFail(error))
      })
  }
}

export const logoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
  }
}

export const logoutSuccess = (res) => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    res: res,
  }
}

export const logoutFail = (error) => {
  return {
    type: actionTypes.LOGOUT_FAIL,
    error: error,
  }
}


/*
 *   RESTORE SESSION
 */

export const restoreSession = () => {
  return dispatch => {
    dispatch(restoreSessionStart())
    axios.post('/customer/login/reconnect')
      .then ( response => {
        dispatch(restoreSessionSuccess(response.data))
        dispatch(actions.getCustomerProfile())
        //dispatch(getVersion())
        //dispatch(showNotification('Automatischer Login erfolgreich','info'))
      })
      .catch(error => {
        dispatch(restoreSessionFail(error))
      })
  }
}

export const restoreSessionStart = () => {
  return {
    type: actionTypes.RESTORE_SESSION_START,
  }
}

export const restoreSessionSuccess = (res) => {
  return {
    type: actionTypes.RESTORE_SESSION_SUCCESS,
    res: res,
  }
}

export const restoreSessionFail = (error) => {
  return {
    type: actionTypes.RESTORE_SESSION_FAIL,
    error: error,
  }
}


/*
 *   RESET PASSWORD
 */

export const resetPassword = (payload) => {
  return dispatch => {
    dispatch(resetPasswordStart())
    axios.post('/customer/password/reset', payload)
      .then ( response => {
        dispatch(showNotification('Neues Passwort angefordert'))
        dispatch(resetPasswordSuccess(response.data))
      })
      .catch(error => {
        dispatch(showNotification('Passwort Reset fehlgeschlagen', 'error'))
        dispatch(resetPasswordFail(error))
      })
  }
}

export const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  }
}

export const resetPasswordSuccess = (res) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    res: res,
  }
}

export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error: error,
  }
}


/*
 *   PUT PASSWORD
 */

export const putPassword = (payload) => {
  return dispatch => {
    dispatch(putPasswordStart())
    axios.put('/customer/password/update', payload)
      .then ( response => {
        dispatch(showNotification('Passwort aktualisiert'))
        dispatch(putPasswordSuccess(response.data))
      })
      .catch(error => {
        dispatch(showNotification('Passwortänderung fehlgeschlagen', 'error'))
        dispatch(putPasswordFail(error))
      })
  }
}

export const putPasswordStart = () => {
  return {
    type: actionTypes.PUT_PASSWORD_START,
  }
}

export const putPasswordSuccess = (res) => {
  return {
    type: actionTypes.PUT_PASSWORD_SUCCESS,
    res: res,
  }
}

export const putPasswordFail = (error) => {
  return {
    type: actionTypes.PUT_PASSWORD_FAIL,
    error: error,
  }
}
