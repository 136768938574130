import RatingStars from "./RatingStars";
import {subscriptionStatusLabel} from "../functions/enum";
import {formatDateWithMonth} from "../functions/dateHelper";
import React from "react";
import BusinessSubscriptionHelp from "./BusinessSubscriptionHelp";


const SubscriptionDetailsBox = (props) => {

  /* basic subscription attributes */
  const subscriptionID = props?.subscription?.subscriptionID
  const subscriptionData = props?.subscription?.subscriptionData
  const subscriptionEvaluation = props?.subscription?.subscriptionEvaluation
  //const nightsPerMonth =  subscriptionData?.BookingOffer?.BookingSubscriptionCategory?.nightsPerMonth || 0
  //const pricePerMonth = subscriptionData?.BookingOffer?.price * nightsPerMonth || 0
  const subscriptionRoom = subscriptionData?.BookingRoom
  const subscriptionHotel = subscriptionData?.BookingHotel
  //const subscriptionDuration = subscriptionData?.BookingOffer?.BookingSubscriptionDuration?.months
  const subscriptionStartDate = subscriptionData?.startDate
  const subscriptionEndDate = subscriptionData?.endDate
  const subscriptionSegments = subscriptionData?.BookingSubscriptionSegments || []
  const currentSubscriptionMonthFrom = subscriptionEvaluation?.filter(sub => sub.label === 'active')?.[0]?.dateFrom
  const currentSubscriptionMonthTo = subscriptionEvaluation?.filter(sub => sub.label === 'active')?.[0]?.dateTo
  const isActive = props.isActive
  const product = subscriptionData?.product

  /* attributes for __active__ subscription */
  //const hotelDetail = useSelector(state => state.offer.hotelDetail)
  //const subscriptionRoomDetails = hotelDetail?.BookingRooms?.filter(room => (subscriptionRoom === room.BookingRoomCategory.shortcode))[0] || {}
  let mediaUrl = '/hotel-room-icon-03.png'
  if (Array.isArray(subscriptionHotel.BookingMedia) && subscriptionHotel.BookingMedia.length) {
    const mediaUuid = subscriptionHotel.BookingMedia.filter(picture => picture.position === '1')[0]?.uuid || subscriptionHotel.BookingMedia[0]?.uuid
    if(mediaUuid) mediaUrl = '/api/booking/media/'+mediaUuid
  }

  const boxStyle = isActive
    ? 'rounded-lg shadow-lg border-2 border-gray-300 bg-gray-50 text-gray-900 mb-4 p-3 sm:mx-6'
    : 'rounded-lg shadow-lg border-2 border-gray-300 bg-gray-100 text-gray-500 mb-4 p-3 sm:mx-6'
  const statusStyle = isActive ? 'text-green-600 font-regular' : 'text-orange-700 font-regular'

  return(
    <div className={boxStyle} key={subscriptionID+'_detail'}>

      <div className="text-2xl font-light mb-3">Hotel-Abo im <span className="font-semibold">{subscriptionHotel.name}</span></div>

      <div className="flex flex-wrap border-b border-gray-300 pb-2">
        <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
          Status
        </div>
        <div className="w-full md:w-9/12 pt-3 text-sm">
          <div className={statusStyle}>{subscriptionStatusLabel[subscriptionData?.status]}</div>
          <div className="font-light mt-2">Startdatum: &nbsp; {formatDateWithMonth(subscriptionStartDate)}</div>
          <div className="font-light">Enddatum: &nbsp; {subscriptionEndDate ? formatDateWithMonth(subscriptionEndDate) : '—'}</div>
        </div>
      </div>

      <div className="flex flex-wrap border-b border-gray-300 pb-2">
        <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
          Abo-ID
        </div>
        <div className="w-full md:w-9/12 pt-3 text-sm">
          <div className="font-light">{subscriptionID}</div>
        </div>
      </div>

      <div className="flex flex-wrap border-b border-gray-300 pb-2">
        <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
          Hotel
        </div>
        <div className="w-full md:w-9/12 pt-3 text-sm">
          <div
            className="text-xs text-gray-500 font-light mb-1">{subscriptionHotel.addressCity} - {subscriptionHotel.addressDistrict}</div>
          <div className="flex mt-3">
            <div className="font-regular mr-1">{subscriptionHotel.name}</div>
            <RatingStars rating={subscriptionHotel.ratingStars || 0}/>
          </div>
          <div className="mb-2">
            <p className="font-light">{subscriptionHotel.addressStreet}</p>
            <p className="font-light">{subscriptionHotel.addressZip} {subscriptionHotel.addressCity}</p>
          </div>

          <div className="mt-2 flex flex-wrap">
            {subscriptionHotel.BookingHotelAttributes ? subscriptionHotel.BookingHotelAttributes.map(x => (x?.BookingHotel_bookingHotelAttribute?.category === 'super') ?
              <div key={x.uuid}>
                <div className="flex items-center text-sm">
                  <img src={'/api/booking/media/' + x.uuid} alt={x.name} className="pt-1 w-6 h-7 mr-1"/>
                  <div className="font-light mr-4">{x.name}</div>
                </div>
              </div> : ''
            ) : ''}
          </div>

          <div
            className="my-2 h-28 w-44 bg-cover rounded text-center overflow-hidden"
            style={{backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
            title={subscriptionHotel.name}
          >
          </div>

        </div>
      </div>

      <div className="flex flex-wrap border-b border-gray-300 pb-2">
        <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
          Zimmerkategorie
        </div>
        <div className="w-full md:w-9/12 pt-3 text-sm">
          <div className="font-regular">{subscriptionRoom.name}</div>
          <div className="text-sm font-light mt-2 resetList" dangerouslySetInnerHTML={{__html: subscriptionRoom.description}} />
        </div>
      </div>

      <div className="flex flex-wrap border-b border-gray-300 pb-2">
        <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
          Nächte &amp; Preis
        </div>
        <div className="w-full md:w-9/12 pt-3 text-sm">
          <ul className="font-light">
            {subscriptionSegments.map(seg => {
              if(seg.segmentType === 'subscription') {
                return(
                  <li key={seg.segmentID}>
                    <span className="font-normal">{seg?.endDate ? '' : 'Ab '} {formatDateWithMonth(seg?.startDate)} {seg?.endDate ? ' - '+formatDateWithMonth(seg?.endDate) : ''}</span>
                    <br/> {seg?.offerNights} Nächte / Monat
                    &nbsp;&nbsp; für &nbsp;&nbsp;  {(seg?.pricePerMonth || 0).toFixed(2).replace('.',',')} € / Monat
                    &nbsp; ({(seg?.price || 0).toFixed(2).replace('.',',')} € / Nacht)
                    <br /> Laufzeit {seg?.offerDuration} Monat{ seg?.offerDuration > 1 ? 'e' : ''}
                  </li>
                )
              }
              else if(seg.segmentType === 'breakMonth' || seg.segmentType === 'breakCustom') {
                return(
                  <li key={seg.segmentID}>
                    <span className="font-normal">{seg?.endDate ? '' : 'Ab '} {formatDateWithMonth(seg?.startDate)} {seg?.endDate ? ' - '+formatDateWithMonth(seg?.endDate) : ''}</span>
                    <br/> Pause
                  </li>
                )
              }
              else {
                return ('')
              }
            })}
          </ul>
          {isActive || subscriptionData?.status === 'paused'
            ? <form action="/api/customer/payment/update" method="POST">
              <button
                className="border border-primary bg-blue-50 hover:bg-blue-100 rounded-md p-2 my-1"
                type="submit"
              >
                Zahlungsmethode anpassen
              </button>
            </form>
            : ''}
        </div>
      </div>

      {isActive && currentSubscriptionMonthFrom && currentSubscriptionMonthTo
        ? <div className="flex flex-wrap border-b border-gray-300 pb-2">
          <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
            Laufzeit
          </div>
          <div className="w-full md:w-9/12 pt-3 text-sm">
            <div className="font-light mt-2">Dein aktueller Abo-Monat läuft
              von {formatDateWithMonth(currentSubscriptionMonthFrom)} bis {formatDateWithMonth(currentSubscriptionMonthTo)}.
            </div>
            <div className="font-light">Dein Abo verlängert sich automatisch, wenn du nicht spätestens einen Tag vor
              Ende der Laufzeit kündigst.
            </div>
          </div>
        </div>
        : ''}

      <div className="flex flex-wrap">
        <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
          Konditionen
        </div>
        <div className="w-full md:w-9/12 pt-3 text-sm">
          <div className="font-light">MyFlexHome Abo-Konditionen in deinem Tarif:</div>
          <div className="text-sm font-light mt-2 resetList" dangerouslySetInnerHTML={{__html: subscriptionHotel.subscriptionTerms}} />
          <div>{product === 'business'
            ? <BusinessSubscriptionHelp help={false}/>
            : ''
          }</div>
        </div>
      </div>

    </div>

  )
}

export default SubscriptionDetailsBox