import StepperBar from '../components/StepperBar';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import RatingStars from '../components/RatingStars';
import {Box, Checkbox, FormControlLabel, TextField} from '@mui/material';
import {formatDateWithMonth} from "../functions/dateHelper";
import {Controller, useForm} from 'react-hook-form';
import * as actions from "../store/actions";
import Tippy from "@tippyjs/react";
import BusinessSubscriptionHelp from "../components/BusinessSubscriptionHelp";

const Account = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  /* redux states */
  const hotelDetail = useSelector(state => state.offer.hotelDetail)
  const subscriptionCategory = useSelector(state => state.offer.subscriptionCategory)
  const subscriptionRoom = useSelector(state => state.offer.subscriptionRoom)
  const subscriptionRoomDetails = hotelDetail?.BookingRooms?.filter(room => (subscriptionRoom === room.BookingRoomCategory.shortcode))[0] || {}
  const subscriptionDuration = useSelector(state => state.offer.subscriptionDuration)
  const subscriptionStartDate = useSelector(state => state.offer.subscriptionStartDate)
  const pricePerMonth = useSelector(state => state.offer.pricePerMonth)
  const pricePerNight = useSelector(state => state.offer.pricePerNight)
  const pricePerMonthWithDiscount = useSelector(state => state.offer.pricePerMonthWithDiscount) || null
  const pricePerNightWithDiscount = useSelector(state => state.offer.pricePerNightWithDiscount) || null
  const discountPerMonth = useSelector(state => state.offer.discountPerMonth) || null
  const offerDiscount = useSelector(state => state.offer?.BookingOfferDiscount) || null
  const product = useSelector(state => state.offer.product)

  /* check for preconditions, otherwise start from scratch */
  useEffect(() => {
    if (pricePerNight === 0) {
      history.push('/search/muenchen/?nights=4')
    }
  }, [history, pricePerMonth, pricePerNight])

  let mediaUrl = '/hotel-room-icon-03.png'
  if (Array.isArray(hotelDetail.BookingMedia) && hotelDetail.BookingMedia.length) {
    const mediaUuid = hotelDetail.BookingMedia.filter(picture => picture.position === '1')[0]?.uuid || hotelDetail.BookingMedia[0]?.uuid
    if(mediaUuid) mediaUrl = '/api/booking/media/'+mediaUuid
  }

  /* react hook form */
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
      privacy: false,
      mailNotification: false,
    }
  })
  const onSubmit = data => {
    const payload = {
      ...data,
      registrationSource: 'bookingJourney/'+hotelDetail?.hotelId || 'bookingJourney',
      product: product,
    }
    dispatch(actions.postAccount(payload))
  }

  return (
    <div className="container max-w-screen-lg mx-auto mb-10">
        <div className="mx-3 md:w-10/12 md:mx-auto">
          <StepperBar active={2} />

          <div className="text-center text-3xl font-light mt-10">Deine Abo-Auswahl</div>

          <div className="rounded-lg shadow-lg border border-green-500 bg-green-50 p-3 my-4 flex flex-wrap items-center">

            <div
              className="w-full h-32 md:h-48 md:w-4/12 bg-cover rounded text-center overflow-hidden"
              style={{backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover',  backgroundPosition: 'center'}}
              title={hotelDetail.name}>
              <div className=""> </div>
            </div>

            <div className="w-full md:w-5/12 py-3 md:pl-3">
              <div className="text-xs text-gray-500 font-light mb-1">{hotelDetail.addressCity} - {hotelDetail.addressDistrict}</div>
              <div className="flex">
                <div className="text-xl font-regular mr-1">{hotelDetail.name}</div>
                <RatingStars rating={hotelDetail.ratingStars || 0} />
              </div>
              <div className="text-md font-regular">{subscriptionRoomDetails.name} Zimmer</div>
              <div className="text-sm font-light resetList" dangerouslySetInnerHTML={{__html: subscriptionRoomDetails.description}} />
              <div className="mt-2 flex flex-wrap">
                {hotelDetail.BookingHotelAttributes
                  ? hotelDetail.BookingHotelAttributes.map(x => (x.category === 'super')
                    ? <div key={x.uuid}>
                        <div className="flex items-center text-sm" key={x.uuid}>
                          <img src={'/api/booking/media/'+x.uuid} alt={x.name} className="pt-1 w-6 h-7 mr-1"/>
                          <div className="font-light mr-4">{x.name}</div>
                        </div>
                      </div>
                    : '')
                  : ''}
              </div>

              {product === 'business'
                ? <BusinessSubscriptionHelp help={false}/>
                : ''
              }
            </div>

            <div className="w-full md:w-3/12 pt-3 flex flex-wrap content-center md:justify-end">
              <div className="md:text-right">
                {offerDiscount?.name
                  ? <div className="mb-1">
                      <Tippy theme="default" content={
                        <div className="">{offerDiscount?.description}</div>
                      }>
                        <span className="text-white bg-discount text-xs rounded-lg px-3 py-1 text-center">{offerDiscount?.name}</span>
                      </Tippy>
                      <div className="text-xs text-discount mt-1 mb-2 lg:hidden">{offerDiscount?.description}</div>
                    </div>
                  : '' }
                <div className="text-lg font-bold">
                  { discountPerMonth > 0
                    ? <>
                      <span className="text-red-700 tracking-tight line-through text-lg font-bold mr-2">
                        { pricePerMonth?.toFixed(0) } €
                      </span>
                      <span>
                        { pricePerMonthWithDiscount?.toFixed(0) } € <span className="text-lg font-light">/ Monat</span>
                      </span>
                    </>
                    : <>
                        <span>
                          { pricePerMonth?.toFixed(0) } € <span className="text-lg font-light">/ Monat</span>
                        </span>
                      </>
                  }
                </div>
                <div className="text-xs font-light">im {subscriptionCategory}-Nächte Abo</div>
                <div className="text-sm font-regular text-secondaryGray mt-3 font-light">
                  { discountPerMonth > 0
                    ? <>
                        <span className="tracking-tight text-red-700 line-through">
                          { pricePerNight?.toFixed(0) } €
                        </span>
                        <span className="ml-1">
                          { pricePerNightWithDiscount?.toFixed(0) } € / Nacht
                        </span>
                      </>
                    : <>
                        <span className="">
                          { pricePerNight?.toFixed(0) } € / Nacht
                        </span>
                      </>
                  }
                </div>

                <div className="text-sm font-light mt-4">Laufzeit: {subscriptionDuration} Monat{ subscriptionDuration > 1 ? 'e' : ''}</div>
                <div className="text-sm font-light">Beginn: {formatDateWithMonth(subscriptionStartDate)}</div>
              </div>
            </div>

          </div>

          <div className="text-center text-3xl font-light mt-10">Benutzerkonto anlegen</div>
          {product === 'single'
          ? <div className="text-center text-md font-light mt-2">Damit kannst du später dein Abo verwalten und
              mit einem Klick Nächte buchen oder stornieren.</div>
          :''}
          {product === 'business'
          ? <div className="text-center text-md font-light mt-2">Als Business Admin kannst du weitere Personen zu deinem Account einladen, oder auch direkt für andere buchen.</div>
          :''}


          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto p-4 rounded-lg sm:w-112">
              <div>

                <Box
                  sx={{
                    textAlign: 'center',
                    '& .MuiTextField-root': {m:1, p:0, width:'90%'},
                  }}
                >
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine E-Mail-Adresse an',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Bitte gib eine gültige E-Mail-Adresse an"
                      }
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="E-Mail"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'Bitte wähle ein Passwort',
                      minLength: {
                        value: 8,
                        message: 'Bitte wähle ein sicheres Passwort >= 8 Zeichen',
                      },
                      maxLength: 99,
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Passwort"
                        type="password"
                        autoComplete="new-password"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <div className="text-left mx-6">
                    <Controller
                      name="privacy"
                      control={control}
                      rules={{
                        required: 'Bitte akzeptiere die AGB und Datenschutzerklräung',
                      }}
                      render={({ field,fieldState}) =>  (
                        <FormControlLabel
                          control={<Checkbox
                            {...field}
                            checked={field.value}
                            sx={fieldState.error ? {color: 'rgb(185 28 28)'} : {}}
                          />}
                          label={
                            <div className={fieldState.error ? 'font-light text-sm my-3 text-red-700' : 'font-light text-sm my-3'}>Hiermit akzeptiere ich
                              die <a href="https://www.myflexhome.de/agb/" target="_blank" rel="noreferrer" className="underline text-secondaryBlue">AGB</a> und
                              stimme der Verarbeitung meiner Daten entsprechend
                              der <a href="https://www.myflexhome.de/datenschutz/" target="_blank" rel="noreferrer" className="underline text-secondaryBlue">Datenschutzerklärung</a> zu.
                            </div>
                          }
                        />

                      )}
                    />
                    <Controller
                      name="mailNotification"
                      control={control}
                      render={({ field,fieldState}) =>  (
                        <FormControlLabel
                          control={<Checkbox
                            {...field}
                            checked={field.value}
                          />}
                          label={
                            <div className="font-light text-sm my-3">Ich möchte über Neuigkeiten, Angebote
                              und Rabatte informiert werden.</div>
                          }
                        />

                      )}
                    />
                  </div>

                </Box>
              </div>

            </div>
            <div className="text-center mx-auto mt-2">
              <button
                type="submit"
                className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
              >
                Jetzt Registrieren
              </button>
            </div>
          </form>

          <div className="text-center mx-auto my-6 text-sm text-gray-500">
            Schon registriert? <button onClick={() => {history.push('/login/?target=/account-details/'); window.scrollTo(0, 0)}} className="underline">Direkt zum Login</button>
          </div>

        </div>
    </div>
  )
}

export default Account
