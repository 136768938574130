import React from 'react'
import {ArrowsUpDownIcon} from '@heroicons/react/24/solid'
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import ListBoxDefault from "./ListBoxDefault";

const SearchSortDropDown = () => {

  const dispatch = useDispatch()

  const sortList = useSelector(state => state.search.sortList)
  const inputSort = useSelector(state => state.search.inputSort)

  const handleSortChange = (value) => {
    dispatch(actions.updateInputSort(value))
    dispatch(actions.updateSearchSort(value))
    dispatch(actions.setSearchUrl())
  }

  return (
    <div className="mx-3 rounded-md border border-gray-200 shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
      <ListBoxDefault data={sortList} value={inputSort} setValue={handleSortChange} font={'font-medium'} icon={<ArrowsUpDownIcon className="w-5 h-5 ml-2" />} />
    </div>
  )
}

export default SearchSortDropDown