import * as actionTypes from './actionTypes'

export const moveMapHotelDetail = (viewport) => {
  return dispatch => {
    dispatch({
      type: actionTypes.MOVE_MAP_HOTEL_DETAIL,
      res: viewport,
    })
  }
}

export const moveMapSearch = (viewport) => {
  return dispatch => {
    dispatch({
      type: actionTypes.MOVE_MAP_SEARCH,
      res: viewport,
    })
  }
}