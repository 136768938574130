
export const CheckIn = () => {
    return(
        <svg height="22" width="22" viewBox="0 0 512 512">
        <g id="carrier">
            <path fill="currentColor" d="M282.647,401.156h-18.377v-16.602c0-8.621-7.014-15.634-15.634-15.634h-30.128c-8.621,0-15.634,7.014-15.634,15.634 v16.602h-18.377c-6.548,0-11.856,5.308-11.856,11.856v66.79c0,6.548,5.308,11.856,11.856,11.856h98.152 c6.548,0,11.856-5.308,11.856-11.856v-66.79C294.503,406.464,289.195,401.156,282.647,401.156z M248.454,401.156h-29.769v-16.422 h29.769V401.156z"></path>
            <path fill="currentColor" d="M289.314,140.545l-20.794-6.37c-3.985-1.221-8.204,1.02-9.425,5.005l-6.376,20.814c1.346-0.151,2.699-0.237,4.046-0.237 c12.198,0.001,23.174,6.084,29.776,15.603l7.778-25.39C295.539,145.986,293.299,141.766,289.314,140.545z"></path>
            <path fill="currentColor" d="M429.545,79.613c-8.048-4.752-15.512-10.667-34.927-10.667c-19.03,0-26.664,5.793-34.416,10.216 c-1.424,20.339,14.678,37.217,34.654,37.217C414.708,116.378,430.692,99.722,429.545,79.613z"></path>
            <path fill="currentColor" d="M479.402,166.936c-0.111-21.769-17.911-39.481-39.68-39.481h-17.186c-17.094,44.657-10.921,28.532-20.785,54.302 l4.901-23.086c0.344-1.622,0.094-3.313-0.705-4.764l-6.73-12.24l5.983-10.881c0.414-0.755,0.401-1.672-0.038-2.413 c-0.438-0.742-1.235-1.196-2.096-1.196h-16.421c-0.861,0-1.659,0.455-2.096,1.196s-0.452,1.658-0.038,2.413l5.983,10.881 l-6.755,12.285c-1.576,2.867-1.117,4.235,3.563,27.805c-10.506-26.946-6.409-16.775-20.571-54.302h-17.025 c-21.769,0-39.57,17.711-39.68,39.481l-0.295,58.728h33.536l0.294-58.557c0.009-1.785,1.461-3.225,3.245-3.221 c1.785,0.004,3.229,1.453,3.229,3.238v58.543h88.814v-58.58c0-1.938,1.57-3.508,3.508-3.509c1.938,0,3.509,1.57,3.509,3.508 c0,0.007,0,0.013,0,0.02l0.294,58.561h33.536L479.402,166.936z M394.643,211.615c-3.238,0-5.968-2.63-5.968-5.968 c0-3.323,2.718-5.968,5.968-5.968c3.142,0,5.968,2.583,5.968,5.968C400.611,209.052,397.769,211.615,394.643,211.615z"></path>
            <path fill="currentColor" d="M494.55,246.606H158.925v34.901H494.55c9.637,0,17.45-7.813,17.45-17.45C512,254.418,504.188,246.606,494.55,246.606z"></path>
            <path fill="currentColor" d="M274.67,192.728c-1.818-9.895-11.317-16.453-21.219-14.634l-52.703,9.681l-28.9-50.935 c-5.784-10.194-16.657-16.527-28.379-16.527c-9.823,0-89.757,0-99.668,0c-23.663,0-43.012,19.251-43.13,42.913L0,297.121 c-0.051,10.066,8.068,18.266,18.134,18.317c0.031,0,0.063,0,0.094,0c10.022,0,18.173-8.099,18.223-18.133l0.673-133.897 c0-0.001,0-0.001,0-0.002c0.011-1.994,1.633-3.602,3.626-3.596c1.994,0.006,3.605,1.624,3.606,3.616 c0.001,45.529,0.008,306.36,0.008,306.36c0,12.078,9.792,21.871,21.871,21.871c12.078,0,21.871-9.792,21.871-21.871V294.972h9.443 v174.815c0,12.078,9.792,21.871,21.871,21.871s21.871-9.792,21.871-21.871c-0.001-287.197-0.394-127.019-0.399-309.598 c0-0.413,0.278-0.775,0.677-0.881c0.4-0.106,0.821,0.068,1.026,0.427l32.846,57.3c3.84,6.768,11.536,10.331,19.145,8.932 l65.451-12.022C269.937,212.126,276.487,202.628,274.67,192.728z"></path>
            <path fill="currentColor" d="M92.822,32.725c-20.862,0-37.773,16.912-37.773,37.773c0,20.847,16.929,37.773,37.773,37.773 c20.884,0,37.773-16.931,37.773-37.773C130.595,49.757,113.804,32.725,92.822,32.725z"></path>
            <path fill="currentColor" d="M423.462,20.343h-57.211c-2.547,0-4.611,2.065-4.611,4.611v38.214c7.934-4.526,19.76-7.402,32.979-7.402 c13.482,0,25.522,2.99,33.455,7.673V24.954C428.073,22.406,426.008,20.343,423.462,20.343z"></path>
        </g></svg>
    )
}
