import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../store/actions';
import {ButtonBack} from '../components/Buttons';
import SubscriptionDetailsBox from '../components/SubscriptionDetailsBox';
import {Disclosure} from '@headlessui/react'
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {subscriptionStatusLabel} from "../functions/enum";
import {formatDate} from "../functions/dateHelper";
import placeholder from '../assets/images/dashboard_subscription_placeholder.webp';
import {Link} from "react-router-dom";
import LoadingSpinner from "../assets/svg/LoadingSpinner";

const CustomerSubscription = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getCustomerSubscriptions())
  }, [dispatch])

  /* redux states */
  const subscriptions = useSelector(state => state.customer.subscriptions)
  const subscriptionsLoading = useSelector(state => state.customer.subscriptionsLoading)
  const profile = useSelector(state => state.customer.profile)

  const userProduct = profile?.userProduct
  const findNewHotelUrl = '/search/muenchen/' + (userProduct === 'business' ? '?nights=10&product=business' : '?nights=4')

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack />

        <div className="text-center text-3xl font-light mt-8">Abo</div>
        <div className="text-center text-md font-light mt-2">Dein Hotel-Abo im Blick</div>

        {subscriptionsLoading
          ? <div className="flex justify-center mt-4"><LoadingSpinner /></div>
          : subscriptions.length > 0
            ? <div>
              <div className="rounded-2xl bg-white py-2 max-w-screen-md mx-auto">
                {subscriptions.map(sub => {
                  const hotelName = sub.subscriptionData?.BookingHotel?.name
                  const startDate = sub.subscriptionData?.startDate
                  const endDate = sub.subscriptionData?.endDate
                  const status = subscriptionStatusLabel[sub.subscriptionData?.status]
                  const isActive = ['upcoming','active', 'cancelled'].includes(sub.subscriptionData?.status)
                  const rowStyle = isActive ? 'flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium bg-green-100 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75' : 'flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium bg-gray-100 text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75'

                  return(
                    <Disclosure as="div" className="mt-2" key={sub.subscriptionID} defaultOpen={isActive}>
                      {({open}) => (
                        <>
                          <Disclosure.Button
                            className={rowStyle}>
                            <span><b>{hotelName}</b>  — {endDate ? '' : 'Ab '}{formatDate(startDate)} {endDate ? `bis ${formatDate(endDate)}` : ''} — {status}</span>
                            <ChevronRightIcon
                              className={`${
                                open ? 'rotate-90 transform' : ''
                              } h-5 w-5 text-blue-900`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pt-2 pb-2 text-sm text-gray-500">
                            <SubscriptionDetailsBox subscription={sub} isActive={isActive} key={sub.subscriptionID+'_box'} />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                )}

              </div>

              <div className="p-6 text-left border border-gray-200 rounded-lg shadow-md bg-yellow-50 mt-12 mx-6">
                Du willst dein Abo pausieren oder kündigen? Nächsten Monat benötigst du ein kleineres oder größeres Abo-Paket?<br/>
                Diese Funktion befindet sich gerade noch im Aufbau.<br/>
                Schreibe uns einfach eine formlose E-Mail mit Angabe deiner Abo-ID
                an <a className="underline" href={'mailto:hello@myflexhome.de?subject=MyFlexHome Abo'}>hello@myflexhome.de</a>
              </div>
            </div>

            : <div>
              <div className="mt-8 flex justify-center">
                <div className="p-6 border border-gray-200 rounded-lg shadow-md bg-yellow-50 mx-6 text-center">
                  Aktuell hast du kein aktives Abo.<br/>
                  <Link to={findNewHotelUrl} className="underline text-secondaryBlue">Finde das passende Hotel</Link> und wähle dein Abo-Paket.
                </div>
              </div>
              <div className="mt-20 flex flex-col justify-center">
                <div className="mx-auto font-bold text-gray-400">Beispielansicht Abo-Verwaltung</div>
                <div className="mx-auto pt-4 border-t border-gray-300"><img src={placeholder} alt="" style={{maxWidth: 600}}/></div>
              </div>
            </div>
        }

      </div>

    </div>
  )
}

export default CustomerSubscription
