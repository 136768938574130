import * as actionTypes from './actionTypes'
import axios from "../../axios.config";
import {history} from '../../index';
import {showNotification} from "./mainWindow";
import {getCustomerProfile} from "./customer";

/*
 * UPDATE offer selection: subscription category (#nights)
 */
export const updateOfferSelectionSubscriptionCategory = (category) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_CATEGORY,
      res: Number(category),
    })
    dispatch({ type: actionTypes.UPDATE_OFFER_PRICE })
    dispatch({ type: actionTypes.UPDATE_OFFER_PRICE_ANIMATION })
  }
}


/*
 * UPDATE offer selection: room
 */
export const updateOfferSelectionSubscriptionRoom = (room) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_ROOM,
      res: room,
    })
    dispatch({ type: actionTypes.UPDATE_OFFER_PRICE })
    dispatch({ type: actionTypes.UPDATE_OFFER_PRICE_ANIMATION })
  }
}


/*
 * UPDATE offer selection: subscription duration (#months)
 */
export const updateOfferSelectionSubscriptionDuration = (duration) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_DURATION,
      res: duration,
    })
    dispatch({ type: actionTypes.UPDATE_OFFER_PRICE })
    dispatch({ type: actionTypes.UPDATE_OFFER_PRICE_ANIMATION })
  }
}


/*
 * UPDATE offer price. Recalculation only, no animation
 */
export const updateOfferPrice = () => {
  return dispatch => {
    dispatch({ type: actionTypes.UPDATE_OFFER_PRICE })
  }
}


/*
 * UPDATE new subscription: start date
 */
export const updateOfferSelectionSubscriptionStartDate = (date) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_START_DATE,
      res: date,
    })
  }
}


/*
 * UPDATE new subscription: selected nights (array)
 */
export const updateOfferSelectionNights = (nights) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_OFFER_SELECTION_NIGHTS,
      res: nights,
    })
  }
}


/*
 * POST account (first account creation with email and password)
 */
export const postAccount = (payload, successTargetUrl=null) => {

  return dispatch => {
    dispatch(postAccountStart())
    axios.post('/booking/account/new', payload)
      .then((response) => {
        if(response.status === 200) {
          dispatch(postAccountSuccess(response.data))
          dispatch(getCustomerProfile())
          dispatch(showNotification('Account erfolgreich angelegt'),'success')
          window.scrollTo(0, 0)
          if(successTargetUrl) {
            history.push(successTargetUrl)
          } else {
            history.push('/account-details/')
          }
        }
        if(response.status === 202) {
          dispatch(showNotification(response?.data?.message || 'Fehler','error'))
          dispatch(postAccountFail(response?.data?.message || 'Fehler'))
        }
      })
      .catch((error) => {
        const message = 'Leider ist ein Fehler aufgetreten.'
        dispatch(showNotification(message,'error'))
        dispatch(postAccountFail(error))
      })
  }
}

const postAccountStart = () => {
  return {
    type: actionTypes.POST_ACCOUNT_START,
  }
}

const postAccountSuccess = (res) => {
  return {
    type: actionTypes.POST_ACCOUNT_SUCCESS,
    res: res,
  }
}

const postAccountFail = (res, error) => {
  return {
    type: actionTypes.POST_ACCOUNT_FAIL,
    res: res,
    error: error,
  }
}



/*
 * PUT account (update account with further details like address)
 */
export const putAccount = (payload) => {

  return dispatch => {
    dispatch(putAccountStart())
    axios.put('/booking/account/new', payload)
      .then (response => {
        dispatch(putAccountSuccess(response.data))
        dispatch(getCustomerProfile())
        window.scrollTo(0, 0)
        history.push('/summary/')
      })
      .catch((res, error) => {
        const message = res?.response?.data?.message || 'Leider ist ein Fehler aufgetreten.'
        dispatch(showNotification(message,'error'))
        dispatch(putAccountFail(error))
      })
  }
}

const putAccountStart = () => {
  return {
    type: actionTypes.PUT_ACCOUNT_START,
  }
}

const putAccountSuccess = (res) => {
  return {
    type: actionTypes.PUT_ACCOUNT_SUCCESS,
    res: res,
  }
}

const putAccountFail = (res, error) => {
  return {
    type: actionTypes.PUT_ACCOUNT_FAIL,
    res: res,
    error: error,
  }
}



/*
 * POST updateCheckoutCart (post all shopping cart content to server session to keep state during stripe checkout)
 */
export const putCheckoutUpdateCart = (payload) => {

  return (dispatch, getState) => {
    dispatch(putCheckoutUpdateCartStart())
    axios.put('/booking/checkout/updateCart', payload)
      .then (response => {
        dispatch(putCheckoutUpdateCartSuccess(response.data))
        window.scrollTo(0, 0)
        if(getState().login.user?.email === '' || getState().customer?.profile?.accountType === 'businessUser') {
          history.push('/account/')
        } else {
          if(getState().login.user?.firstName?.length > 0) {
            history.push('/summary/')
          } else {
            history.push('/account-details/')
          }
        }

      })
      .catch((res, error) => {
        dispatch(showNotification('Leider ist ein Fehler aufgetreten.','error'))
        dispatch(putCheckoutUpdateCartFail(error))
      })
  }
}

const putCheckoutUpdateCartStart = () => {
  return {
    type: actionTypes.PUT_CHECKOUT_UPDATE_CART_START,
  }
}

const putCheckoutUpdateCartSuccess = (res) => {
  return {
    type: actionTypes.PUT_CHECKOUT_UPDATE_CART_SUCCESS,
    res: res,
  }
}

const putCheckoutUpdateCartFail = (res, error) => {
  return {
    type: actionTypes.PUT_CHECKOUT_UPDATE_CART_FAIL,
    res: res,
    error: error,
  }
}


/*
 * POST checkoutFinalize (post all shopping cart content to server session to keep state during stripe checkout)
 */
export const postCheckoutFinalize = (payload) => {

  return dispatch => {
    dispatch(postCheckoutFinalizeStart())
    axios.post('/booking/checkout/finalize', payload)
      .then (response => {
        dispatch(postCheckoutFinalizeSuccess(response.data))
        //window.scrollTo(0, 0)
        //history.push('/summary/')
      })
      .catch((res, error) => {
        dispatch(showNotification('Leider ist ein Fehler aufgetreten.','error'))
        dispatch(postCheckoutFinalizeFail(error))
      })
  }
}

const postCheckoutFinalizeStart = () => {
  return {
    type: actionTypes.POST_CHECKOUT_FINALIZE_START,
  }
}

const postCheckoutFinalizeSuccess = (res) => {
  return {
    type: actionTypes.POST_CHECKOUT_FINALIZE_SUCCESS,
    res: res,
  }
}

const postCheckoutFinalizeFail = (res, error) => {
  return {
    type: actionTypes.POST_CHECKOUT_FINALIZE_FAIL,
    res: res,
    error: error,
  }
}
