import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {ConnectedRouter, connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import './css/index.css';
import App from './App';
import TagManager from 'react-gtm-module'
import searchReducer from './store/reducers/search';
import offerReducer from './store/reducers/offer';
import mapReducer from './store/reducers/map';
import mainWindowReducer from './store/reducers/mainWindow';
import loginReducer from './store/reducers/login';
import customerReducer from './store/reducers/customer';
import businessReducer from './store/reducers/business';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  search: searchReducer,
  offer: offerReducer,
  map: mapReducer,
  mainWindow: mainWindowReducer,
  login: loginReducer,
  customer: customerReducer,
  business: businessReducer,
})

export const history = createBrowserHistory()

const store = createStore(rootReducer(history), composeEnhancers(
  applyMiddleware(
    routerMiddleware(history),
    thunk
  )
))

const tagManagerArgs = {
  gtmId: 'GTM-NDRW8J6'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App history={history}/>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorkerRegistration.register()
//serviceWorkerRegistration.registerLocalServiceWorker()
