import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import {useHistory} from "react-router";
import {Controller, useForm} from 'react-hook-form';
import {Box, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {ButtonBack} from "../components/Buttons";
import {Disclosure} from "@headlessui/react";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {Autocomplete} from '@mui/material';
import countryList from "../assets/countryList.json";

const CustomerProfile = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const profile = useSelector(state => state.customer.profile)
  const userProduct = profile?.userProduct

  useEffect(() => {
  }, [])

  /* react hook form */
  const { control, handleSubmit } = useForm({
    defaultValues: {
      ...profile,
      company: profile?.BusinessCompany?.name || '',
      addressCountry: profile?.addressCountry || 'Deutschland',
    }
  })
  const onSubmit = data => {
    dispatch(actions.putCustomerProfile(data))
    //console.log(data)
  }
  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack />

        <div className="text-center text-3xl font-light mt-8">Account</div>
        <div className="text-center text-md font-light mt-2">Kundenprofil und Einstellungen</div>


        <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
          <div className="mx-auto p-4 rounded-lg sm:w-112">
            <div>

              <Box
                sx={{
                  textAlign: 'center',
                  '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                  '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                }}
              >
                <div className="">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Vornamen an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Vorname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Nachnamen an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Nachname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="company"
                    control={control}
                    rules={{
                      ...(userProduct === 'business' ? {
                        required: 'Bitte gib deinen Firmenname an',
                      } : null)
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label={userProduct === 'business' ? 'Firma' : 'Firma (optional)'}
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                </div>

                <div className="mt-4">
                  <Controller
                    name="addressZip"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine Postleitzahl an',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Bitte gib eine gültige Postleitzahl an",
                      }
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="PLZ"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="addressCity"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine Stadt an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Stadt"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="addressStreet"
                    control={control}
                    rules={{
                      required: 'Bitte gib deine Straße mit Hausnummer an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Straße mit HausNr"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="addressCountry"
                    control={control}
                    rules={{
                      required: 'Bitte gib dein Wohnsitz-Land an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <Autocomplete
                        {...field}
                        id="addressCountryAutocomplete"
                        options={countryList}
                        disableClearable={false}
                        getOptionLabel={(option) => option || ''}
                        value={field?.value}
                        onChange={(e, value) => {
                          field.onChange(value)
                        }}
                        autoHighlight={true} // required to select entry without submitting entire material-table row
                        onKeyDown={e => handleKey(e)}
                        isOptionEqualToValue={(option, value) => {
                          return option === value
                        }}
                        renderOption={(props, option) => {
                          return(
                            <li {...props}>
                              <div>{option || '---'}</div>
                            </li>
                          )
                        }}
                        size="small"
                        renderInput={(params) => {
                          params.InputProps.style = {}
                          return(
                            <TextField
                              {...params}
                              label="Land"
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                            />
                          )
                        }}
                      />
                    )}
                  />
                </div>

                <div className="mt-4">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Bitte gib eine E-Mail-Adresse an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="E-Mail"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="contactPhone"
                    control={control}
                    rules={{
                      required: 'Bitte gib eine Telefonnummer an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Telefon"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <div className="mt-4">
                  <Controller
                    name="mailNotification"
                    control={control}
                    render={({ field,fieldState}) =>  (
                      <FormControl fullWidth sx={{
                        textAlign: 'left',
                        '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                        '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                      }}>
                        <InputLabel id="newsletter-label">Newsletter</InputLabel>
                        <Select
                          labelId="newsletter-label"
                          label="Newsletter"
                          size="small"
                          value={field.value}
                          onChange={field.onChange}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={true}>Ja</MenuItem>
                          <MenuItem value={false}>Nein</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>

                <Disclosure as="div" className="mt-8" defaultOpen={profile.billingLastName || profile.billingCompany || profile.billingStreet}>
                  {({open}) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium bg-gray-100 text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
                        <div className="text-base">Abweichende Rechnungsadresse <span className="ml-4 font-light text-sm text-gray-500">(optional)</span></div>
                        <ChevronRightIcon
                          className={`${
                            open ? 'rotate-90 transform' : ''
                          } h-5 w-5 text-blue-900`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-4 pb-6 mt-1 border-4 rounded-lg border-gray-100">
                        <Box
                          sx={{
                            textAlign: 'center',
                            '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                            '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                          }}
                        >
                          <div className="text-sm font-light text-left px-5 mb-4">
                            Nenne uns die Person, Firma oder Adresse auf die deine digitale Rechnung ausgestellt werden soll.
                          </div>
                          <div>
                            <Controller
                              name="billingFirstName"
                              control={control}
                              render={({ field,fieldState}) =>  (
                                <TextField
                                  {...field}
                                  label="Vorname"
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  variant="outlined"
                                />
                              )}
                            />
                            <Controller
                              name="billingLastName"
                              control={control}
                              render={({ field,fieldState}) =>  (
                                <TextField
                                  {...field}
                                  label="Nachname"
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  variant="outlined"
                                />
                              )}
                            />
                            <Controller
                              name="billingCompany"
                              control={control}
                              render={({ field,fieldState}) =>  (
                                <TextField
                                  {...field}
                                  label="Firma"
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                          <div className="mt-4">
                            <Controller
                              name="billingZip"
                              control={control}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Bitte gib eine gültige Postleitzahl an",
                                }
                              }}
                              render={({ field,fieldState}) =>  (
                                <TextField
                                  {...field}
                                  label="PLZ"
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  variant="outlined"
                                />
                              )}
                            />
                            <Controller
                              name="billingCity"
                              control={control}
                              render={({ field,fieldState}) =>  (
                                <TextField
                                  {...field}
                                  label="Stadt"
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  variant="outlined"
                                />
                              )}
                            />
                            <Controller
                              name="billingStreet"
                              control={control}
                              render={({ field,fieldState}) =>  (
                                <TextField
                                  {...field}
                                  label="Straße mit Hausnr."
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  variant="outlined"
                                />
                              )}
                            />
                            <Controller
                              name="billingCountry"
                              control={control}
                              render={({ field,fieldState}) =>  (
                                <Autocomplete
                                  {...field}
                                  id="billingCountryAutocomplete"
                                  options={countryList}
                                  disableClearable={false}
                                  getOptionLabel={(option) => option || ''}
                                  value={field?.value}
                                  //onChange={field.onChange}
                                  onChange={(e, value) => {
                                    field.onChange(value)
                                  }}
                                  autoHighlight={true} // required to select entry without submitting entire material-table row
                                  onKeyDown={e => handleKey(e)}
                                  isOptionEqualToValue={(option, value) => {
                                    return option === value
                                  }}
                                  renderOption={(props, option) => {
                                    return(
                                      <li {...props}>
                                        <div>{option || '---'}</div>
                                      </li>
                                    )
                                  }}
                                  size="small"
                                  renderInput={(params) => {
                                    params.InputProps.style = {}
                                    return(
                                      <TextField
                                        {...params}
                                        label="Land"
                                        error={!!fieldState.error}
                                        helperText={fieldState.error ? fieldState.error.message : null}
                                      />
                                    )
                                  }}
                                />
                              )}
                            />
                          </div>

                        </Box>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

              </Box>
            </div>

          </div>
          <div className="text-center mx-auto mt-2">
            <button
              type="submit"
              className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
            >
              Änderungen speichern
            </button>
          </div>
          <div className="text-center mx-auto mt-2">
            <button
              className="btn bg-secondaryLightGray text-white text-lg hover:bg-secondaryGray rounded p-2 w-56 m-2"
              onClick={() => {history.push('/dashboard/profile/'); window.scrollTo(0, 0)}}
            >Abbrechen</button>
          </div>
        </form>

      </div>

    </div>
  )
}

export default CustomerProfile
