import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import './css/app.css';
import {ConnectedRouter} from 'connected-react-router';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Redirect, Route, Switch} from 'react-router-dom';
import * as actions from './store/actions';
import HotelDetails from './routes/HotelDetails';
import Search from './routes/Search';
import Header from './components/Header';
import Login from './routes/Login';
import Booking from './routes/Booking';
import Comparison from './routes/Comparison';
import HeaderLight from './components/HeaderLight';
import Footer from './components/Footer';
import Account from './routes/Account';
import AccountDetails from './routes/AccountDetails';
import Summary from "./routes/Summary";
import Notifications from './components/Notifications';
import CustomerProfile from "./routes/CustomerProfile";
import CustomerDashboard from "./routes/CustomerDashboard";
import CustomerSubscription from "./routes/CustomerSubscription";
import Success from './routes/Success';
import CustomerProfileEdit from './routes/CustomerProfileEdit';
import CustomerBooking from "./routes/CustomerBooking";
import Logout from "./routes/Logout";
import ResetPassword from "./routes/ResetPassword";
import CustomerPasswordEdit from "./routes/CustomerPasswordEdit";
import axios from "./axios.config";
import UpgradeModal from "./components/UpgradeModal";
import NewsletterModal from "./components/NewsletterModal";
import SuccessFallback from "./routes/SuccessFallback";
import AccountOnly from "./routes/AccountOnly";
import PageNotFound from "./routes/PageNotFound";
import CustomerInvoice from "./routes/CustomerInvoice";
import {useLocation} from "react-router";
import BusinessJoin from "./routes/BusinessJoin";
import Test from "./routes/Test";
import CustomerCompanyInvite from "./routes/CustomerCompanyInvite";
import CustomerCompanyInviteAdd from "./routes/CustomerCompanyInviteAdd";
import AccountOnlyBusiness from "./routes/AccountOnlyBusiness";
import CustomerBookingHistory from "./routes/CustomerBookingHistory";
import HotelRequest from './routes/HotelRequest';

const App = ({ history }) => {

  const dispatch = useDispatch()

  const isLoggedIn = useSelector(state => state.login.isLoggedIn)
  useEffect(() => {
    dispatch(actions.restoreSession())
  }, [dispatch])

  const muiTenantTheme = {
    palette: {
      primary: {
        light: '#25A9D5',
        main: '#25A9D5',
        dark: '#25A9D5',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#7b11a8',
        main: '#7b11a8',
        dark: '#7b11a8',
        contrastText: '#000000',
      },
    },
    typography: {
      //fontSize: 13,
    },
    //spacing: 6,
  }
  const muiTheme = createTheme(muiTenantTheme)

  axios.interceptors.response.use((response) => {
    if (response.headers['x-mfh-upgrade'] === 'true') {
      console.log('Upgrade!')
      dispatch(actions.showUpgradeModal())
    }
    return response
  }, (error) => {
    return Promise.reject(error)
  })

  const PrivateRoute = ({ children, ...rest }) => {

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login/",
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }

  const LoginRoute = ({ children, location, ...rest }) => {
    const searchQuery = new URLSearchParams(location.search)
    const target = searchQuery.get("target") || null
    /*
     * Dynamic routing after login:
     * Prio1: target query param, e.g. /login/?target=/account-details/
     * Prio2: location.state.from e.g. private url /dashboard/detailsXY/
     * Prio3: fallback, e.g. /dashboard/
     */
    let newPath = target ?? location?.state?.from?.pathname ?? '/dashboard/'

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLoggedIn ? (
            <Redirect to={{ pathname: newPath }} />
          ) : (
            <>
              <HeaderLight />
              <Login />
              <Footer />
            </>
          )
        }
      />
    )
  }

  const ScrollToTop = () => {
    const { pathname } = useLocation()
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
    return null
  }

  return (
    <ConnectedRouter history={history}>
      <ThemeProvider theme={muiTheme}>
        <div className="App">
          <ScrollToTop />
          <Switch>
            <LoginRoute path="/login/" />
            <PrivateRoute path="/dashboard/">
              <HeaderLight />
              <Switch>
                <Route path="/dashboard/profile/edit/">
                  <CustomerProfileEdit />
                </Route>
                <Route path="/dashboard/profile/">
                  <CustomerProfile />
                </Route>
                <Route path="/dashboard/invoice/">
                  <CustomerInvoice />
                </Route>
                <Route path="/dashboard/password/">
                  <CustomerPasswordEdit />
                </Route>
                <Route path="/dashboard/company/invite/add/">
                  <CustomerCompanyInviteAdd />
                </Route>
                <Route path="/dashboard/company/invite/">
                  <CustomerCompanyInvite />
                </Route>
                <Route path="/dashboard/booking/:subscriptionID">
                  <CustomerBooking />
                </Route>
                <Route path="/dashboard/subscriptions/">
                  <CustomerSubscription />
                </Route>
                <Route path="/dashboard/history/">
                  <CustomerBookingHistory />
                </Route>
                <Route path="/dashboard/success/direct/">
                  <SuccessFallback />
                </Route>
                <Route path="/dashboard/success/">
                  <Success />
                </Route>
                <Route path="/dashboard/business/booking/">
                  <CustomerBooking />
                </Route>
                <Route path="/dashboard/">
                  <CustomerDashboard />
                </Route>
              </Switch>
              <Footer />
            </PrivateRoute>
            <Route path="/business/new-account/">
              <HeaderLight />
              <AccountOnlyBusiness />
              <Footer />
            </Route>
            <Route path="/business/join/">
              <HeaderLight />
              <BusinessJoin />
              <Footer />
            </Route>
            <Route path="/logout/">
              <HeaderLight />
              <Logout />
              <Footer />
            </Route>
            <Route path="/reset-password/">
              <HeaderLight />
              <ResetPassword />
              <Footer />
            </Route>
            <Route
              path="/search/:city/:deprecatedNights/"
              render={props => (
                <Redirect to={{ pathname: `/search/${props.match.params.city}/`, search: `?nights=${props.match.params.deprecatedNights}&product=single` }} />
                )}
            />
            <Route path="/search/:city/">
              <Header />
              <Search />
              <Footer />
              <NewsletterModal />
            </Route>
            <Route path="/hotel/:city/:id/">
              <Header />
              <HotelDetails />
              <Footer />
              <NewsletterModal />
            </Route>
            <Route path="/booking/">
              <HeaderLight />
              <Booking />
              <Footer />
              <NewsletterModal />
            </Route>
            <Route path="/account/">
              <HeaderLight />
              <Account />
              <Footer />
              <NewsletterModal />
            </Route>
            <Route path="/new-account/">
              <HeaderLight />
              <AccountOnly />
              <Footer />
              <NewsletterModal />
            </Route>
            <Route path="/account-details/">
              <HeaderLight />
              <AccountDetails />
              <Footer />
              <NewsletterModal />
            </Route>
            <Route path="/summary/">
              <HeaderLight />
              <Summary />
              <Footer />
              <NewsletterModal />
            </Route>
            <Route path="/hotel-request/">
              <HeaderLight />
              <HotelRequest />
              <Footer />
            </Route>
            <Route path="/zweitwohnsitz-kosten-rechner/">
              <Comparison />
            </Route>
            <Route path="/404/">
              <HeaderLight />
              <PageNotFound />
              <Footer />
            </Route>
            <Route path="/daypicker-test/">
              <Test />
            </Route>
            <Route path="/">
              <Redirect to={{ pathname: "/search/muenchen/", search: "?nights=4&product=single" }} />
            </Route>
          </Switch>
          <Notifications />
          <UpgradeModal />
        </div>
      </ThemeProvider>
    </ConnectedRouter>
  )
}

export default App
