import HeaderLoginDropDown from "./HeaderLoginDropDown";
import {UserCircleIcon} from "@heroicons/react/24/outline";
import {useSelector} from 'react-redux';
import {useHistory} from "react-router";

const HeaderLogin = (props) => {

  const history = useHistory()

  const firstName = useSelector(state => state.login.user.firstName)
  const email = useSelector(state => state.login.user.email)
  const profile = useSelector(state => state.customer.profile)
  const subscriptionActive = profile?.BookingSubscriptions?.filter(subscription => ['terminated','paused'].includes(subscription.status) === false)?.[0]
  const subscriptionID = subscriptionActive?.subscriptionID || ''

  const handleLoginClick = () => {
    history.push('/login/')
  }

  return(
    <div className="text-center">
      {email
        ? <HeaderLoginDropDown firstName={firstName} email={email} subscriptionID={subscriptionID} />
        : <button
            className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary cursor-pointer flex"
            onClick={handleLoginClick}><UserCircleIcon className="h-5 w-5 mr-2 text-gray-400" /> Login</button>
      }
    </div>
  )
}

export default HeaderLogin